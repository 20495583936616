import * as types from "./mutation-types";

let pollingSubscription = null;
let eventNumber = 0;
export const actions = {
  [types.LOAD_EVENTS]: ({ commit }) => {
    // always fire an event to refresh things on a regular interval
    // this should be replaced with some other push notification strategy like signalr at some point
    if (!pollingSubscription) {
      pollingSubscription = setInterval(() => {
        commit(types.LOAD_EVENTS_SUCCESS, [{ action: "forceUpdate", resourceID: eventNumber++ }]);
      }, 600000);
    }
  },
};
