<template>
  <span v-if="value">
    <template v-for="(id, index) in value" :key="index">
      <v-list-item>
        <v-text-field v-bind="$attrs" v-model="id.value" hide-details disabled @update:model-value="$emit('update', value)"></v-text-field>
      </v-list-item>
      <v-divider v-if="index < value.length - 1" :key="`divider-${index}`" class="mx-2"></v-divider>
    </template>
  </span>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  value: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["update"]);

// Define reactive variables
const value = ref(props.value);

// Watch for changes in the value prop and update the reactive variable
watch(
  () => props.value,
  newValue => {
    value.value = newValue;
  }
);

// Emit the updated value when the v-model is updated
const updateValue = newValue => {
  emit("update", newValue);
};
</script>
