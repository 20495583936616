import * as mixinMethods from "./helpers";
import { renderName } from "./fhir-helpers";

const formatName = (item) => {
  return renderName(item) || "-";
};
const codeFormatter = item => {
  return item?.text ?? item?.coding?.map(c => c.code ?? c.display).join() ?? "";
};
const categoryFormatter = item => {
  let code = item?.data?.resource?.category?.coding?.map(c => c.code).join() ?? "";
  return code ? code : item?.data?.resource?.category?.text ?? item?.coding?.map(c => c.code).join() ?? "";
};

const agCodeFormatter = item => {
  let code = item.data.resource.code;
  return code ? code.text : "";
};
const patientLinkRenderer = item => {
  let patient = item.data.resource.subject;
  if (!patient) return "-";
  let id = patient.reference.split("/")[1];
  if (!id) return patient.display;
  return `<a href="/patients/${id}">${patient.reference}</a>`;
};

export const colDefs = [
  { refType: "Appointment", headerName: "", field: "tags", width: 50 },
  { refType: "Appointment", headerName: "Patient", field: "patient", hideEmbedded: true },
  { refType: "Appointment", headerName: "Description", field: "description" },
  { refType: "Appointment", headerName: "Created", field: "created", formatter: mixinMethods.formatDate },
  { refType: "Appointment", headerName: "Status", field: "status" },

  { refType: "ServiceRequest", headerName: "Patient", field: "patient", hideEmbedded: true, cellRenderer: "PatientCell" },
  { refType: "ServiceRequest", headerName: "Code", field: "code", valueFormatter: agCodeFormatter, formatter: codeFormatter },
  { refType: "ServiceRequest", headerName: "Authored", field: "resource.authoredOn", cellRenderer: "DateCell" },
  { refType: "ServiceRequest", headerName: "Performer", field: "resource.performer.display", cellRenderer: "PractitionerCell" },
  { refType: "ServiceRequest", headerName: "Status", field: "resource.status" },

  { refType: "Patient", headerName: "Name", field: "patient-name", valueFormatter: renderName },
  { refType: "Patient", headerName: "", field: "tags", width: 50 },
  // { refType: "Patient", headerName:  "Name", field: "name", formatter: fhirHelper.renderName },
  { refType: "Patient", headerName: "Birth Date", field: "birthDate", formatter: mixinMethods.formatDate },
  { refType: "Patient", headerName: "Identifiers", field: "identifiers", width: 30 },

  //observation
  { refType: "Observation", headerName: "", field: "tags", width: 50 },
  { refType: "Observation", headerName: "Patient", field: "subject", sortable: false, valueFormatter: renderName },
  { refType: "Observation", headerName: "Value", field: "value" },
  { refType: "Observation", headerName: "Code", field: "code", formatter: codeFormatter },
  // { refType: "Observation", headerName:  "Date", field: "effectiveDateTime", formatter: mixinMethods.formatDate },
  //encounter
  { refType: "Encounter", headerName: "", field: "tags", width: 50 },
  { refType: "Encounter", headerName: "Patient", field: "subject", hideEmbedded: false },
  // { refType: "Encounter", headerName:  "Code", field: "type", formatter: codeFormatter },
  { refType: "Encounter", headerName: "Date", field: "period.start", formatter: mixinMethods.formatDate },
  { refType: "Encounter", headerName: "Status", field: "status" },
  // { refType: "Encounter", headerName:  "Location", field: "location.location.display" },
  // { refType: "Encounter", headerName:  "Organization", field: "serviceProvider.display" },

  //medicationstatement
  { refType: "MedicationStatement", headerName: "Patient", field: "subject", hideEmbedded: false },
  { refType: "MedicationStatement", headerName: "Code", field: "medicationCodeableConcept", formatter: codeFormatter },
  { refType: "MedicationStatement", headerName: "Date", field: "effectivePeriod.start", formatter: mixinMethods.formatDate },
  { refType: "MedicationStatement", headerName: "Status", field: "status" },
  { refType: "MedicationStatement", headerName: "Taken", field: "taken" },

  //medicationrequest
  { refType: "MedicationRequest", headerName: "Patient", field: "subject", hideEmbedded: false },
  { refType: "MedicationRequest", headerName: "Code", field: "medicationCodeableConcept", formatter: codeFormatter },
  { refType: "MedicationRequest", headerName: "Date", field: "authoredOn", formatter: mixinMethods.formatDate },
  { refType: "MedicationRequest", headerName: "Status", field: "status" },
  { refType: "MedicationRequest", headerName: "Intent", field: "intent" },
  { refType: "MedicationRequest", headerName: "Priority", field: "priority" },

  //coverage
  { refType: "Coverage", headerName: "", field: "tags", width: 50 },
  { refType: "Coverage", headerName: "Patient", field: "beneficiary", formatter: renderName },
  { refType: "Coverage", headerName: "Code", field: "type", formatter: codeFormatter },
  { refType: "Coverage", headerName: "Date", field: "period.start", formatter: mixinMethods.formatDate },
  { refType: "Coverage", headerName: "Status", field: "status" },
  { refType: "Coverage", headerName: "Plan", field: "plan" },
  { refType: "Coverage", headerName: "Subplan", field: "subplan" },
  { refType: "Coverage", headerName: "Network", field: "network" },
  { refType: "Coverage", headerName: "Group", field: "group" },
  { refType: "Coverage", headerName: "Subgroup", field: "subgroup" },
  // { refType: "Coverage", headerName:  "Dependent", field: "dependent" },
  // { refType: "Coverage", headerName:  "Sequence", field: "sequence" },
  // { refType: "Coverage", headerName:  "Order", field: "order" },
  // { refType: "Coverage", headerName:  "Relationship", field: "relationship" },
  // { refType: "Coverage", headerName:  "Beneficiary", field: "beneficiary" },
  { refType: "Coverage", headerName: "Payor", field: "payor", formatter: renderName },
  { refType: "Coverage", headerName: "Class", field: "class" },
  // { refType: "Coverage", headerName:  "CostToBeneficiary", field: "costToBeneficiary" },
  // { refType: "Coverage", headerName:  "Subrogation", field: "subrogation" },
  // { refType: "Coverage", headerName:  "Network", field: "network" },
  // { refType: "Coverage", headerName:  "Contract", field: "contract" },

  //careteam
  { refType: "CareTeam", headerName: "", field: "tags", width: 50 },
  { refType: "CareTeam", headerName: "Patient", field: "subject", hideEmbedded: false, formatter: renderName },
  { refType: "CareTeam", headerName: "Date", field: "period.start", formatter: mixinMethods.formatDate },
  { refType: "CareTeam", headerName: "Status", field: "status" },
  { refType: "CareTeam", headerName: "Category", field: "category" },
  { refType: "CareTeam", headerName: "Name", field: "name" },
  { refType: "CareTeam", headerName: "Participant", field: "participant" },
  { refType: "CareTeam", headerName: "Note", field: "note" },

  //allergyintolerance
  { refType: "AllergyIntolerance", headerName: "Patient", field: "patient", hideEmbedded: false },
  { refType: "AllergyIntolerance", headerName: "Code", field: "code", formatter: codeFormatter },
  { refType: "AllergyIntolerance", headerName: "Date", field: "recordedDate", formatter: mixinMethods.formatDate },
  { refType: "AllergyIntolerance", headerName: "Status", field: "status" },
  { refType: "AllergyIntolerance", headerName: "Criticality", field: "criticality" },
  { refType: "AllergyIntolerance", headerName: "Type", field: "type" },
  { refType: "AllergyIntolerance", headerName: "Category", field: "category" },
  { refType: "AllergyIntolerance", headerName: "LastOccurence", field: "lastOccurrence", formatter: mixinMethods.formatDate },
  { refType: "AllergyIntolerance", headerName: "Note", field: "note" },
  { refType: "AllergyIntolerance", headerName: "Reaction", field: "reaction" },
  { refType: "AllergyIntolerance", headerName: "Recorder", field: "recorder" },

  //documentreference
  { refType: "DocumentReference", headerName: "Patient", field: "subject", hideEmbedded: false },
  { refType: "DocumentReference", headerName: "Description", field: "description" },
  { refType: "DocumentReference", headerName: "Content", field: "Content" },

  //condition
  { refType: "Condition", headerName: "Patient", field: "subject", hideEmbedded: false },
  { refType: "Condition", headerName: "Code", field: "code", formatter: codeFormatter },
  { refType: "Condition", headerName: "Date", field: "recordedDate", formatter: mixinMethods.formatDate },
  { refType: "Condition", headerName: "Status", field: "clinicalStatus" },
  { refType: "Condition", headerName: "Verification Status", field: "verificationStatus", formatter: codeFormatter },
  { refType: "Condition", headerName: "Category", field: "category" },
  { refType: "Condition", headerName: "Onset", field: "onset" },

  //questionnaireresponse
  { refType: "QuestionnaireResponse", headerName: "Patient", field: "subject", hideEmbedded: false },
  { refType: "QuestionnaireResponse", headerName: "Questionnaire", field: "questionnaire" },
  { refType: "QuestionnaireResponse", headerName: "Date", field: "authored", formatter: mixinMethods.formatDate },
  { refType: "QuestionnaireResponse", headerName: "Status", field: "status" },
  { refType: "QuestionnaireResponse", headerName: "Author", field: "author" },
  { refType: "QuestionnaireResponse", headerName: "Source", field: "source" },
  { refType: "QuestionnaireResponse", headerName: "Item", field: "item" },

  //practitioner
  { refType: "Practitioner", headerName: "Name", field: "name", formatter: renderName },
  { refType: "Practitioner", headerName: "", field: "tags", width: 50 },
  { refType: "Practitioner", headerName: "Identifiers", field: "identifiers", width: 30 },
  { refType: "Practitioner", headerName: "Qualifications", field: "qualification", width: 30, formatter: (item) => item?.length ?? "-" },

  //practitionerrole
  { refType: "PractitionerRole", headerName: "", field: "tags", width: 50 },
  { refType: "PractitionerRole", headerName: "Practitioner", field: "practitioner", hideEmbedded: false },
  { refType: "PractitionerRole", headerName: "Organization", field: "organization", hideEmbedded: false },
  { refType: "PractitionerRole", headerName: "Code", field: "code", formatter: codeFormatter },
  { refType: "PractitionerRole", headerName: "Speciality", field: "speciality" },
  { refType: "PractitionerRole", headerName: "Location", field: "location" },
  { refType: "PractitionerRole", headerName: "Telecom", field: "telecom" },

  //organization
  { refType: "Organization", headerName: "", field: "tags", width: 50 },
  { refType: "Organization", headerName: "Name", field: "name" },
  { refType: "Organization", headerName: "Identifiers", field: "identifiers", width: 30 },
  { refType: "Organization", headerName: "Type", field: "type" },
  { refType: "Organization", headerName: "Part of", field: "partOf" },
  { refType: "Organization", headerName: "Telecom", field: "telecom" },
  { refType: "Organization", headerName: "Address", field: "address" },
  { refType: "Organization", headerName: "Contact", field: "contact" },
  { refType: "Organization", headerName: "Endpoint", field: "endpoint" },

  //relatedperson
  { refType: "RelatedPerson", headerName: "Name", field: "name", formatter: renderName },
  { refType: "RelatedPerson", headerName: "", field: "tags", width: 50 },
  { refType: "RelatedPerson", headerName: "Patient", field: "patient", hideEmbedded: false, formatter: renderName },
  { refType: "RelatedPerson", headerName: "Relationship", field: "relationship" },
  { refType: "RelatedPerson", headerName: "Telecom", field: "telecom" },
  { refType: "RelatedPerson", headerName: "Address", field: "address" },

  //location
  { refType: "Location", headerName: "", field: "tags", width: 50 },

  //fhir communication
  {
    refType: "Communication", headerName: "Patient", field: "subject", cellRenderer: patientLinkRenderer, valueFormatter: (item) => item?.data?.resource?.subject?.reference ?? "",
    getQuickFilterText: params => {
      return params.value?.split("/")[1] ?? "";
    },
  },

  //task
  { refType: "Task", headerName: "Assignee", field: "owner", formatter: formatName },
  { refType: "Task", headerName: "Last Updated", field: "meta.lastUpdated", formatter: mixinMethods.formatDateTime },
  { refType: "Task", headerName: "Business Status", field: "businessStatus", formatter: codeFormatter },
  { refType: "Task", headerName: "Requester", field: "requester.display" },
  { refType: "Task", headerName: "Status", field: "status" },

  { refType: "Communication", headerName: "Medium", field: "medium", valueFormatter: (item) => item?.data?.resource?.medium?.map(m => m.coding[0].code).join() ?? "" },
  { refType: "Communication", headerName: "Category", field: "category", valueFormatter: (item) => item?.data?.resource?.category?.[0]?.text ?? "-" },
  { refType: "Communication", headerName: "Topic", field: "topic", valueFormatter: (item) => `${item?.data?.resource?.topic?.coding?.[0]?.system}/${item?.data?.resource?.topic?.coding?.[0]?.code}` ?? "-" },
  // { refType: "Communication", headerName: "Payload", field: "payload", valueFormatter: (item) => item?.data?.resource?.payload?.[0]?.contentString ?? "-" },
  { refType: "Communication", headerName: "Sent", field: "sent", valueFormatter: (item) => item?.data?.resource?.sent ?? "-" },

];
