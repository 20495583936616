<template>
  <span>
    <v-menu v-model="dateMenu" :close-on-content-click="false" min-width="290px">
      <template #activator="{ props }">
        <v-text-field
          v-bind="$attrs" v-model="dateFormatted" variant="outlined" prepend-inner-icon="mdi-calendar"
          :rules="[rules.date]" autocomplete="off" @blur="parseDate(dateFormatted)"
        ></v-text-field>
      </template>
      <v-date-picker
        ref="picker" v-model="date" type="date" no-title
        @update:model-value="dateMenu = false"
      ></v-date-picker>
    </v-menu>
  </span>
</template>
<script>
import { formatDate } from "../../utils/helpers";

export default {
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dateMenu: false,
    date: null,
    dateFormatted: null,
    rules: {
      required: (value) => !!value || "Required",
      date: (value) => {
        var validDate = true;
        try {
          var date = new Date(value);
          if (date.getTime() !== date.getTime() || date.getFullYear() <= 1900) validDate = "Invalid date";
        } catch {
          return false;
        }
        return validDate;
      },
    },
  }),
  watch: {
    value(val) {
      if (val) this.date = val;
    },
    date(val) {
      var date = formatDate(val);
      if (date) {
        this.dateFormatted = date;
      }
      this.$emit("update", this.date);
    },
  },
  created() {
    this.date = this.value;
  },
  methods: {
    parseDate(val) {
      if (val) {
        var date = formatDate(val);
        if (date) {
          this.dateFormatted = date;
          this.patient.dob = date;
        }
      }
    },
  }
};
</script>