import * as types from "./mutation-types";
import { actions } from "./actions";
import { fhirToPractitioner } from "../../utils/fhir-helpers";

const state = {
  practitioner: { name: [{ family: null, given: [null], text: null }], qualification: [], qualifications: [], gender: null },
  practitioners: [],
  roles: [],
  pendingPractitioners: [],
  error: null,
  isAdding: false,
  loading: false,
  isDeleting: false,
};

const getters = {
  getStatuses: state => state.statuses,
};
export const mutations = {
  [types.CREATE_PRACTITIONER]: (state, obj) => {
    state.pendingPractitioners.unshift(obj);
    state.isLoadingSettings = true;
    state.error = null;
  },
  [types.CREATE_PRACTITIONER_SUCCESS]: (state, obj) => {
    state.pendingPractitioners = state.pendingPractitioners.filter(p => p.key !== obj.key);
    state.practitioners.unshift(obj.practitioner);
    state.practitioner = obj.practitioner;
    state.error = null;
  },
  [types.CREATE_PRACTITIONER_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.UPDATE_PRACTITIONER]: state => {
    state.isLoadingSettings = true;
    state.error = null;
  },
  [types.UPDATE_PRACTITIONER_SUCCESS]: (state, practitioner) => {
    state.error = null;
    state.practitioner = { ...state.practitioner, ...practitioner };
  },
  [types.UPDATE_PRACTITIONER_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.UPDATE_ROLE]: state => {
    state.loading = true;
    state.error = null;
  },
  [types.UPDATE_ROLE_SUCCESS]: (state, role) => {
    state.practitioner.locations = role?.location ? role.location : [];
    state.practitioner.role = role;
    state.error = null;
    state.loading = false;
  },
  [types.UPDATE_ROLE_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.CREATE_ROLE]: state => {
    state.loading = true;
    state.error = null;
  },
  [types.CREATE_ROLE_SUCCESS]: (state, role) => {
    state.practitioner.locations = role?.location ? role.location : [];
    state.practitioner.role = role;
    state.error = null;
    state.loading = false;
  },
  [types.CREATE_ROLE_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.LOAD_PRACTITIONER]: (state, userID) => {
    state.loading = true;
  },
  [types.LOAD_PRACTITIONER_SUCCESS]: (state, practitioner) => {
    if (!practitioner.meta) practitioner.meta = {};
    state.practitioner = { ...state.practitioner, ...practitioner };
    state.error = null;
    state.loading = false;
  },
  [types.LOAD_PRACTITIONER_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.DELETE_PRACTITIONERS]: (state, selectedProviders) => {
    let ids = selectedProviders.map(s => s.id);
    let pendingDelete = state.practitioners.filter(p => ids.includes(p.id));
    if (pendingDelete) {
      // pendingDelete.forEach(d => Vue.set(d, "deleting", true));
    }
    state.isDeleting = true;
    state.error = null;
  },
  [types.DELETE_PRACTITIONERS_SUCCESS]: (state, selectedProviders) => {
    let ids = selectedProviders.map(s => s.id);
    state.practitioners = state.practitioners.filter(p => !ids.includes(p.id));
    state.error = null;
    state.isDeleting = false;
  },
  [types.DELETE_PRACTITIONERS_FAILURE]: (state, obj) => {
    let ids = obj.selectedProviders.map(s => s.id);
    let pendingDelete = state.practitioners.filter(p => ids.includes(p.id));
    if (pendingDelete) {
      // pendingDelete.forEach(d => Vue.set(d, "deleting", false));
    }
    state.error = obj.error;
    state.isDeleting = false;
  },
  [types.LOAD_PRACTITIONERS]: state => {
    state.loading = true;
  },
  [types.LOAD_PRACTITIONERS_SUCCESS]: (state, entries) => {
    state.practitioners = entries.filter(e => e.resource.resourceType == "Practitioner").map(p => fhirToPractitioner(p.resource));
    state.roles = entries.filter(e => e.resource.resourceType == "PractitionerRole").map(p => p.resource);
    state.error = null;
    state.loading = false;
  },
  [types.LOAD_PRACTITIONERS_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.RESET]: state => {
    state.practitioner = { name: [{ family: null, given: [null] }], qualification: [] };
    state.practitioners = [];
    state.pendingPractitioners = [];
  },
  [types.SET_PRACTITIONER]: (state, practitioner) => {
    state.practitioner = practitioner;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
