import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  mappers: null,
  mapper: null,
  error: null,
};

const getters = {
  getIntegrations: state => state.integrations,
};
export const mutations = {
  [types.MAPPERS_LOAD]: state => {
    state.error = null;
  },
  [types.MAPPERS_LOAD_SUCCESS]: (state, mappers) => {
    state.mappers = mappers;
  },
  [types.MAPPERS_LOAD_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.MAPPER_LOAD_BY_ID]: (state) => {
    state.loading = true;
  },
  [types.MAPPER_LOAD_BY_ID_SUCCESS]: (state, mapper) => {
    state.mapper = mapper;
  },
  [types.MAPPER_LOAD_BY_ID_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.UPDATE_MAPPER]: state => {
    state.loading = true;
  },
  [types.UPDATE_MAPPER_SUCCESS]: (state, mapper) => {
    if (state.mapper.id === mapper.id) state.mapper = mapper;
    const index = state.mappers.findIndex(m => m.id === mapper.id);
    if (index >= 0) state.mappers.splice(index, 1, mapper);
    state.loading = false;
  },
  [types.UPDATE_MAPPER_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
