import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    show: false,
    message: '',
    color: 'neutral',
    timeout: 5000,
  }),
  actions: {
    showMessage(message, color = 'success', timeout = 5000) {
      this.show = true
      this.message = message
      this.color = color
      this.timeout = timeout
    },
    showSuccess(message, timeout = 5000) {
      this.showMessage(message, 'success', timeout)
    },
    showError(message, timeout = 5000) {
      this.showMessage(message, 'error', timeout)
    },
    hideSnackbar() {
      this.show = false
    },
  },
})
