import { actions } from "./actions";
import * as types from "./mutation-types";

const state = {
  template: { type: "scheduling" },
  templates: [],
  error: null,
  loading: false,
  processing: false,
};

export const mutations = {
  [types.LOAD_TEMPLATES]: state => {
    state.error = null;
    state.loading = true;
  },
  [types.LOAD_TEMPLATES_SUCCESS]: (state, templates) => {
    state.templates = templates;
    state.loading = false;
  },
  [types.LOAD_TEMPLATES_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.LOAD_TEMPLATE_BY_ID]: state => {
    state.loading = true;
  },
  [types.LOAD_TEMPLATE_BY_ID_SUCCESS]: (state, template) => {
    state.template = template;
    state.loading = false;
  },
  [types.LOAD_TEMPLATE_BY_ID_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.CREATE_TEMPLATE]: (state, template) => {
    state.template = template;
    state.processing = true;
  },
  [types.CREATE_TEMPLATE_SUCCESS]: (state, template) => {
    state.processing = false;
    state.template = template;
  },
  [types.CREATE_TEMPLATE_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.UPDATE_TEMPLATE]: state => {
    state.processing = true;
  },
  [types.UPDATE_TEMPLATE_SUCCESS]: state => {
    state.processing = false;
  },
  [types.UPDATE_TEMPLATE_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.DELETE_TEMPLATE]: state => {
    state.processing = true;
  },
  [types.DELETE_TEMPLATE_SUCCESS]: (state, id) => {
    state.automations = state.automations.filter(r => r.id !== id);
    state.processing = false;
  },
  [types.DELETE_TEMPLATE_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.RESET_TEMPLATE]: state => {
    state.template = { type: "scheduling" };
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
