<template>
  <div class="bg-background px-3 pb-4 rounded fill-height overflow-auto">
    <v-toolbar v-if="canEdit" variant="flat" class="pr-0" density="compact" color="transparent">
      <v-spacer></v-spacer>
      <v-btn icon variant="text" :loading="loading" @click="loadData">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn icon size="small" :disabled="unsaved" @click="addNote">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card v-for="(note, index) in notes" :key="`card-${note.id}-${index}`" class="pt-2 mb-4" max-width="800" color="yellow-lighten-4" min-height="200">
      <v-card-title v-if="currentNote !== note && !note.edit" class="pl-6 pt-2 note-title">
        <v-row class="px-4 pt-2 float-left" style="width: 400px">
          <!-- <v-text-field v-if="currentNote == note && note.edit" v-model="currentNote.title" autofocus variant="plain" placeholder="Title" flat density="compact" autocomplete="off" hide-details class="text-h6 font-weight-bold grow"></v-text-field>
          <span v-else class="pt-3 text-grey text-subtitle-2">{{ note.title }}</span> -->
          <v-chip v-for="(event, i) in note.event" v-if="note.event" :key="`event-${note.id}-${i}`" :color="colorFromString(findTaskCode(event.detail[0].reference)).hex" class="ml-2">{{ findTaskCode(event.detail[0].reference) }}</v-chip>
        </v-row>
        <v-tooltip v-if="currentNote !== note && !note.edit" location="bottom">
          <template #activator="{ props }">
            <v-btn icon variant="text" class="float-right" @click="copyContent(note)">
              <v-icon color="grey" size="small">mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="py-1" style="min-height: 88px">
        <WYSIWYG v-if="currentNote == note && note.edit" v-model="newNote" class="note-editor" :options="{ placeholder: 'Take a note' }" @update="newNote = $event" />
        <div v-for="(section, i) in note.section" v-else :key="`${note.id}-${i}`" class="px-2" v-html="section.text.div"></div>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-chip v-if="!note.id" class="mt-1 text-grey" variant="outlined">Unsaved</v-chip>
        <Avatar v-if="note.id" size="20" :user="note.author[0]"></Avatar>
        <span v-if="note.id" class="py-0 pl-2 pr-4 text-subtitle-1">{{ note.author[0].display }}</span>
        <v-spacer></v-spacer>
        <span v-if="note.id" class="py-0 text-grey text-subtitle-2">{{ formatUTCDateTime(note.date) }}</span>
        <v-chip v-else variant="plain" class="py-0 text-grey text-subtitle-2" v-text="noteHelpText"></v-chip>
        <v-progress-circular v-if="note.deleting" indeterminate color="primary"></v-progress-circular>
        <v-btn v-if="!note.id" variant="text" class="mr-2" size="small" @click="cancelEdit">Cancel</v-btn>
        <v-btn v-if="!note.id" class="mr-0" variant="elevated" color="primary" size="small" :disabled="!noteValid" :loading="processing" @click="saveNote">Save</v-btn>
        <v-menu v-else-if="note.id && allowEdit">
          <template #activator="{ props }">
            <v-btn variant="text" color="grey-lighten-1" style="min-width: 0px; width: 36px" class="pa-0" v-bind="props">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="confirmDelete(note)">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item @click="editNote(note)">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="deleteDialog" max-width="390">
      <v-card>
        <v-card-title class="">Are you sure?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" variant="flat" @click="deleteNote">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Avatar from "./avatar.vue";
import WYSIWYG from "../wysiwyg/wysiwyg.vue";
import * as api from "../../utils/api";
import { formatUTCDateTime } from "../../utils/helpers";
import { useTasks } from "../../store/tasks";

export default {
  name: "Notes", //TODO: Support passing of notes, this could be used to consolidate calls such as practitioner with a _revInclude
  components: { WYSIWYG, Avatar },
  inheritAttrs: true,
  props: {
    subject: {
      type: String,
      default: null,
    },
    subjectName: {
      type: String,
      default: "",
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    event: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    newNote: null,
    currentNote: null,
    notes: [],
    deleteDialog: false,
    processing: false,
    unsaved: false,
    taskStore: null,
  }),
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("user", ["users"]),
    allowDelete() {
      const group = this.user?.groups?.find(g => g !== "Orbital-Instance-Admin");
      return !!group;
    },
    allowEdit() {
      return this.allowDelete && this.canEdit;
    },
    noteValid() {
      return this.newNote?.length > 0;
    },
    tasks() {
      return this.taskStore.tasks;
    },
    noteHelpText() {
      return navigator.platform.includes("Mac") ? "⌘ + Enter to Save" : "Ctrl + Enter to Save";
    },
  },
  watch: {
    subject() {
      this.loadData();
    },
    event() {
      this.loadData();
    },
  },
  created() {
    this.taskStore = useTasks();
    this.loadData();
  },
  mounted() {
    window.addEventListener("keydown", this.keydownCallback);
  },
  unmounted() {
    window.removeEventListener("keydown", this.keydownCallback);
  },
  methods: {
    formatUTCDateTime,
    keydownCallback(e) {
      if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
        if (this.noteValid) this.saveNote();
        e.preventDefault();
      }
    },
    async loadData() {
      this.loading = true;
      if (this.subject) {
        var resp = await api.get(`composition?subject=${this.subject}`);
        this.notes = resp.data.entry?.map(e => e.resource) ?? [];
        if (this.event) {
          this.notes = this.notes.filter(n => n.event?.some(e => e.detail.some(d => d.reference === this.event)));
        }
      }
      this.loading = false;
    },
    findTaskCode(id) {
      let task = this.tasks.find(t => t.id === id.replace("Task/", ""));

      return task?.code?.text ?? task?.code?.coding?.[0]?.display ?? "Task";
    },
    addNote() {
      this.unsaved = true;
      this.currentNote = {
        resourceType: "Composition",
        status: "final",
        subject: {
          reference: this.subject,
          display: this.subjectName,
        },
        title: "Note",
        event: [],
        author: [
          {
            reference: `Practitioner/${this.user.practitionerID}`,
            display: this.user.name,
          },
        ],
        section: [
          {
            text: {
              status: "generated",
              div: this.newNote,
            },
          },
        ],
        type: {
          coding: [
            {
              system: "http://loinc.org",
              code: "11488-4",
              display: "Consult note",
            },
          ],
        },
      };
      if (this.event) {
        this.currentNote.event.push({
          detail: [{ reference: this.event }],
        });
      }
      this.currentNote.edit = true;
      this.notes.unshift(this.currentNote);
    },
    async saveNote() {
      this.processing = true;
      this.currentNote.edit = false;
      this.currentNote.date = new Date();
      this.currentNote.section[0].text.div = this.newNote;
      try {
        var resp = await api.post("composition", this.currentNote);
        this.currentNote.id = resp.id;
      } catch (e) {
        this.$store.dispatch("SNACKBAR_ERROR", "Error saving note");
      }
      this.processing = false;
      this.newNote = "";

      this.currentNote = null;
      this.unsaved = false;
      this.processing = false;
    },
    confirmDelete(note) {
      this.deleteDialog = true;
      this.currentNote = note;
    },
    async deleteNote() {
      this.deleteDialog = false;
      this.currentNote.deleting = true;
      this.notes = this.notes.filter(n => n !== this.currentNote);
      await api.deleteResource(`composition/${this.currentNote.id}`);
    },
    editNote(note) {
      this.currentNote = note;
      this.newNote = note.section[0].text.div;
      this.currentNote.edit = true;
    },
    cancelEdit() {
      this.notes = this.notes.filter(n => n !== this.currentNote);
      this.currentNote = null;
      this.unsaved = false;
    },
    async copyContent(note) {
      const htmlContent = note.section[0].text.div;
      try {
        // Use the Clipboard API to write the variant="text" to the clipboard
        // await navigator.clipboard.writeText(text_only);
        await navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([htmlContent], { type: "text/html" }),
          }),
        ]);
        this.$store.dispatch("SNACKBAR_SUCCESS", "Copied to clipboard");
      } catch (err) {
        // Handle any errors that occur during the copy operation
        console.error("Failed to copy text: ", err);
      }
    },
  },
};
</script>
<style>
.note-title {
  min-height: 52px;
}
</style>
