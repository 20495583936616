<template>
  <div>
    <v-progress-linear
      v-if="isLoadingDocs" :size="70" :width="7" color="primary" indeterminate
      class="fill-height mx-auto"
    ></v-progress-linear>
    <v-row v-else-if="allowDownloadAll && currentPatientDocs.length > 0">
      <v-col class="d-flex flex-row-reverse">
        <v-btn variant="text" color="primary" size="small" @click="downloadAllFiles">
          Download All
        </v-btn>
      </v-col>
    </v-row>
    <v-card variant="flat">
      <v-card-text v-if="!isLoadingDocs && currentPatientDocs" class="pa-0">
        <template v-for="file in currentPatientDocs" :key="file.id">
          <v-card class="mb-3 pa-0" variant="outlined">
            <v-list-item
              :disabled="file.deleting || downloading" class="pa-4" :title="file.description"
              @click="fetchDocument(file)"
            >
              <template #prepend color="primary-lighten-1">
                <v-icon v-if="file.type == 'application/x-zip-compressed'" class="mx-auto">
                  mdi-folder-zip-outline
                </v-icon>
                <v-icon v-else-if="file.type == 'application/pdf'" class="mx-auto">
                  mdi-file-pdf-box
                </v-icon>
                <v-icon v-else-if="file.type && file.type.indexOf('image') > -1" class="mx-auto">
                  mdi-image
                </v-icon>
                <v-icon v-else class="mx-auto">
                  mdi-file
                </v-icon>
              </template>
              <v-progress-linear
                v-if="file.deleting || file.downloading" :color="file.error ? 'red' : 'primary accent-4'"
                indeterminate rounded height="6"
              ></v-progress-linear>
              <template v-else>
                <v-list-item-subtitle class="text-caption text-left mb-0 text-grey">
                  {{ file.name }}
                </v-list-item-subtitle>
              </template>
              <template v-if="allowDelete && !deleteDialog && !file.deleting && !file.downloading" #append>
                <span variant="text" color="grey" size="small">
                  {{ formatDateTime(file.date) }}
                </span>
                <v-btn color="grey" variant="text" size="small" @click.stop="removeFile(file)">
                  Delete
                </v-btn>
              </template>
            </v-list-item>
          </v-card>
        </template>
      </v-card-text>
    </v-card>
    <v-dialog v-model="deleteDialog" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure?
        </v-card-title>
        <v-card-text>Delete file</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" variant="flat" @click="deleteCallback">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as api from "../../utils/api.js";
import { mapState, mapActions, mapGetters } from "vuex";
import { DELETE_FILE } from "../../store/patients/mutation-types";
import { formatDateTime } from "../../utils/helpers";

export default {
  name: "PatientFiles",
  components: {},
  props: {
    patientID: {
      type: String,
      required: true,
    },
    allowDownloadAll: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    deleteDialog: false,
    deleteCallback: null,
    downloading: false,
  }),
  async created() { },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("patients", ["isLoadingDocs"]),
    ...mapState("register", ["instanceID"]),
    ...mapState("instances", ["instance", "loading"]),
    ...mapGetters("patients", ["getPatientDocs"]),
    allowDelete() {
      //allow delete if user is admin or if user is the patient
      const instanceAdmin = this.user?.groups?.find(g => g === "Orbital-Instance-Admin") ?? false;
      return !instanceAdmin;
    },
    currentInstanceID() {
      return this.instance?.id || this.instanceID;
    },
    currentPatientDocs() {
      return this.getPatientDocs(this.patientID).sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    },
  },
  methods: {
    formatDateTime,
    ...mapActions("patients", [DELETE_FILE]),
    removeFile(file) {
      this.deleteDialog = true;
      let parent = this;
      this.deleteCallback = async function () {
        file.deleting = true;
        parent.deleteDialog = false;
        await parent.DELETE_FILE(file);
      };
    },
    async fetchDocument(doc) {
      this.downloading = true;
      doc.downloading = true;

      var options = {
        headers: { "instance-id": this.currentInstanceID },
      };
      await api.downloadFile(doc, options);

      this.downloading = false;
      doc.downloading = false;
    },
    async downloadAllFiles() {
      Promise.all(
        this.currentPatientDocs.map(async doc => {
          await this.fetchDocument(doc);
          this.downloading = true;
        })
      ).then(() => {
        this.downloading = false;
      });
    },
  },
};
</script>