import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  statuses: null,
  status: null,
  isLoadingSettings: false,
  error: null,
  visitType: null,
  visitTypes: null,
  codeSystems: [],
  processing: false,
  importMaps: null,
  orgImportMaps: null,
};

const getters = {
  getStatuses: state => state.statuses,
};
export const mutations = {
  [types.LOAD_STATUSES]: state => {
    state.isLoadingSettings = true;
    state.error = null;
  },
  [types.LOAD_STATUSES_SUCCESS]: (state, statuses) => {
    state.statuses = statuses;
    state.isLoadingSettings = false;
  },
  [types.LOAD_STATUSES_FAILURE]: (state, error) => {
    state.isLoadingSettings = false;
    state.error = error;
  },
  [types.STATUS_UPDATE]: (state, status) => {
    state.isLoadingSettings = true;
    state.error = null;
    state.status = status;
  },
  [types.STATUS_UPDATE_SUCCESS]: (state, status) => {
    if (state.status.id === status.id) state.status = status;
    const index = state.statuses.findIndex(s => s.id === status.id);
    if (index >= 0) state.statuses.splice(index, 1, status);
    state.isLoadingSettings = false;
  },
  [types.STATUS_UPDATE_FAILURE]: (state, error) => {
    state.isLoadingSettings = false;
    state.error = error;
  },
  [types.CREATE_STATUS]: (state, status) => {
    state.isLoadingSettings = false;
    state.error = null;
    state.status = status;
  },
  [types.CREATE_STATUS_SUCCESS]: state => {
    state.isLoadingSettings = false;
  },
  [types.CREATE_STATUS_FAILURE]: (state, error) => {
    state.isLoadingSettings = false;
    state.error = error;
  },
  [types.DELETE_STATUS]: (state, status) => {
    state.isLoadingSettings = false;
    state.error = null;
    state.status = status;
  },
  [types.DELETE_STATUS_SUCCESS]: state => {
    state.isLoadingSettings = false;
  },
  [types.DELETE_STATUS_FAILURE]: (state, error) => {
    state.isLoadingSettings = false;
    state.error = error;
  },
  [types.LOAD_CODE_SYSTEM]: (state, code) => {
    state.error = null;
    state.isLoadingSettings = true;
  },
  [types.LOAD_CODE_SYSTEM_SUCCESS]: (state, codeSystem) => {
    let index = state.codeSystems.findIndex(cs => cs.title == codeSystem.title);
    if (codeSystem) {
      if (index > -1) {
        state.codeSystems[index] = codeSystem;
      } else {
        state.codeSystems.push(codeSystem);
      }
    }
    state.isLoadingSettings = false;
  },
  [types.LOAD_CODE_SYSTEM_FAILURE]: (state, error) => {
    state.error = error;
    state.isLoadingSettings = false;
  },
  [types.UPDATE_CODE_SYSTEM]: (state, codeSystem) => {
    state.codeSystem = codeSystem;
    state.processing = true;
  },
  [types.UPDATE_CODE_SYSTEM_SUCCESS]: (state, codeSystem) => {
    state.codeSystem = codeSystem;
    state.processing = false;
  },
  [types.UPDATE_CODE_SYSTEM_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
