export const LOAD_INTEGRATION_LOCATION = "LOAD_INTEGRATION_LOCATION";
export const LOAD_INTEGRATION_LOCATION_SUCCESS = "LOAD_INTEGRATION_LOCATION_SUCCESS";
export const LOAD_INTEGRATION_LOCATION_FAILURE = "LOAD_INTEGRATION_LOCATION_FAILURE";

export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_FAILURE = "CREATE_LOCATION_FAILURE";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAILURE = "UPDATE_LOCATION_FAILURE";

export const LOAD_LOCATIONS = "LOAD_LOCATIONS";
export const LOAD_LOCATIONS_SUCCESS = "LOAD_LOCATIONS_SUCCESS";
export const LOAD_LOCATIONS_FAILURE = "LOAD_LOCATIONS_FAILURE";
