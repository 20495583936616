export const MAPPERS_LOAD = "MAPPERS_LOAD";
export const MAPPERS_LOAD_SUCCESS = "MAPPERS_LOAD_SUCCESS";
export const MAPPERS_LOAD_FAILURE = "MAPPERS_LOAD_FAILURE";

export const MAPPER_LOAD_BY_ID = "MAPPER_LOAD_BY_ID";
export const MAPPER_LOAD_BY_ID_SUCCESS = "MAPPER_LOAD_BY_ID_SUCCESS";
export const MAPPER_LOAD_BY_ID_FAILURE = "MAPPER_LOAD_BY_ID_FAILURE";

export const UPDATE_MAPPER = "UPDATE_MAPPER";
export const UPDATE_MAPPER_SUCCESS = "UPDATE_MAPPER_SUCCESS";
export const UPDATE_MAPPER_FAILURE = "UPDATE_MAPPER_FAILURE";