export const FORMS_LOAD = "FORMS_LOAD";
export const FORMS_LOAD_SUCCESS = "FORMS_LOAD_SUCCESS";
export const FORMS_LOAD_FAILURE = "FORMS_LOAD_FAILURE";

export const FORM_LOAD_BY_ID = "FORM_LOAD_BY_ID";
export const FORM_LOAD_BY_ID_SUCCESS = "FORM_LOAD_BY_ID_SUCCESS";
export const FORM_LOAD_BY_ID_FAILURE = "FORM_LOAD_BY_ID_FAILURE";

export const UPDATE_FORM = "UPDATE_FORM";
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS";
export const UPDATE_FORM_FAILURE = "UPDATE_FORM_FAILURE";

export const CREATE_FORM = "CREATE_FORM";
export const CREATE_FORM_SUCCESS = "CREATE_FORM_SUCCESS";
export const CREATE_FORM_FAILURE = "CREATE_FORM_FAILURE";

export const DELETE_FORM = "DELETE_FORM";
export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS";
export const DELETE_FORM_FAILURE = "DELETE_FORM_FAILURE";

export const NEW_FORM = "NEW_FORM";

export const ADMIN_FORMS_LOAD = "ADMIN_FORMS_LOAD";
