import Vuex from "vuex";
import user from "./user";
import appointments from "./appointments";
import auth from "./auth";
import patients from "./patients";
import settings from "./settings";
import register from "./register";
import instances from "./instances";
import practitioners from "./practitioners";
import integrations from "./integrations";
import mappers from "./mappers";
import organization from "./organization";
import forms from "./forms";
import providers from "./providers";
import messaging from "./messaging";
import locations from "./locations";
import events from "./events";
import payments from "./payments";
import automations from "./automations";
import templates from "./templates";
import system_notifications from "./system-notifications";
import * as actions from "./appMutations";

const appMutations = {
  setSnackbar(state, message) {
    state.snackbar = message;
  },
  setBanner(state, message) {
    state.banner = message;
  },
  clearBanner(state) {
    state.banner = null;
  },
};

const appActions = {
  [actions.SNACKBAR_INFO]: ({ commit }, message) => {
    commit("setSnackbar", { message: message, level: "info", timeout: 10000 }, { root: true });
  },
  [actions.SNACKBAR_SUCCESS]: ({ commit }, message) => {
    commit("setSnackbar", { message: message, level: "success", icon: "mdi-check_circle" }, { root: true });
  },
  [actions.SNACKBAR_WARNING]: ({ commit }, message) => {
    commit("setSnackbar", { message: message, level: "warning", icon: "mdi-warning" }, { root: true });
  },
  [actions.SNACKBAR_ERROR]: ({ commit }, message) => {
    commit("setSnackbar", { message: message, level: "error", icon: "mdi-error" }, { root: true });
  },
  [actions.SLOW_CONNECTION]: ({ commit }) => {
    commit(
      "setBanner",
      {
        message: "Your connection seems to be degraded. Please make sure you have a strong internet connection to upload/download files.",
        level: "error",
      },
      { root: true }
    );
  },
  [actions.SLOW_CONNECTION_RESOLVED]: ({ commit }) => {
    commit("clearBanner", {}, { root: true });
  },
};

const store = new Vuex.Store({
  state: {
    version: "1.0.0",
    snackbar: {},
    banner: null,
  },
  modules: {
    user,
    appointments,
    auth,
    patients,
    settings,
    register,
    instances,
    practitioners,
    integrations,
    forms,
    organization,
    providers,
    messaging,
    events,
    system_notifications,
    mappers,
    locations,
    payments,
    automations,
    templates,
  },
  mutations: {
    ...appMutations,
  },
  actions: {
    ...appActions,
  },
});

export default store;
