import { jwtDecode } from "jwt-decode";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import * as rx from "rxjs";
import { debounce, take, map } from "rxjs/operators";
import { getCachedObject } from "./helpers";
import { Auth } from "@aws-amplify/auth";

let user = null;
let userPool = null;
let BASE_URL = location.origin;
let windowObjectReference = null;
let previousUrl = null;
var broadcastChannel;
let isIdle$ = new rx.BehaviorSubject(false);
let awsConfig = null;

try {
  broadcastChannel = new BroadcastChannel("orbital_channel");
} catch {
  broadcastChannel = {
    postMessage() { },
  };
}

async function rememberDevice() {
  Auth.currentAuthenticatedUser()
    .then(user => Auth.rememberDevice(user))
    .catch(_err => {/* empty */ });
}

async function getUser() {
  if (!awsConfig?.userPoolID) return null;
  try {
    user = await Auth.currentAuthenticatedUser();
    if (user) {
      //store.commit('setUser', data);
      var u = mapUser({ ...user, attributes: Object.keys(user.attributes).map((key) => ({ Name: key, Value: user.attributes[key] })) });
      u.groups = await getUserGroups(user);
      return u;
    }
  } catch (e) {
    return null;
  }
}

function getAWSExport(config) {
  if (config)
    awsConfig = config;

  return {
    aws_project_region: "us-east-1",
    aws_cognito_region: awsConfig?.region ?? "us-east-1",
    aws_user_pools_id: awsConfig?.userPoolID,
    aws_user_pools_web_client_id: awsConfig?.userPoolWebClientID,
    oauth: {},
  };
}

function getUserPool(config) {
  if (config)
    awsConfig = config;
  if (userPool) return userPool;
  if (!awsConfig?.userPoolID) return null;
  userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: awsConfig.userPoolID,
    ClientId: awsConfig.userPoolWebClientID,
  });
  return userPool;
}

function mapUser(cognitoUser) {
  //if cognit attributes are not an array, convert to array
  if (!Array.isArray(cognitoUser.attributes)) {
    cognitoUser.attributes = Object.keys(cognitoUser.attributes).map((key) => ({ Name: key, Value: cognitoUser.attributes[key] }));
  }
  let profileImage = cognitoUser.attributes.find(a => a.Name == "picture");
  let organizationID = cognitoUser.attributes.find(a => a.Name == "custom:organizationID");
  let practitionerID = cognitoUser.attributes.find(a => a.Name == "custom:practitionerID");
  let emailNotifications = cognitoUser.attributes.find(a => a.Name == "custom:emailNotifications");
  let smsNotifications = cognitoUser.attributes.find(a => a.Name == "custom:smsNotifications");
  let phoneNumber = cognitoUser.attributes.find(a => a.Name == "phone_number");
  let instanceID = cognitoUser.attributes.find(a => a.Name == "custom:instanceID");
  let name = cognitoUser.attributes.find(a => a.Name == "name");
  let patientID = cognitoUser.attributes.find(a => a.Name == "custom:patientID");
  let patients = cognitoUser.attributes.find(a => a.Name == "custom:patients")?.Value?.split(",") ?? [];
  let phones = cognitoUser.attributes.find(a => a.Name == "custom:phones")?.Value.split(",") ?? [];
  let settings = cognitoUser.attributes.find(a => a.Name == "custom:settings")?.Value ? JSON.parse(cognitoUser.attributes.find(a => a.Name == "custom:settings")?.Value) : {};
  return {
    id: cognitoUser.username,
    name: name?.Value ?? "",
    address: cognitoUser.attributes.find(a => a.Name == "address")?.Value ?? "",
    practitionerID: practitionerID ? practitionerID.Value : "",
    profileImage: profileImage ? profileImage.Value : "",
    userID: cognitoUser.attributes.find(a => a.Name == "email").Value,
    citizen: cognitoUser.attributes.find(a => a.Name == "custom:citizen")?.Value,
    phone: phoneNumber ? phoneNumber.Value : "",
    phones: phones,
    settings: settings,
    organizationID: organizationID ? organizationID.Value : "",
    instanceID: instanceID ? instanceID.Value : "",
    patientID: patientID?.Value,
    emailNotifications: emailNotifications ? emailNotifications.Value.split(",") : [],
    smsNotifications: smsNotifications ? smsNotifications.Value.split(",") : [],
    patients: patients,
  };
}

function getCachedUser(force) {
  let user = null;
  if (!user || force) {
    const cachedUser = getCachedObject("user");
    user = cachedUser ? cachedUser : null;
  }
  // if (user) Sentry.setUser({ email: user.userID });
  return user;
}

async function getUserAttributes(cognitoUser) {
  return new Promise((resolve, reject) => {
    cognitoUser.getUserAttributes((err, result) => {
      resolve(result);
    });
  });
}

async function getUserGroups(cognitoUser) {
  return new Promise((resolve, reject) => {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        console.error(err);
        return;
      }
      var sessionIdInfo = jwtDecode(session.getIdToken().jwtToken);
      resolve(sessionIdInfo["cognito:groups"]);
    });
  });
}

function refreshSession() {
  var userPool = getUserPool();
  var user = userPool?.getCurrentUser();
  if (!user) return;
  user.getSession((err, session) => {
    if (err) {
      console.error('Error getting session:', err);
      return;
    }
    // Check if the session is valid and not expired
    if (session.isValid()) {
      // Refresh the session
      user.refreshSession(session.getRefreshToken(), (err, newSession) => {
        if (err) {
          console.error('Error refreshing session:', err);
        }
      });
    } else {
      console.warn('Session is not valid or has expired');
    }
  });
}

function getUserSession() {
  var userPool = getUserPool();
  var user = userPool?.getCurrentUser();
  if (!user) return null;

  return user.getSession((err, session) => {
    return session;
  });
}
function checkPassword(password) {
  var validation = { valid_password: false };
  if (!password) return validation;
  validation.password_length = password.length;

  if (validation.password_length > 7) {
    validation.contains_eight_characters = true;
  } else {
    validation.contains_eight_characters = false;
  }

  validation.contains_number = /\d/.test(password);
  validation.contains_lowercase = /[a-z]/.test(password);
  validation.contains_uppercase = /[A-Z]/.test(password);

  //eslint-disable-next-line
  const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  validation.contains_special_character = format.test(password);

  if (
    validation.contains_eight_characters === true &&
    validation.contains_special_character === true &&
    validation.contains_lowercase === true &&
    validation.contains_uppercase === true &&
    validation.contains_number === true
  ) {
    validation.valid_password = true;
  } else {
    validation.valid_password = false;
  }

  return validation;
}

function clearIdle() {
  isIdle$.next(false);
  // refreshSession();
  throttleFunction(() => broadcastChannel.postMessage("idle_cleared"), 500);
}

let isIdleTimerStarted = false;

function startIdleTimer() {
  if (!isIdleTimerStarted) {
    broadcastChannel.onmessage = messageEvent => {
      if (messageEvent.data === "idle_cleared") {
        isIdle$.next(false);
      }
    };

    var actionStreams = rx.merge(
      rx.fromEvent(document, "click"),
      rx.fromEvent(document, "keydown"),
      rx.fromEvent(document, "mousemove"),
      rx.fromEvent(document, "touchstart"),
      rx.fromEvent(document, "scroll")
    );

    var actionStreamsWithIdle = rx.merge(actionStreams, isIdle$);

    // idle timer in milliseconds
    var timeout = 1800000;
    var idleStream = actionStreamsWithIdle.pipe(debounce(() => rx.interval(timeout)));

    actionStreams.subscribe(() => {
      clearIdle();
    });

    idleStream.subscribe(
      () => {
        isIdle$.next(true);
      },
      err => {
        console.log("Error: " + err);
      }
    );
    isIdleTimerStarted = true;
  }

  return isIdle$.asObservable();
}

const getIdleTimer = () => {
  const timerInterval$ = rx.interval(1000); //1s
  const time = 60;
  return timerInterval$.pipe(
    take(time),
    map(v => time - v - 1)
  );
};

const openSignInWindow = (url, name) => {
  if (!url.includes("redirect_uri")) url += `&redirect_uri=${BASE_URL}/api/oauth/callback`;

  // window features
  const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
    windowObjectReference.focus();
  }
  // assign the previous URL
  previousUrl = url;
};

var timerId;
var throttleFunction = function (func, delay) {
  if (timerId) {
    return;
  }
  timerId = setTimeout(function () {
    func();
    timerId = undefined;
  }, delay);
};

export {
  getUserPool,
  mapUser,
  getCachedUser,
  getUserAttributes,
  getUserGroups,
  getUserSession,
  refreshSession,
  userPool,
  rememberDevice,
  openSignInWindow,
  startIdleTimer,
  getIdleTimer,
  getAWSExport,
  getUser
};
