import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueApexCharts from "vue3-apexcharts";
import App from "./App.vue";
import router from "./router";
// import { useOrganizationStore } from '@/store/organization/store.js';
import store from "./store";
import * as api from "./utils/api";
import * as authhelper from "./utils/auth-helper";
import * as fhirhelper from "./utils/fhir-helpers";

import failoverImage from "./components/shared/failoverImage.vue";
import Avatar from './components/shared/avatar.vue';
import ProviderSearch from './components/providers/ProviderSearch.vue';
import DatePicker from './components/shared/datePicker.vue';
import InstanceSelector from "./components/shared/InstanceSelector.vue";
import { Amplify } from 'aws-amplify';
import { registerPlugins } from "./plugins";
import { LicenseManager } from 'ag-grid-enterprise';
import { useNotificationsStore } from '@/store/notifications/index.js';
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';

// init
init();

async function init() {
  const pinia = createPinia();
  // mount app
  const app = createApp(App);
  app.use(pinia);
  app.use(store);
  app.use(VueApexCharts);
  app.use(router);

  var config = window.config;
  if (!config) {
    var response = await api.GetInit();
    config = response?.data;
    //Amplify.configure(authhelper.getAWSExport(config.awsConfig));
  }

  fhirhelper.init(config.systemConfig);
  //Vue.use(VueTheMask);

  // setup a refresh timer for every 10 min, but trigger immediately
  setInterval(function refresh() {
    authhelper.refreshSession();
  }, 600000);

  app.component("FailoverImage", failoverImage);
  app.component("Avatar", Avatar);
  app.component("DatePicker", DatePicker);
  app.component("ProviderSearch", ProviderSearch);
  app.component("InstanceSelector", InstanceSelector);

  Amplify.configure(authhelper.getAWSExport(config.awsConfig));

  store.commit("organization/LOAD_ORGANIZATION_SUCCESS", config, { root: true });
  store.commit("instances/LOAD_INSTANCE_BY_ID_SUCCESS", config.instance, { root: true });
  store.commit("instances/LOAD_INSTANCES_SUCCESS", [config.instance], { root: true });
  store.commit("integrations/LOAD_INTEGRATIONS_SUCCESS", config.integrations, { root: true });
  if (config.forms) {
    store.commit("forms/FORMS_LOAD_SUCCESS", config.forms, { root: true });
  }
  store.commit("user/LOAD_ROLES_SUCCESS", config.roles, { root: true });
  store.commit("settings/LOAD_STATUSES_SUCCESS", config.statuses, { root: true });
  const notificationsStore = useNotificationsStore();
  notificationsStore.loadNotificationTypesSuccess(config.notificationTypes);
  app.provide('version', config.version);
  LicenseManager.setLicenseKey(config.agGridKey);
  app.provide('features', config.features);


  app.config.errorHandler = (err, vm, info) => {
    // Check if the error message matches the specific error
    if (err?.message?.includes('Failed to load module script: Expected a JavaScript module script but the server responded with a MIME type of "text/html"')) {
      // Refresh the page
      location.reload();
    } else {
      // Handle other errors
      if (config.environment !== 'localhost') {
        datadogLogs.logger.error(err);
      } else {
        console.error('Error in localhost environment:', err);
      }

      throw err; // Let the default error handler handle it
    }
  };

  registerPlugins(app);
  let version = config.version.replace(/[^a-z0-9_:./-]/g, '');

  var sharedConfig = {
    applicationId: 'e1f29b1f-ed19-4374-9d8f-408b58f62ac3',
    clientToken: 'pub26f3f50484f717d905addfcabb79fa1f',
    version: version,
    env: config.environment,
    service: 'web-app',
    site: 'us5.datadoghq.com',
    telemetrySampleRate: 100
  };

  if (config.environment !== 'localhost') {
    datadogLogs.init({
      ...sharedConfig,
    })
    datadogRum.init({
      ...sharedConfig,
      // sessionSampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      enablePrivacyForActionName: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
  }
  app.mount("#app");
}
