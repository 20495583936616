import * as types from "./mutation-types";
import * as api from "../../utils/api";
import * as appMutations from "../appMutations";
import router from "../../router";

export const actions = {
  [types.LOAD_COUNTS]: async ({ commit }) => {
    const result = await api.get("message-counts");
    commit(types.LOAD_COUNTS_SUCCESS, result?.data);
  },
  [types.LOAD_PATIENT_CONVERSATIONS]: async ({ commit }, { patientID }) => {
    commit(types.LOAD_PATIENT_CONVERSATIONS);
    try {
      const url = `conversation?patientID=${patientID}`;
      const response = await api.get(url);
      commit(types.LOAD_PATIENT_CONVERSATIONS_SUCCESS, response?.data);
    } catch (error) {
      commit(types.LOAD_PATIENT_CONVERSATIONS_FAILURE, error);
    }
  },
  [types.CREATE_CONVERSATION]: async ({ commit, dispatch }, conversation) => {
    commit(types.CREATE_CONVERSATION);
    try {
      const response = await api.post("conversation", conversation);
      commit(types.CREATE_CONVERSATION_SUCCESS, response);
    } catch (error) {
      commit(types.CREATE_CONVERSATION_FAILURE, error);
      let message = error?.response?.data ?? "There was an error creating the conversation. Please try again.";
      dispatch(appMutations.SNACKBAR_ERROR, message, {
        root: true,
      });
    }
  },
  [types.UPDATE_CONVERSATION]: async ({ commit }, conversation) => {
    commit(types.UPDATE_CONVERSATION);
    await api.put("conversation", conversation);
    commit(types.UPDATE_CONVERSATION_SUCCESS, conversation);
  },
  [types.CREATE_MESSAGE]: async ({ commit, dispatch }, message) => {
    commit(types.CREATE_MESSAGE);
    try {
      const resp = await api.post("message", message);
      commit(types.CREATE_MESSAGE_SUCCESS, resp);
      return true;
    } catch (error) {
      commit(types.CREATE_MESSAGE_FAILURE, error);
      const message = error.message === "Item size has exceeded the maximum allowed size"
        ? "The message is too large. Please shorten it and try again."
        : error?.response?.data ?? "There was an error sending the message. Please try again.";
      dispatch(appMutations.SNACKBAR_ERROR, message, {
        root: true,
      });
      return false;
    }
  },
  [types.DELETE_CONVERSATION]: async ({ commit }, conversation) => {
    commit(types.DELETE_CONVERSATION);
    await api.deleteResource(`conversation/${conversation.guid}`, conversation);
    commit(types.DELETE_CONVERSATION_SUCCESS);
  },
  [types.LOAD_MESSAGE_TEMPLATES]: async ({ commit }) => {
    commit(types.LOAD_MESSAGE_TEMPLATES);
    try {
      const response = await api.get("template");
      commit(types.LOAD_MESSAGE_TEMPLATES_SUCCESS, response?.data);
    } catch (error) {
      commit(types.LOAD_MESSAGE_TEMPLATES_FAILURE, error);
    }
  },
  [types.LOAD_MESSAGE_TEMPLATE]: async ({ commit }, id) => {
    commit(types.LOAD_MESSAGE_TEMPLATES);
    try {
      const response = await api.get(`template/${id}`);
      commit(types.LOAD_MESSAGE_TEMPLATE_SUCCESS, response?.data);
    } catch (error) {
      commit(types.LOAD_MESSAGE_TEMPLATE_FAILURE, error);
    }
  },
  [types.CREATE_MESSAGE_TEMPLATE]: async ({ commit, dispatch }, template) => {
    commit(types.CREATE_MESSAGE_TEMPLATE);
    const resp = await api.post("Template", template);
    commit(types.CREATE_MESSAGE_TEMPLATE_SUCCESS, resp);
    dispatch(appMutations.SNACKBAR_SUCCESS, "Message Template Created", {
      root: true,
    });
  },
  [types.UPDATE_MESSAGE_TEMPLATE]: async ({ commit, dispatch }, template) => {
    commit(types.UPDATE_MESSAGE_TEMPLATE);
    try {
      const response = await api.put(`Template/${template.id}`, template);
      commit(types.UPDATE_MESSAGE_TEMPLATE_SUCCESS, response);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Message Template Updated", {
        root: true,
      });
    } catch (error) {
      commit(types.UPDATE_MESSAGE_TEMPLATE_FAILURE, error);
      const message = error?.response?.data ?? "There was an error updating the message template. Please try again.";
      dispatch(appMutations.SNACKBAR_ERROR, message, {
        root: true,
      });
    }
  },
  [types.DELETE_MESSAGE_TEMPLATE]: async ({ commit, dispatch }, template) => {
    commit(types.DELETE_MESSAGE_TEMPLATE);
    await api.deleteResource(`Template/${template.id}`, template);
    commit(types.DELETE_MESSAGE_TEMPLATE_SUCCESS, template);
    dispatch(appMutations.SNACKBAR_SUCCESS, "Message Template Deleted", {
      root: true,
    });
  },
  [types.RESET_TEMPLATE]: ({ commit }) => {
    commit(types.RESET_TEMPLATE);
  },
};
