const CALLBACK_NAME = "placesCallback";

let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;

const initPromise = new Promise((resolve, reject) => {
    resolveInitPromise = resolve;
    rejectInitPromise = reject;
});

export default function init(API_KEY) {
    try {
        API_KEY = API_KEY || 'AIzaSyDZ4RXHDSE7TwV5074VgoJ5ZdQ_JBvoS0c';
        console.log(API_KEY);
        if (initialized) return initPromise;
        initialized = true;
        window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

        const script = document.createElement("script");
        script.async = true;
        script.defer = true;
        script.loading = "async";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=${CALLBACK_NAME}`;
        script.onerror = rejectInitPromise;
        document.querySelector("head").appendChild(script);

        return initPromise;
    } catch (e) {
        console.log(e);
    }
}