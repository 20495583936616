export const LOAD_INSTANCES = "LOAD_INSTANCES";
export const LOAD_INSTANCES_SUCCESS = "LOAD_INSTANCES_SUCCESS";
export const LOAD_INSTANCES_FAILURE = "LOAD_INSTANCES_FAILURE";

export const LOAD_INSTANCE_BY_ID = "LOAD_INSTANCE_BY_ID";
export const LOAD_INSTANCE_BY_ID_SUCCESS = "LOAD_INSTANCE_BY_ID_SUCCESS";
export const LOAD_INSTANCE_BY_ID_FAILURE = "LOAD_INSTANCE_BY_ID_FAILURE";

export const UPDATE_INSTANCE = "UPDATE_INSTANCE";
export const UPDATE_INSTANCE_SUCCESS = "UPDATE_INSTANCE_SUCCESS";
export const UPDATE_INSTANCE_FAILURE = "UPDATE_INSTANCE_FAILURE";

export const CREATE_INSTANCE = "CREATE_INSTANCE";
export const CREATE_INSTANCE_SUCCESS = "CREATE_INSTANCE_SUCCESS";
export const CREATE_INSTANCE_FAILURE = "CREATE_INSTANCE_FAILURE";

export const RESET_INSTANCE = "RESET_INSTANCE";

export const DELETE_INSTANCE = "DELETE_INSTANCE";
export const DELETE_INSTANCE_FAILURE = "DELETE_INSTANCE_FAILURE";
export const DELETE_INSTANCE_SUCCESS = "DELETE_INSTANCE_SUCCESS";
