import * as types from "./mutation-types";
import { actions } from "./actions";
import * as fhirHelper from "../../utils/fhir-helpers.js";

const ERROR_MESSAGE = "An error has occured. Please verify your input and try again.";
const nameTemplate = {
  use: "official",
  family: null,
  given: [null],
};
const patientTemplate = {
  id: null,
  name: [{ ...nameTemplate }],
  email: null,
  phone: null,
  dob: null,
  gender: "female",
  address: null,
  country: null,
  countryCode: 1,
  identifier: [],
  location: null,
};
const state = {
  patient: { ...patientTemplate },
  newUser: {
    name: [{ ...nameTemplate }],
    password: null,
    confirmPassword: null,
    acceptCommunication: false,
    acceptTerms: false,
    phones: [],
    address: null,
    citizen: null
  },
  complete: false,
  processing: false,
  files: [],
  instanceID: null,
  error: null,
  countries: [],
  loadingCountries: false,
};

const mutations = {
  [types.LOAD_COUNTRIES]: state => {
    state.countries = [];
    state.loadingCountries = true;
  },
  [types.LOAD_COUNTRIES_SUCCESS]: (state, { countries }) => {
    state.countries = countries;
    state.loadingCountries = false;
  },
  [types.CREATE_PATIENT]: (state, patient) => {
    state.processing = true;
    state.patient = patient;
    state.error = null;
    state.instanceID = patient.instanceID;
  },
  [types.PATIENT_CREATED]: (state, fhirPatient) => {
    state.processing = false;
    state.patient.id = fhirPatient.id;
    state.error = null;
  },
  [types.PATIENT_CREATED_FAILURE]: (state, error) => {
    state.processing = false;
    state.error = ERROR_MESSAGE;
  },
  [types.UPDATE_PATIENT]: (state, patient) => {
    state.processing = true;
    state.patient = patient;
    state.error = null;
  },
  [types.PATIENT_UPDATED]: (state, fhirPatient) => {
    state.processing = false;
    state.patient.id = fhirPatient?.id;
  },
  [types.PATIENT_UPDATED_FAILURE]: (state, error) => {
    state.processing = false;
    state.error = ERROR_MESSAGE;
  },
  [types.RESET_STATE]: state => {
    state.patient = { ...patientTemplate };
    state.patient.name = [{ ...nameTemplate }];
    state.patient.name[0].given = [null];
    state.newUser = { name: [{ ...nameTemplate }], password: null, confirmPassword: null, acceptCommunication: false, acceptTerms: false, phones: [], address: null, citizen: null };
    state.complete = false;
    state.processing = false;
    state.error = null;
  },
  [types.RESUME_REGISTRATION]: state => {
  },
  [types.RESUME_REGISTRATION_SUCCESS]: (state, { patient }) => {
    state.patient = { ...patientTemplate, name: fhirHelper.stringToName(patient?.name), ...patient };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
