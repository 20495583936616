import * as types from "./mutation-types";
import * as api from "../../utils/api";
export const actions = {
  [types.LOAD_INTEGRATION_PROVIDERS]: async ({ commit, state }, { search, page, integrationID }) => {
    commit(types.LOAD_INTEGRATION_PROVIDERS);
    try {
      var query = search ? "&name=" + search : "";
      var response = await api.search("admin/integrations/" + integrationID + "/practitioner?x=1" + query + "&page=" + page, {});
      commit(types.LOAD_INTEGRATION_PROVIDERS_SUCCESS, response?.data);
    } catch (error) {
      commit(types.LOAD_INTEGRATION_PROVIDERS_FAILURE, error);
    }
  },
  [types.CLEAR_INTEGRATION_PROVIDERS]: async ({ commit }) => {
    commit(types.CLEAR_INTEGRATION_PROVIDERS);
  },
};
