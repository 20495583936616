import * as types from "./mutation-types";
import * as api from "../../utils/api";
import * as appMutations from "../appMutations";

export const actions = {
  [types.LOAD_INTEGRATIONS]: async ({ commit }) => {
    commit(types.LOAD_INTEGRATIONS);
    try {
      var resp = await api.get("/admin/integrations");
      commit(types.LOAD_INTEGRATIONS_SUCCESS, resp.data);
    } catch (error) {
      commit(types.LOAD_INTEGRATIONS_FAILURE, error);
    }
  },
  [types.INTEGRATION_LOAD_BY_ID]: async ({ commit, dispatch }, integrationID) => {
    commit(types.INTEGRATION_LOAD_BY_ID, integrationID);
    try {
      var resp = await api.get("admin/integrations/" + integrationID);
      commit(types.INTEGRATION_LOAD_BY_ID_SUCCESS, resp.data);
    } catch (error) {
      commit(types.INTEGRATION_LOAD_BY_ID_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Loading Integration", {
        root: true,
      });
    }
  },
  [types.UPDATE_INTEGRATION]: async ({ commit, dispatch }, integration) => {
    commit(types.UPDATE_INTEGRATION, integration);
    try {
      var resp = await api.put("admin/integrations/" + integration.id, integration);
      commit(types.UPDATE_INTEGRATION_SUCCESS, resp.data);
    } catch (error) {
      commit(types.UPDATE_INTEGRATION_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Updating Integration", {
        root: true,
      });
    }
  },
  [types.INIT_ESIGNATURE]: async ({ state, commit, dispatch }, { req, redirect }) => {
    // commit(types.INIT_ESIGNATURE);
    let integration = state.integrations?.find(i => i.type === "esignature") ?? { id: "Docusign-Orbital-Health" };
    try {
      var resp = await api.post(`admin/integration/${integration.id}/DocumentReference/Envelope`, req);
      if (redirect) {
        window.location = resp.url;
      } else {
        //dispatch("tasks/LOAD_TASKS", `Patient/${req.signerClientId}`, { root: true });
      }
      // commit(types.INIT_ESIGNATURE_SUCCESS, resp.data);
    } catch (error) {
      dispatch(appMutations.SNACKBAR_ERROR, "Error initiating eSignature. Please try again", {
        root: true,
      });
      // commit(types.INIT_ESIGNATURE_FAILURE, error);
    }
  },
  [types.RESUME_ESIGNATURE]: async ({ state, commit, dispatch }, { req, redirect }) => {
    // commit(types.INIT_ESIGNATURE);
    let integration = state.integrations?.find(i => i.type === "esignature") ?? { id: "Docusign-Orbital-Health" };
    try {
      var resp = await api.post(`admin/integration/${integration.id}/SigningURL`, req);
      if (redirect) {
        window.location = resp.url;
      }
      // commit(types.INIT_ESIGNATURE_SUCCESS, resp.data);
    } catch (error) {
      dispatch(appMutations.SNACKBAR_ERROR, "Error initiating eSignature. Please try again", {
        root: true,
      });
      throw error;
      // commit(types.INIT_ESIGNATURE_FAILURE, error);
    }
  },
  [types.DOWNLOAD_ESIGNATURE]: async ({ state, commit, dispatch }, { req }) => {
    let integration = state.integrations?.find(i => i.type === "esignature") ?? { id: "Docusign-Orbital-Health" };
    try {
      commit(types.DOWNLOAD_ESIGNATURE, req.taskID);
      var resp = await api.downloadFile({ url: `admin/integration/${integration.id}/DownloadFiles/${req.taskID}`, name: req.name });
      commit(types.DOWNLOAD_ESIGNATURE_SUCCESS, req.taskID);
    } catch (error) {
      commit(types.DOWNLOAD_ESIGNATURE_FAILURE, req.taskID);
      dispatch(appMutations.SNACKBAR_ERROR, "Error downloading eSignature. Please try again", {
        root: true,
      });
      throw error;
    }
  },
};
