export const LOAD_PATIENTS = "LOAD_PATIENTS";
export const LOAD_PATIENTS_SUCCESS = "LOAD_PATIENTS_SUCCESS";
export const LOAD_PATIENTS_FAILURE = "LOAD_PATIENTS_FAILURE";
export const DELETE_PATIENTS = "DELETE_PATIENTS";
export const DELETE_PATIENTS_SUCCESS = "DELETE_PATIENTS_SUCCESS";
export const DELETE_PATIENTS_FAILURE = "DELETE_PATIENTS_FAILURE";
export const SET_PATIENT = "SET_PATIENT";
export const PATIENTS_RESET = "PATIENTS_RESET";
export const LOAD_PATIENT_BY_ID = "LOAD_PATIENT_BY_ID";
export const PATIENT_LOAD_SUCCESS = "PATIENT_LOAD_SUCCESS";
export const PATIENT_LOAD_FAILURE = "PATIENT_LOAD_FAILURE";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS";
export const UPDATE_PATIENT_FAILURE = "UPDATE_PATIENT_FAILURE";
export const CREATE_DOCUMENT = "CREATE_DOCUMENT";
export const CREATE_DOCUMENT_FAILURE = "CREATE_DOCUMENT_FAILURE";
export const CREATE_DOCUMENT_COMPLETE = "CREATE_DOCUMENT_COMPLETE";

export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";

export const SYNC_PATIENTS = "SYNC_PATIENTS";
export const SYNC_PATIENTS_SUCCESS = "SYNC_PATIENTS_SUCCESS";
export const SYNC_PATIENTS_FAILURE = "SYNC_PATIENTS_FAILURE";

export const PATIENT_LOAD_NOTFOUND = "PATIENT_LOAD_NOTFOUND";

export const LOAD_PATIENT_DOCUMENTS = "LOAD_PATIENT_DOCUMENTS";
export const LOAD_PATIENT_DOCUMENTS_SUCCESS = "LOAD_PATIENT_DOCUMENTS_SUCCESS";
export const LOAD_PATIENT_DOCUMENTS_FAILURE = "LOAD_PATIENT_DOCUMENTS_FAILURE";

export const POPULATE_QUESTIONNAIRE = "POPULATE_QUESTIONNAIRE";
export const POPULATE_QUESTIONNAIRE_SUCCESS = "POPULATE_QUESTIONNAIRE_SUCCESS";
export const POPULATE_QUESTIONNAIRE_FAILURE = "POPULATE_QUESTIONNAIRE_FAILURE";

export const CREATE_QUESTIONNAIRERESPONSE = "CREATE_QUESTIONNAIRERESPONSE";
export const CREATE_QUESTIONNAIRERESPONSE_SUCCESS = "CREATE_QUESTIONNAIRERESPONSE_SUCCESS";
export const UPDATE_QUESTIONNAIRERESPONSE = "UPDATE_QUESTIONNAIRERESPONSE";
export const UPDATE_QUESTIONNAIRERESPONSE_FAILURE = "UPDATE_QUESTIONNAIRERESPONSE_FAILURE";
export const UPDATE_QUESTIONNAIRERESPONSE_SUCCESS = "UPDATE_QUESTIONNAIRERESPONSE_SUCCESS";

export const UPDATE_RELATED_PERSON = "UPDATE_RELATED_PERSON";
