import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  forms: [],
  error: null,
  form: {},
  loading: false,
};

const getters = {
  getStatuses: state => state.statuses,
};
export const mutations = {
  [types.NEW_FORM]: state => {
    state.form = {
      name: "",
      fields: [],
    };
  },
  [types.FORMS_LOAD]: state => {
    state.loading = true;
    state.error = null;
  },
  [types.FORMS_LOAD_SUCCESS]: (state, forms) => {
    state.loading = false;
    state.forms = forms;
  },
  [types.FORMS_LOAD_FAILURE]: (state, error) => {
    state.loading = false;
    state.error = error;
  },
  [types.FORM_LOAD_BY_ID]: state => {
    state.loading = true;
  },
  [types.FORM_LOAD_BY_ID_SUCCESS]: (state, form) => {
    state.form = { sections: [], ...form };
    state.loading = false;
  },
  [types.UPDATE_FORM]: state => {
    state.loading = true;
  },
  [types.UPDATE_FORM_SUCCESS]: (state, form) => {
    state.loading = false;
    state.form = form;
    const index = state.forms?.findIndex(f => f.id === form.id);
    if (index >= 0) state.forms.splice(index, 1, form);
  },
  [types.UPDATE_FORM_FAILURE]: (state, error) => {
    state.loading = false;
    state.error = error;
  },
  [types.CREATE_FORM]: state => {
    state.loading = true;
  },
  [types.CREATE_FORM_SUCCESS]: (state, form) => {
    state.loading = false;
    state.form = form;
  },
  [types.CREATE_FORM_FAILURE]: (state, error) => {
    state.loading = false;
    state.error = error;
  },
  [types.DELETE_FORM]: state => {
    state.loading = true;
  },
  [types.DELETE_FORM_SUCCESS]: (state, id) => {
    state.loading = false;
    const index = state.forms.findIndex(f => f.id === id);
    if (index >= 0) state.forms.splice(index, 1);
  },
  [types.DELETE_FORM_FAILURE]: (state, error) => {
    state.loading = false;
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
