/* eslint-disable sonarjs/no-duplicate-string */
let systemUri = "";
let systemUrn = "";

//FHIR resource types for events
const resourceTypes = [
  "Account",
  "AdverseReaction",
  "AllergyIntolerance",
  "Appointment",
  "AppointmentResponse",
  "Binary",
  "CarePlan",
  "CareTeam",
  "Claim",
  "ClaimResponse",
  "ClinicalImpression",
  "Communication",
  "CommunicationRequest",
  "CompartmentDefinition",
  "Composition",
  "Condition",
  "Consent",
  "Contract",
  "Coverage",
  "DetectedIssue",
  "Device",
  "DeviceComponent",
  "DeviceMetric",
  "DeviceRequest",
  "DeviceUseStatement",
  "DiagnosticReport",
  "DocumentReference",
  "EligibilityRequest",
  "EligibilityResponse",
  "Encounter",
  "EnrollmentRequest",
  "EnrollmentResponse",
  "EpisodeOfCare",
  "ExplanationOfBenefit",
  "Flag",
  "Goal",
  "ImagingStudy",
  "Immunization",
  "ImmunizationRecommendation",
  "List",
  "MedicationAdministration",
  "MedicationDispense",
  "MedicationRequest",
  "MedicationStatement",
  "NutritionOrder",
  "Observation",
  "OperationDefinition",
  "OperationOutcome",
  "Organization",
  "Parameters",
  "Patient",
  "PaymentNotice",
  "PaymentReconciliation",
  "Person",
  "Practitioner",
  "PractitionerRole",
  "Procedure",
  "ProcedureRequest",
  "QuestionnaireResponse",
  "ReferralRequest",
  "RelatedPerson",
  "ResearchStudy",
  "RiskAssessment",
  "Schedule",
  "Slot",
  "Task",
  "VisionPrescription",
];

function init(config) {
  if (!config) {
    return;
  }
  systemUri = config.uri;
  systemUrn = config.urn;
}
function stringToName(name) {
  if (!name) {
    return [
      {
        text: null,
        use: "official",
        family: "",
        given: [""],
      },
    ];
  }
  if (typeof name == "string") {
    var firstName = name
      ?.split(" ")
      .slice(0, -1)
      .join(" ");
    var lastName = name
      ?.split(" ")
      .slice(-1)
      .join(" ");

    return [
      {
        text: name,
        use: "official",
        family: lastName,
        given: [firstName],
      },
    ];
  }
  return name;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function patientToFhir(patient) {
  var fhirName;
  if (typeof patient.name === "string") {
    let name = patient.name.trim();
    fhirName = stringToName(name);
  } else {
    fhirName = patient.name;
  }
  fhirName.forEach(n => {
    n.text = n.text ?? (n.given ? n.given.toString() : "") + (n.family ? " " + n.family.toString() : "");
    n.text = n.text !== undefined ? n.text : "";
  });
  const fhirPatient = {
    resourceType: "Patient",
    meta: { tag: patient.tags },
    active: true,
    id: patient.id,
    extension: patient.extension ?? [],
    identifier: [],
    name: fhirName,
    telecom: patient.telecom ?? [],
    gender: patient.gender,
    birthDate: patient.dob ? new Date(patient.dob).toISOString().substring(0, 10) : "",
    address: [
      {
        use: "home",
        line: patient.street || patient.address?.address || patient.address,
        city: patient.city || patient.address?.city,
        state: patient.state || patient.address?.state,
        postalCode: patient.postalCode || patient.address?.postalCode,
        country: patient.country || patient.address?.country,
      },
    ],
    managingOrganization: patient.fhirPatient?.managingOrganization ?? {
      reference: patient.instanceID,
      display: patient.instanceName,
    },
  };
  if (patient.location && !fhirPatient.address.find(a => a.use === "temp" && a.country === patient.location)) {
    fhirPatient.address.push({
      use: "temp",
      country: patient.location,
    });
  }
  if (patient.phone && !fhirPatient.telecom.find(t => t.value === patient.phone)) {
    fhirPatient.telecom.push({
      use: "mobile",
      system: "phone",
      value: patient.phone,
    });
  }

  if (patient.phones) {
    patient.phones.forEach(phone => {
      if (!fhirPatient.telecom.find(t => t.value === phone.value)) {
        fhirPatient.telecom.push({
          use: "home",
          system: "phone",
          value: phone.value,
        });
      }
    });
  }
  if (patient.email && !fhirPatient.telecom.find(t => t.value === patient.email)) {
    fhirPatient.telecom.push({
      use: "home",
      system: "email",
      value: patient.email,
    });
  }
  if (patient.identifier && patient.identifier.length > 0) {
    // de-dupe identifiers
    fhirPatient.identifier = [...new Map(patient.identifier.map(id => [id["value"] + id["system"], id])).values()];
  } else {
    // fhirPatient.identifier.push({
    //   use: "usual",
    //   system: systemUri,
    //   value: patient.userID ? patient.userID : patient.email,
    // });
  }
  if (patient.ssn) {
    fhirPatient.identifier.push({
      use: "usual",
      system: systemUrn,
      value: patient.ssn,
    });
  }
  fhirPatient.extension ??= [];
  if (patient.importance) {
    fhirPatient.extension.push(
      {
        url: "http://hl7.org/fhir/StructureDefinition/patient-importance",
        valueCodeableConcept: {
          coding: [
            {
              code: "High",
            },
          ],
        },
      },
    );
  }
  //add each of the patient.fhirPatient extension to the fhirPatient if not already present
  if (patient.fhirPatient) {
    patient.fhirPatient.extension?.forEach(extension => {
      if (!fhirPatient.extension.find(e => e.url === extension.url)) {
        fhirPatient.extension.push(extension);
      }
    });
  }

  return fhirPatient;
}

function fhirToPatient(fhirPatient) {
  if (!fhirPatient) return null;
  const telecom = fhirPatient.telecom ?? [];
  const name = fhirPatient.name?.[0];
  const address = fhirPatient.address?.find(a => a.use === "home") ?? fhirPatient.address?.[0];
  const ssn = fhirPatient.identifier?.find(id => id.system === systemUrn);
  const email = telecom?.find(({ system }) => system === "email");

  return {
    email: email?.value, //email?.value,
    phones: telecom?.filter(({ system }) => system === "phone"),
    userID: fhirPatient.identifier?.find(id => id.system === systemUri)?.value,
    fhirPatient: fhirPatient,
    id: fhirPatient.id,
    identifier: fhirPatient.identifier ?? [],
    extension: fhirPatient.extension ?? [],
    ssn: ssn?.value,
    lastUpdated: fhirPatient.meta ? new Date(fhirPatient.meta.lastUpdated).toLocaleDateString("en-US") : null,
    name: renderName(name),
    lastName: name?.family?.toString(),
    dob: fhirPatient.birthDate,
    telecom: telecom,
    gender: fhirPatient.gender,
    location: fhirPatient.address?.find(a => a.use == "temp")?.country ?? (address?.country ?? 'US'),
    address: address?.line,
    city: address?.city,
    state: address?.state,
    country: address?.country,
    postalCode: address?.postalCode,
    instanceID: fhirPatient.managingOrganization?.reference?.replace("Organization/", ""),
    instance: fhirPatient.managingOrganization?.display,
    instanceName: fhirPatient.managingOrganization?.display,
    importID: fhirPatient.extension?.find(e => e.url == "/import")?.valueString,
    tags: fhirPatient.meta?.tag,
    importance: fhirPatient.extension?.find(e => e.url == "http://hl7.org/fhir/StructureDefinition/patient-importance") ? 1 : 0,
  };
}

function formToQuestionnaire(form) {
  return {
    id: form.questionnaireID,
    name: form.name,
    status: "active",
    locked: form.locked,
    item: form.fields
      // .filter((f) => {
      //     f.readOnly;
      // })
      .map((f, index) => {
        return {
          linkId: `${form.id}.${index}`,
          text: f.label,
          type: f.type,
          required: f.required,
          answerOption: f.answerOption
            ? f.answerOption.map(o => {
              return { valueString: o.value };
            })
            : null,
          readOnly: f.readOnly ? true : undefined
        };
      }),
  };
}

function renderQuestionnaire(form) {
  if (!form) return {};
  return {
    questionnaire: `Questionnaire/${form.questionnaireID}`,
    status: "completed",
    item: form.fields?.map((f, index) => {
      return {
        answer: [
          {
            valueString: f.renderType == "Table" ? renderEmptyAnswers(f) : null,
          },
        ],
        linkId: `${form.id}.${index}`,
      };
    }),
  };
}

function renderAnswers(response, form) {
  if (!response.item) return response;
  response.item.map((i, index) => {
    if (!i.answer) {
      i.answer = [{}];
    } else if (i.answer.length > 1) {
      i.answer = [{ valueString: i.answer.map(a => a.valueString) }];
    } else {
      var field = form.fields[index];
      if (field && (field.renderType == "Multiple Choice" || field.renderType == "Dropdown")) {
        i.answer[0].valueString = [i.answer[0].valueString];
      }
    }
    return i;
  });
  return response;
}

function renderEmptyAnswers(field) {
  var answer = {};
  field.answerOption.forEach(o => {
    answer[o.label] = null;
  });
  return answer;
}

function responseToVM(response) {
  return {
    lastUpdated: new Date(response.meta.lastUpdated).toLocaleDateString("en-US"),
    id: response.id,
    answers: response.item
      ? response.item.map(item => {
        return {
          text: item.text,
          answer: item?.answer?.[0]?.valueString
        };
      })
      : [],
  };
}

function createDocumentReference(file, patientID, instanceID) {
  return {
    resourceType: "DocumentReference",
    status: "current",
    docStatus: "preliminary",
    subject: {
      reference: "Patient/" + patientID,
    },
    date: new Date().toISOString("en-US"),
    author: [
      {
        reference: "Patient/" + patientID,
      },
    ],
    custodian: {
      reference: instanceID,
    },
    description: file.description,
    securityLabel: [
      {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/v3-Confidentiality",
            code: "V",
            display: "very restricted",
          },
        ],
      },
    ],
    content: [
      {
        attachment: {
          contentType: file.type,
          url: "Binary/" + encodeURIComponent(file.binaryID),
          size: file.size,
          title: file.name,
          creation: file.lastUpdated,
        },
      },
    ],
  };
}

function docRefToVM(docref) {
  let content = docref.content?.[0].attachment;
  if (!content) return {};
  return {
    ...docref,
    id: docref.id,
    description: docref.description || docref.type?.coding?.map(coding => coding.display).join(" | "),
    name: content.title,
    size: content.size ? Math.round(content.size / 1024) + " KB" : null,
    type: content.contentType,
    url: content.url,
    patient: docref.subject ? docref.subject.reference.replace("Patient/", "") : null,
  };
}

function createFhirAppointment(model) {
  const name = typeof model.patient.name === "string" ? model.patient.name : model.patient.name[0].text ?? `${model.patient.name[0]} ${model.patient.name[0].given[0]}`;
  return {
    resourceType: "Appointment",
    status: "proposed",
    // "specialty": [
    //   {
    //     "coding": [
    //       {
    //         "system": "http://snomed.info/sct",
    //         "code": "394814009",
    //         "display": "General practice"
    //       }
    //     ]
    //   }
    // ],
    appointmentType: {
      coding: [
        {
          code: model.appointmentType?.id,
        },
      ],
      text: model.appointmentType?.name,
    },
    description: model.description,
    comment: model.comment,
    participant: [
      {
        actor: {
          reference: "Patient/" + model.patient.id,
          display: name,
        },
        required: "required",
        status: "needs-action",
      },
    ],
  };
}

function createTransactionBundle(entries) {
  return {
    resourceType: "Bundle",
    type: "transaction",
    entry: entries
  };
}

function createDeleteBundle(resourceType, resources) {
  return {
    resourceType: "Bundle",
    type: "transaction",
    entry: resources.map(resource => {
      return {
        request: {
          method: "DELETE",
          url: resourceType + "/" + resource
        },
      };
    }),
  };
}

function findAssignee(appointment) {
  return appointment.participant ? appointment.participant.find(p => (p.type ? p.type.find(t => t.coding.find(c => c.code == "ADM")) : false)) : null;
}

function findAppointmentPatient(appointment) {
  return appointment.participant ? appointment.participant.find(p => p.actor.reference.toLowerCase().includes("patient/")) : null;
}

function updateAppointmentRank(appointment, rank) {
  if (!appointment.extension) appointment.extension = [];

  appointment.extension = appointment.extension.filter(e => e.url != "ranking");

  appointment.extension.push({
    url: "ranking",
    valueString: rank,
  });

  return appointment;
}

function updateAppointmentAssignee(user, appointment, type) {
  type = type ?? [
    {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
          code: "ADM",
        },
      ],
    },
  ];
  let practitionerID = user?.practitionerID ?? user?.id;
  appointment.participant = appointment.participant?.filter(p => !p.type || p.type[0].coding[0].code !== type[0].coding[0].code) ?? [];
  if (practitionerID) {
    const displayName = typeof user.name === "string" ? user.name : (user.name?.[0] ? renderName(user.name[0]) : user.text);
    appointment.participant.push({
      type: type,
      actor: {
        reference: "Practitioner/" + practitionerID,
        display: displayName,
      },
      required: "required",
      status: "accepted",
    });
  }
  return appointment;
}
function removeParticipant(appointment, reference, type) {
  appointment.participant = appointment.participant.filter(p => p.actor.reference !== reference || p?.type?.[0].coding?.[0].code !== type);
  return appointment;
}
function createPractitioner(user) {
  let firstName = user.name
    .split(" ")
    .slice(0, -1)
    .join(" ");
  let lastName = user.name
    .split(" ")
    .slice(-1)
    .join(" ");
  return {
    resourceType: "Practitioner",
    identifier: [
      {
        system: systemUri,
        value: user.userID, //TODO: does this work?
      },
    ],
    active: true,
    name: [
      {
        text: user.name,
        family: lastName,
        given: [firstName],
      },
    ],
  };
}
function fhirToPractitioner(resource) {
  var rating = resource.extension?.find(e => e.url == "rating")?.valueCodeableConcept.coding[0].code ?? null;
  rating = rating ? +parseFloat(rating).toFixed(2) : null;

  return {
    ...resource, rating,
    nameString: renderName(resource.name),
    user: resource.identifier?.find(i => i.system === 'https://orbital-health.firebaseapp.com')?.value,
  };
}
function mapPractitionerBundle(bundle) {
  let roles = bundle.entry?.filter(e => e.resource.resourceType === "PractitionerRole").map(e => e.resource);
  let practitioners = bundle.entry?.filter(e => e.resource.resourceType === "Practitioner")
    .map(e => fhirToPractitioner(e.resource))
    .map(p => {
      let proles = roles.filter(r => r.practitioner.reference.includes(p.id));
      p.locations = proles.map(r => r.location?.map(l => l.display)).flat();
      return p;
    });

  return { practitioners, roles };
}

function fhirBundleToPractitioner(resource) {
  let practitioner = resource.entry.find(e => e.resource.resourceType === "Practitioner")?.resource;
  if (practitioner) {
    var rating = practitioner.extension?.find(e => e.url == "rating")?.valueCodeableConcept.coding[0].code ?? null;

    practitioner.rating = rating ? +parseFloat(rating).toFixed(2) : null;
    practitioner.role = resource.entry.find(e => e.resource.resourceType === "PractitionerRole")?.resource;
    practitioner.notes = resource.entry.filter(e => e.resource.resourceType === "Composition").map(e => e.resource);
    practitioner.locations = practitioner.role?.location ? practitioner.role.location : [];
    if (!practitioner.name[0].given) practitioner.name[0].given = [null];
    if (!practitioner.name[0].text) practitioner.name[0].text = renderName(practitioner.name[0]);
    practitioner.text ??= { status: "generated", div: `<div xmlns="http://www.w3.org/1999/xhtml">${practitioner.name[0].text}</div>` };
  }
  return practitioner;
}

function practitionerToFhir(practitioner) {
  if (practitioner.rating) {
    practitioner.extension = practitioner.extension ? practitioner.extension.filter(e => e.url != "rating") : [];
    practitioner.extension.push({
      url: "rating",
      valueCodeableConcept: {
        coding: [
          {
            system: "rating",
            code: practitioner.rating,
          },
        ],
      },
    });
  }
  delete practitioner.rating;
  delete practitioner.role;
  delete practitioner.locations;
  delete practitioner.notes;
  return practitioner;
}

function findAppointmentParams(providerID, departmentIDs, visitTypeIDs, startDate, endDate) {
  let p = {
    resourceType: "Parameters",
    parameter: [
      {
        name: "startTime",
        valueDateTime: startDate,
      },
      {
        name: "endTime",
        valueDateTime: endDate,
      },
      {
        name: "practitioner-reference",
        valueReference: {
          reference: "practitioner/" + providerID,
        },
      },
    ],
  };
  if (this.departmentIDs) {
    p.departmentIDs = departmentIDs.map(function (id) {
      return {
        name: "location-reference",
        valueReference: {
          reference: "location/" + id,
        },
      };
    });
  }
  if (this.visitTypeIDs) {
    p.visitTypeIDs = visitTypeIDs.map(function (id) {
      return {
        name: "service-type",
        valueCodeableConcept: {
          coding: [
            {
              system: "Epic",
              code: id,
            },
          ],
        },
      };
    });
  }
  return p;
}

function fhirToAppointment(fhirAppointment, statuses) {
  var patient = fhirAppointment.participant.find(p => p.actor.reference.includes("Patient/"));
  return {
    status: statuses?.find(s => s.fhirStatus == fhirAppointment.status).name,
    description: fhirAppointment.description,
    comment: fhirAppointment.comment,
    patient: patient.actor.display,
    patientID: patient.actor.reference.replace("Patient/", ""),
  };
}

function renderName(name) {
  if (!name) return null;
  if (name instanceof String) return name;
  if (Array.isArray(name)) return renderName(name[0]);
  if (name.text) return name.text;
  if (name.display) return name.display;
  var resp = name.given ? name.given.join(" ") : null;
  resp += name.family ? " " + name.family : "";
  return resp;
}

function addTagsToResource(tags, resource, system) {
  if (!resource.meta.tag) resource.meta.tag = [];
  tags.forEach(tag => {
    resource.meta.tag.push({
      system: system ? system : "http://hl7.org/fhir/sid/icd-10",
      code: tag,
    });
  });

  return resource;
}

function mapResponse(source) {
  var response = { ...source };
  if (response.item) {
    response.item = response.item.filter(i => i.answer[0].valueString);
    response.item.forEach(i => {
      if (Array.isArray(i.answer[0].valueString)) {
        i.answer = i.answer[0].valueString.map(v => {
          return {
            valueString: v,
          };
        });
      }
    });
  }
  return response;
}
function mapResponseFromTaskRequest(taskRequest) {
  return {
    subject: taskRequest.task.for,
    questionnaire: "Questionnaire/" + taskRequest.form.questionnaireID,
    status: "in-progress",
  };
}

function personToUser(person) {
  return {
    name: renderName(person?.name?.[0]),
    userID: person?.identifier[0].value,
  };
}

function mapTaskToVM(t, _practitioners, instances) {
  if (!t) return null;
  const practitionerID = t.owner?.reference?.replace("Practitioner/", "");
  const instanceID = t.extension?.find(e => e.url === "patientManagingOrganization")?.valueReference?.reference?.replace("Organization/", "");
  const instance = instances?.find(i => i.fhirID === instanceID);

  return {
    ...t,
    id: t.id,
    //subtitle: t?.code?.text == "Second Opinion" ? tasks + " of " + total + " tasks" : t?.for?.display,
    // subtitleLogo: instance?.icon || instance?.logo,
    logo: instance?.icon || instance?.logo,
    lastUpdated: t.meta?.lastUpdated,
    assigneeID: practitionerID,
    updating: false,
    status: t.status,
    cardTitle: t?.description ?? t.focus?.display ?? t?.for?.display ?? t?.code?.text ?? "Task",
    patientID: t?.for?.reference?.replace("Patient/", ""),
    date: t?.restriction?.period?.start ?? t.authoredOn,
    type: t?.code?.text,
    businessStatus: t?.businessStatus,
    instanceID: instanceID,
    instanceName: instance?.name,
    resourceType: 'Task',
    patient: t?.for,
    done: t?.status == "completed",
  };
}

function mapAppointmentToVM(a, practitioners, instances) {
  const patientID = a.participant?.find(p => p.actor.reference.includes("Patient")).actor.reference.replace("Patient/", "");
  var assignee = findAssignee(a);
  const practitionerID = assignee?.actor?.reference.replace("Practitioner/", "");
  var practitioner = practitioners?.find(p => p.id == practitionerID);
  const instanceID = a.extension?.find(e => e.url == "patientManagingOrganization")?.valueReference?.reference?.replace("Organization/", "");
  const instance = instances.find(i => i.fhirID == instanceID);

  const patient = findAppointmentPatient(a);
  return {
    id: a.id,
    patient: patient,
    patientID,
    icon: "mdi-calendar-clock",
    logo: instance?.icon || instance?.logo,
    cardTitle: patient?.actor?.display ?? a.description ?? a.comment ?? a.appointmentType?.text ?? "Appointment",
    lastUpdated: a.meta?.lastUpdated,
    assigneeID: practitionerID,
    assignee: practitioner,
    updating: false,
    status: a.status,
    date: a?.start,
    instanceID: instanceID,
    instanceName: instance?.name,
    resourceType: 'Appointment',
  };
}

//map service request to VM
function mapServiceRequestToVM(sr, practitioners, instances) {
  if (!sr) return null;
  const practitionerID = sr.owner?.reference?.replace("Practitioner/", "");
  var practitioner = practitioners?.find(p => p.id === practitionerID);
  const instanceID = sr.extension?.find(e => e.url == "patientManagingOrganization")?.valueReference.reference.replace("Organization/", "");
  const instance = instances?.find(i => i.fhirID == instanceID);
  return {
    id: sr.id,
    subtitle: sr?.for?.display,
    subtitleLogo: instance?.icon || instance?.logo,
    logo: instance?.icon || instance?.logo,
    lastUpdated: sr.meta?.lastUpdated,
    assigneeID: practitioner?.id,
    assignee: practitioner,
    updating: false,
    status: sr.status,
    cardTitle: sr?.subject?.display,
    patientID: sr?.for?.reference?.replace("Patient/", ""),
    date: sr?.restriction?.period?.start,
    type: sr?.code?.text,
    instanceID: instanceID,
    instanceName: instance?.name,
    resourceType: 'ServiceRequest'
  };
}

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}
function mapfhirProperties(prefix, properties, types, depth = 0) {
  if (depth > 5 || !properties) return [];
  let ignoreProps = ["resourceType", "implicitRules", "language", "contained", "extension", "modifierExtension", "div"];
  let props = [];

  Object.keys(properties).forEach(p => {
    if (ignoreProps.includes(p)) return;
    let prop = properties[p];
    if (p.startsWith("_") || prop.hidden) return;
    let name = prefix ? `${capitalizeFirstLetter(prefix)}.${capitalizeFirstLetter(p)}` : `${capitalizeFirstLetter(p)}`;
    if (prop.type === "array") {
      let childType = prop.items.$ref ? prop.items.$ref.split("/").pop() : prop.items.type;
      let childProps = types?.[childType]?.properties;
      if (childProps) {
        props = props.concat(mapfhirProperties(name, childProps, types, depth + 1));
      } else {
        props.push({ name, depth });
      }
    } else if (prop.$ref) {
      let childType = prop.$ref.split("/").pop();
      let typesToSkip = ["Element", "Resource"];
      let childProps = typesToSkip.includes(childType) ? null : types?.[childType]?.properties;
      if (childProps) {
        props = props.concat(mapfhirProperties(name, childProps, types, depth + 1));
      } else {
        props.push({
          name: name,
          value: name,
          type: prop.type,
          required: prop.required,
          description: prop.description,
          depth: depth,
        });
      }
    } else {
      props.push({
        name: name,
        value: name,
        type: prop.type,
        required: prop.required,
        description: prop.description,
        depth: depth,
      });
    }
  });
  return props;
}

function findPatientID(resource) {
  let patientID = '';

  if (!resource) {
    return patientID;
  }

  try {
    switch (resource.typeName) {
      case 'Patient':
        patientID = resource.id;
        break;
      case 'Appointment':
        patientID = resource.participant.find(p => p.actor.reference.includes("Patient")).actor.reference.split("/")[1];
        break;
      case 'Task':
        patientID = resource.for.reference.split("/")[1];
        break;
      default:
        patientID = resource?.subject?.reference?.split("/")[1];
    }
  } catch (error) {
    console.error('Error finding patient ID:', error);
  }

  return patientID;
}
function updateTaskStatusReason(task) {
  //update the statusReason
  let time = new Date().toISOString();
  if (!task.statusReason) task.statusReason = {};
  task.statusReason.text = time;
  task.statusReason.coding ??= [];
  task.statusReason.coding.push(
    {
      code: task.status,
      display: time,
    },
  );

  return task;
}
function parseActionCondition(action) {
  //find the statuses mentioned in each condition expression.expression
  let statuses = [];
  for (let condition of action.condition) {
    let expression = condition.expression?.expression;
    if (!expression) continue;
    let status = expression.split("Task.businessStatus = `")[1]?.split("`")?.[0];
    status ??= expression.split("Task.businessStatus.text = `")[1]?.split("`")?.[0];
    if (status) statuses.push(status);
  }
  return statuses;
}

function parseActionLastUpdated(action) {
  try {
    //find the lastUpdated mentioned in each condition expression.expression
    for (let condition of action.condition) {
      let expression = condition.expression?.expression;
      if (!expression || !expression.includes("Task.meta.lastUpdated")) continue;
      let lastUpdated = condition.expression?.expression?.replace("Task.meta.lastUpdated > today() - ", "");

      let days = parseInt(lastUpdated);
      if (isNaN(days)) return null;
      var date = new Date();
      date.setDate(date.getDate() - days);

      return date.toISOString();
    }
  } catch (err) {
    console.log(err);
  }

  return null;
}

export {
  updateTaskStatusReason,
  mapfhirProperties,
  resourceTypes,
  parseActionCondition,
  parseActionLastUpdated,
  init,
  patientToFhir,
  fhirToPatient,
  createDocumentReference,
  docRefToVM,
  formToQuestionnaire,
  renderQuestionnaire,
  responseToVM,
  createFhirAppointment,
  createDeleteBundle,
  createTransactionBundle,
  findAssignee,
  findAppointmentPatient,
  updateAppointmentAssignee,
  createPractitioner,
  findAppointmentParams,
  fhirToAppointment,
  updateAppointmentRank,
  renderName,
  addTagsToResource,
  mapResponse,
  mapResponseFromTaskRequest,
  renderAnswers,
  fhirToPractitioner,
  fhirBundleToPractitioner,
  practitionerToFhir,
  stringToName,
  personToUser,
  removeParticipant,
  mapTaskToVM,
  mapAppointmentToVM,
  mapServiceRequestToVM,
  findPatientID,
  mapPractitionerBundle
};
