<template>
  <v-app-bar-nav-icon v-if="$vuetify.display.mdAndDown" @click="toggleDrawer" />
  <v-navigation-drawer v-model="drawer" width="240">
    <template #prepend>
      <div class="ma-2" style="height: 40px">
        <v-menu v-if="currentPatient && currentPatient.name" location="bottom" close-on-content-click max-height="500">
          <template #activator="{ props }">
            <span v-bind="props">
              <v-list-item :title="currentPatient.name" append-icon="mdi-menu-down" class="pa-0">
                <template #prepend class="mr-2">
                  <Avatar size="26" :user="currentPatient"></Avatar>
                </template>
                <v-list-item-subtitle>Patient</v-list-item-subtitle>
              </v-list-item>
            </span>
          </template>
          <v-list min-width="250">
            <v-list-item v-for="(item, index) in filteredPatients" :key="index" @click="changePatient(item)">
              <template #prepend>
                <Avatar size="32px" :user="item"></Avatar>
              </template>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="registerDialog = true">
              <template #prepend>
                <v-icon>mdi-plus</v-icon>
              </template>
              <v-list-item-title>Register New Patient</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-img v-else :alt="instance.name ? instance.name : ''" class="ma-4 shrink" :src="instance.logo" max-height="32" cover />
      </div>
    </template>
    <v-list id="side-nav" density="compact" nav>
      <template v-for="item in routes">
        <v-row v-if="item.heading" :key="item.heading">
          <v-col cols="6">
            <v-list-subheader v-if="item.heading" class="font-weight-bold">
              {{ item.heading }}
            </v-list-subheader>
          </v-col>
        </v-row>
        <v-list-item v-else-if="showSection(item)" :key="item.text" link :to="item.path">
          <template #prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
          <span class="showOverflow">
            <v-list-item-title class="showOverflow font-weight-bold text-body-1">
              {{ item.text }}
            </v-list-item-title>
          </span>
        </v-list-item>
      </template>
    </v-list>
    <template #append>
      <v-list :class="!$vuetify.display.mobile ? 'portal-drawer-bottom' : ''">
        <v-list-item v-if="instance && instance.contactInfo" link @click="showHelp">
          <template #prepend>
            <v-icon size="20" class="text-grey">mdi-help-circle</v-icon>
          </template>
          <v-list-item-title class="text-grey text-body-2">Help</v-list-item-title>
        </v-list-item>
        <v-list-item value="user" @click="logout">
          <template #prepend>
            <v-icon color="grey-lighten-1">mdi-logout-variant</v-icon>
          </template>
          <v-list-item-title class="text-grey text-body-2">Log Out</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <template #prepend>
            <Avatar size="32px" :user="user"></Avatar>
          </template>
          <v-list-item-title class="font-weight-bold" v-text="renderUserName"></v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
  <v-dialog v-if="instance" v-model="helpDialog" transition="dialog-bottom-transition" width="auto ">
    <v-card>
      <v-card-title class="text-h5">Support</v-card-title>
      <v-card-text class="text-subtitle-1" v-text="instance.contactInfo"></v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-if="registerDialog" v-model="registerDialog" transition="dialog-bottom-transition" width="auto " persistent :fullscreen="$vuetify.display.mobile">
    <v-card>
      <AdminRegister :skip-refresh="true" @close-dialog="closeRegDialog"></AdminRegister>
    </v-card>
  </v-dialog>
  <v-btn variant="elevated" color="primary" class="floating-button" @click="registerDialog = true">New Patient</v-btn>
</template>
<script>
import router from "../router";
import { AUTH_LOGOUT } from "../store/auth/mutation-types";
import { LOAD_PATIENTS, LOAD_PATIENT_BY_ID, SET_PATIENT } from "../store/patients/mutation-types";
import { mapState, mapActions } from "vuex";
import { LOAD_INSTANCE_BY_ID } from "../store/instances/mutation-types";
import AdminRegister from "./register/admin.vue";
const prefixes = ["Mr.", "Ms.", "Mrs.", "Dr."];

export default {
  name: "PortalDrawer",
  components: { AdminRegister },
  data: () => ({
    dialog: false,
    registerDialog: false,
    drawer: null,
    instanceID: localStorage.getItem("instanceID"),
    helpDialog: false,
  }),
  mounted() {},
  async created() {
    if (!this.instance) await this.LOAD_INSTANCE_BY_ID(this.instanceID);
    if (this.instance?.name) {
      this.instanceID = this.instance.id;
      document.title = this.instance.name;
      if (this.instance.icon && document.getElementById("favicon")) {
        document.getElementById("favicon").href = this.instance.icon;
      }
    }
    let id = this.$route.params.id ?? this.user?.patientID ?? this.user?.patients?.[0];
    if (!this.currentPatient?.id && id) await this.LOAD_PATIENT_BY_ID({ id: id });
    await this.LOAD_PATIENTS();
    if (!this.currentPatient?.id && this.patients?.[0]) this.changePatient({ id: this.patients?.[0].id });
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("patients", ["currentPatient", "patients", "isLoadingPatient"]),
    ...mapState("instances", ["instance", "loading"]),
    renderUserName() {
      if (!this.user || !this.user?.name) return;
      let name = this.user?.name;
      prefixes.map(p => (name = name.replace(p, "").trim()));
      if (name.length > 16) name = name.substring(0, name.indexOf(" "));
      return name;
    },
    filteredPatients() {
      return this.patients?.filter(p => p.id !== this.currentPatient.id);
    },
    routes() {
      const id = this.currentPatient?.id ?? this.$route.params.id;
      if (!id) return [];
      return this.items.map(i => {
        return { ...i, path: `/portal/${id}/${i.path}` };
      });
    },
    items() {
      let menuItems = [
        {
          icon: "mdi-home",
          text: "Home",
          path: "",
        },
        {
          icon: "mdi-account",
          text: "Patient",
          path: "patient",
          requirePatient: true,
        },
        // {
        //   icon: "mdi-folder",
        //   text: "Files",
        //   path: "files",
        //   requirePatient: true,
        // },
        {
          icon: "mdi-currency-usd",
          text: "Billing",
          path: "billing",
          requirePatient: true,
        },
        {
          icon: "mdi-account-circle",
          text: "User",
          path: "profile",
        },
      ];
      if (this.currentPatient?.id) return menuItems;

      return menuItems.filter(i => !i.requirePatient);
    },
  },
  methods: {
    ...mapActions("auth", [AUTH_LOGOUT]),
    ...mapActions("patients", [LOAD_PATIENTS, LOAD_PATIENT_BY_ID, SET_PATIENT]),
    ...mapActions("instances", [LOAD_INSTANCE_BY_ID]),
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    logout() {
      this.AUTH_LOGOUT();
    },
    showHelp() {
      this.helpDialog = true;
    },
    showSection(item) {
      if (!item.feature) return true;
    },
    changePatient(patient) {
      if (!patient.id || patient.id == "home") return;
      router.replace({ path: `/portal/${patient.id}/` });
      this.SET_PATIENT(patient.id);
      this.LOAD_PATIENT_BY_ID({ id: patient.id, noCache: true });
    },
    closeRegDialog(patient) {
      this.registerDialog = false;
      this.LOAD_PATIENTS(true).then(() => {
        if ((!this.currentPatient || !this.currentPatient.name || patient.id) && this.patients?.length > 0) {
          this.changePatient(patient);
        }
      });
    },
  },
};
</script>
<style>
#patient-portal-bar .v-theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar {
  background-color: inherit !important;
}

.portal-drawer-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.floating-button {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1000;
}
</style>
