import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  instances: [],
  instance: {
    locked: false,
    logo: null,
    name: null,
    icon: null,
  },
  loading: false,
  error: null,
};

const getters = {
  getInstances: state => state.instances,
  getInstance: state => state.instance,
  getSimpleInstances: state =>
    state.instances?.map(i => {
      return { name: i.name, icon: i.icon, id: i.id, iconName: i.iconName, fhirID: i.fhirID };
    }),
};
export const mutations = {
  [types.LOAD_INSTANCES]: state => {
    state.error = null;
    state.loading = true;
  },
  [types.LOAD_INSTANCES_SUCCESS]: (state, instances) => {
    state.instances = instances;
    state.loading = false;
  },
  [types.LOAD_INSTANCES_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.LOAD_INSTANCE_BY_ID]: (state) => {
    state.loading = true;
  },
  [types.LOAD_INSTANCE_BY_ID_SUCCESS]: (state, instance) => {
    state.instance = instance;
    state.loading = false;
  },
  [types.LOAD_INSTANCE_BY_ID_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.UPDATE_INSTANCE]: state => {
    state.loading = true;
  },
  [types.UPDATE_INSTANCE_SUCCESS]: (state, instance) => {
    state.instances.splice(
      state.instances.findIndex(i => i.id === instance.id),
      1,
      instance
    );
    state.instance = instance;
    state.loading = false;
  },
  [types.UPDATE_INSTANCE_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.CREATE_INSTANCE]: state => {
    state.loading = true;
  },
  [types.CREATE_INSTANCE_SUCCESS]: (state, instance) => {
    state.loading = false;
    state.instances.push(instance);
    state.instance = instance;
  },
  [types.CREATE_INSTANCE_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.RESET_INSTANCE]: state => {
    state.instance = {};
    state.loading = false;
  },
  [types.DELETE_INSTANCE]: state => {
    state.loading = true;
  },
  [types.DELETE_INSTANCE_SUCCESS]: (state, instanceID) => {
    const index = state.instances.findIndex(i => i.id === instanceID);
    if (index >= 0) state.instances.splice(index, 1);
    if (state.instance.id === instanceID) state.instance = {};
    state.loading = false;
  },
  [types.DELETE_INSTANCE_FAILURE]: state => {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
