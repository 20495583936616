<template>
  <v-autocomplete
    :model-value="value" v-bind="$attrs" single-line class="mt-0 flex-nowrap" color="grey"
    :custom-filter="filter" @update:model-value="update"
  >
    <template #chip="{ props, item }">
      <v-chip v-bind="props" :text="item.title"></v-chip>
    </template>

    <template #selection="{ item, index }">
      <v-list-item v-if="index == 0" title="" class="pa-0">
        <Avatar size="24px" left :user="item">
        </Avatar>
        <span class="ml-1 pa-2"> {{ item.title }}</span>
      </v-list-item>
    </template>
    <template #item="{ props, item }">
      <v-list-item v-bind="props" title="">
        <Avatar v-if="item.title !== 'Unassign'" size="28px" :user="item">
        </Avatar>
        <span class="ml-1 pa-2"> {{ renderName(item) }}</span>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import * as fhirHelper from "../../utils/fhir-helpers.js";

export default {
  inheritAttrs: true,
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  methods: {
    update(val) {
      this.$emit("update", val);
    },
    filter: (item, queryText) => {
      return item.title?.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    renderName(person) {
      if (person.title) return person.title;
      if (person instanceof String) return person;
      return fhirHelper.renderName(person.name);
    },
  },
};
</script>