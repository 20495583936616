<template>
  <v-card>
    <v-card-title class="pl-4">
      <span class="text-h5 font-weight-bold">Import Providers</span>
      <v-btn variant="text" class="mr-2 float-right" icon @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-row no-gutters class="d-flex align-stretch">
        <v-col cols="3" style="border-right: 1px solid #dddddd">
          <v-text-field v-model="dialogSearch" autofocus autocomplete="off" class="search-box pa-4" label="Search" single-line hide-details density="compact" @change="updateSearch">
            <template #append>
              <v-fade-transition leave-absolute>
                <v-progress-circular v-if="loading" size="24" color="grey" indeterminate></v-progress-circular>
                <v-icon v-else>mdi-magnify</v-icon>
              </v-fade-transition>
            </template>
          </v-text-field>
          <v-container fluid class="pa-0">
            <v-list style="overflow-y: auto; border-top: 1px solid #ddd" class="full-height-below-nav">
              <span v-if="!dialogSearch && mapProviders && mapProviders.length == 0" class="text-caption pa-2">Search by name to get started</span>
              <span v-else-if="dialogSearch && mapProviders && mapProviders.length == 0 && !loading" class="text-caption pa-2">No results</span>
              <v-list-item v-else-if="loading && mapProviders && mapProviders.length == 0" id="skeleton-results">
                <template v-for="index in 10" :key="'s-div-' + index">
                  <v-skeleton-loader type="list-item-avatar" height="88px" class="pt-2"></v-skeleton-loader>
                  <v-divider></v-divider>
                </template>
              </v-list-item>
              <v-list-item v-else v-model="activeProviderIndex" color="primary">
                <template v-for="(provider, index) in mapProviders" :key="'div-' + index">
                  <li class="provider-search-entry d-flex align-center" @click="activeProviderIndex = index">
                    <Avatar :user="provider" :size="40"></Avatar>
                    <div class="flex-grow-1 ms-4 overflow-hidden">
                      <span>{{ provider.name }}</span>
                    </div>
                    <div v-if="provider.imported" class="d-flex flex-column align-self-start">
                      <v-icon v-if="provider.imported" color="green">mdi-check-circle</v-icon>
                    </div>
                  </li>
                  <v-divider></v-divider>
                </template>
              </v-list-item>
            </v-list>
          </v-container>
        </v-col>
        <v-col cols="9" style="overflow: scroll; height: 100%">
          <v-container fluid class="pa-0">
            <ProviderProfile v-if="activeProvider" v-model:practitioner="activeProvider.resource" :locations="activeProvider.locations" :can-edit="false"></ProviderProfile>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="pa-3">
      <v-spacer></v-spacer>
      <v-btn v-if="activeProvider" variant="flat" color="primary" :loading="processing" @click="importProviderCheck">
        <span v-if="activeProviderImported">Re-</span>
        Import
      </v-btn>
    </v-card-actions>
    <v-dialog v-if="dialog" v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">Are you sure?</v-card-title>
        <v-card-text>{{ dialogActionText }}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" variant="flat" @click="dialogAction">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script lang="js">
import { mapActions, mapState } from "vuex";
import { LOAD_INTEGRATIONS } from "../../store/integrations/mutation-types";
import { LOAD_INTEGRATION_PROVIDERS, CLEAR_INTEGRATION_PROVIDERS } from "../../store/providers/mutation-types";
import { CREATE_PRACTITIONER } from "../../store/practitioners/mutation-types";
import { LOAD_INTEGRATION_LOCATION, CREATE_LOCATION } from "../../store/locations/mutation-types";
import { colorFromString } from "../../utils/helpers";
const ProviderProfile = () => import("./ProviderProfile.vue");

export default {
  name: "ProviderImport",
  components: { ProviderProfile },
  computed: {
    ...mapState("integrations", ["integrations"]),
    ...mapState("practitioners", ["practitioners"]),
    ...mapState("locations", ["locations", "loadingLocation"]),
    ...mapState("providers", ["integrationProviders", "loading", "isDeleting", "isAdding"]),
    existingProviderIDs() {
      var idCollection = this.practitioners.filter(p => p.identifier).map(r => r.identifier);

      return idCollection.reduce((arr, ids) => {
        return arr.concat(ids.map(id => `${id.system}/${id.value}`));
      }, []);
    },
    activeProviderImported() {
      if (this.activeProvider?.resource.identifier) {
        let providerIDs = [].concat(this.activeProvider.resource.identifier.map(id => `${id.system}/${id.value}`));
        let intersection = providerIDs.filter(x => this.existingProviderIDs.includes(x));
        if (intersection && intersection.length > 0) return true;
      }
      return false;
    },
    roles() {
      return this.integrationProviders?.entry?.filter(p => p.resource.resourceType == "PractitionerRole").map(e => e.resource) ?? [];
    },
    searchProviders() {
      return this.integrationProviders?.entry?.filter(p => p.resource.resourceType == "Practitioner") ?? [];
    },
    mapProviders() {
      return this.searchProviders.map(provider => {
        var role = this.roles.filter(r => r.practitioner.reference == provider.fullUrl);
        var name = provider.resource.name[0];
        var nameString = name.text ? name.text : name.given ? name.given.join(" ") + " " + name.family : name.family;
        var photo = provider.resource.photo ? (provider.resource.photo[0].data ? `data:image/png;base64,${provider.resource.photo[0].data}` : provider.resource.photo[0].url) : null;

        var locations = role.filter(r => r.location).map(r => r.location);
        var rating = provider.resource.extension?.find(e => e.url == "rating")?.valueCodeableConcept.coding[0].code ?? null;
        delete provider.resource.id;

        let imported = false;

        if (provider.resource.identifier) {
          let providerIDs = [].concat(provider.resource.identifier.map(id => `${id.system}/${id.value}`));
          let intersection = providerIDs.filter(x => this.existingProviderIDs.includes(x));
          if (intersection && intersection.length > 0) imported = true;
        }

        return {
          id: provider.id,
          name: nameString,
          photo: photo,
          scheduling: false,
          locations: [].concat.apply([], locations),
          roles: [].concat.apply([], role),
          resource: provider.resource,
          color: colorFromString(nameString),
          rating: rating ? +parseFloat(rating).toFixed(2) : null,
          imported: imported,
        };
      });
    },
  },
  data: () => ({
    dialog: false,
    dialogAction: null,
    dialogActionText: null,
    dialogSearch: null,
    addSelected: [],
    totalProviders: 0,
    options: {},
    page: 0,
    activeProviderIndex: null,
    activeProvider: null,
    integration: null,
    processing: false,
  }),
  watch: {
    options: {
      async handler(val) {
        this.page = val.page;
        await this.updateSearch();
      },
      deep: true,
    },
    activeProviderIndex(val) {
      if (val !== null) {
        this.activeProvider = this.mapProviders[val];
        if (this.activeProvider) {
          for (let i = 0; i < this.activeProvider.locations.length; i++) {
            let locationID = this.activeProvider.locations[i]?.reference;
            if (locationID) this.LOAD_INTEGRATION_LOCATION({ locationID: locationID });
          }
        }
      } else {
        this.activeProvider = null;
      }
    },
  },
  created() {
    if (!this.integrations) this.LOAD_INTEGRATIONS();
  },
  mounted() {
    this.CLEAR_INTEGRATION_PROVIDERS();
  },
  methods: {
    ...mapActions("integrations", [LOAD_INTEGRATIONS]),
    ...mapActions("providers", [LOAD_INTEGRATION_PROVIDERS, CLEAR_INTEGRATION_PROVIDERS]),
    ...mapActions("locations", [LOAD_INTEGRATION_LOCATION, CREATE_LOCATION]),
    ...mapActions("practitioners", [CREATE_PRACTITIONER]),

    addProviderDialog() {
      this.dialogActionText = "Add";
      this.dialogAction = this.addSelectedProviders;
      this.dialog = true;
    },
    async addSelectedProviders() {
      await Promise.all(
        this.addSelected.map(async prov => {
          await this.CREATE_PRACTITIONER({ practitioner: prov.resource, roles: prov.roles, locations: prov.locations });
        })
      );
      this.dialog = false;
      this.addSelected = [];
      this.processing = false;
    },
    async updateSearch() {
      this.activeProviderIndex = null;
      let integration = this.integrations?.find(i => i.features.includes("Provider Sync") && i.status == "connected") ?? null;
      (await integration) ? this.LOAD_INTEGRATION_PROVIDERS({ search: this.dialogSearch ?? "", page: this.page, integrationID: integration.id }) : null;
      this.totalProviders = this.integrationProviders ? this.integrationProviders.total : 0;
    },
    async closeDialog() {
      await this.$emit("close-dialog");
    },
    importProviderCheck() {
      if (this.activeProviderImported) {
        this.dialogActionText = `${this.activeProvider.name} already exists in the system. Import again`;
        this.dialogAction = this.importProvider;
        this.dialog = true;
      } else {
        this.importProvider();
      }
    },
    importProvider() {
      this.processing = true;
      this.addSelected = [this.activeProvider];
      this.addSelectedProviders();
    },
    imageFailed(index) {
      this.mapProviders[index].photo = null;
      this.mapProviders[index].photoFailed = true;
    },
  },
};
</script>
<style>
.layout {
  display: inline-block;
  width: 100%;
}

.actions {
  min-width: 120px;
  text-align: right;
}

.provider-search-entry {
  padding: 8px 16px;
  cursor: pointer;
  min-height: 82px;
}
</style>
