<template>
  <div>
    <v-list-item v-bind="$attrs" @click.stop="editLocation()">
      <v-list-item-title v-if="value.display">
        <div class="" v-text="value.display"></div>
      </v-list-item-title>
      <v-list-item-subtitle v-if="value.address">
        <div v-for="line in value.address.line" :key="line" class="text-subtitle-1" v-text="line"></div>
        <div class="text-subtitle-1" v-text="formatAddressDetails(value.address)"></div>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="value.telecom">
        <div v-for="tel in value.telecom" :key="`tel-${tel.value}`" class="text-subtitle-1">
          <v-icon class="mr-2" size="small">
            {{ icon(tel.system) }}
          </v-icon>
          {{ tel.value }}
        </div>
      </v-list-item-subtitle>
      <v-list-item-action v-if="value.id && actions">
        <v-icon color="grey-lighten-1" @click.stop="deleteDialog = true">
          mdi-trash-can-outline
        </v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-dialog v-if="deleteDialog" v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Delete location?
        </v-card-title>
        <v-card-text v-if="value.display">
          {{ value.display }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" variant="flat" @click="deleteLocation">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "update",
  },
  props: ["value", "actions"],
  data: () => ({
    deleteDialog: false,
  }),
  computed: {
    originalValue() {
      return { ...this.value };
    },
  },
  created() { },
  methods: {
    icon(system) {
      switch (system) {
        case "phone":
          return "mdi-phone";
        case "fax":
          return "mdi-fax";
        case "email":
          return "mdi-email";
        default:
          return "";
      }
    },
    formatAddressDetails(address) {
      return `${address.city ? address.city : ""}${address.state ? ", " + address.state : ""} ${address.postalCode ? address.postalCode : ""}`;
    },
    editLocation() {
      this.$emit("edit-location", this.originalValue);
    },
    deleteLocation() {
      this.deleteDialog = false;
      this.$emit("delete-location", this.value);
    },
  },
};
</script>