import { defineStore } from 'pinia';
import * as api from "../../utils/api.js";
import { computeHash, formatDate } from "../../utils/helpers";

export const useRoleStore = defineStore({
  id: 'roles',
  state: () => ({
    loading: false,
    roles: [],
    role: {},
    error: null,
  }),
  getters: {

  },
  actions: {
    async loadRoles() {
      try {
        let response = await api.get("admin/role");
        this.roles = response?.data;
      } catch (error) {
        console.error("Failed to load roles:", error);
        this.error = error;
      }
    },
    async loadRole(id) {
      try {
        let response = await api.get("admin/role/" + id);
        this.role = { ...response?.data, conditions: {} };
      } catch (error) {
        console.error("Failed to load role:", error);
        this.error = error;
      }
    },
    async createRole(role) {
      try {
        let response = await api.post("admin/role", role);
        this.roles.push(response.data);

        return response.data;
      } catch (error) {
        console.error("Failed to create role:", error);
        this.error = error;
      }
    },
    async updateRole(role) {
      try {
        let response = await api.put("admin/role/" + role.id, role);
        let index = this.roles.findIndex((r) => r.id === role.id);
        this.roles.splice(index, 1, response.data);
      } catch (error) {
        console.error("Failed to update role:", error);
        this.error = error;
      }
    },
    async deleteRole(role) {
      try {
        await api.deleteResource("admin/role/" + role.id);
        let index = this.roles.findIndex((r) => r.id === role.id);
        this.roles.splice(index, 1);
      } catch (error) {
        console.error("Failed to delete role:", error);
        this.error = error;
      }
    },
  },
});
