import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
    notifications: [],
};

export const mutations = {
    [types.LOAD_SYSTEM_NOTIFICATIONS]: (state) => {
        state.error = null;
    },
    [types.LOAD_SYSTEM_NOTIFICATIONS_SUCCESS]: (state, notifications) => {
        state.notifications = notifications;
    },
    [types.LOAD_SYSTEM_NOTIFICATIONS_FAILURE]: (state, error) => {
        state.error = error;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};