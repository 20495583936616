<template>
  <v-card class="fill-height rounded-0" flat>
    <v-card-text class="pa-0">
      <v-row class="pt-2">
        <v-col cols="10">
          <v-text-field v-model="psearch" autofocus variant="outlined" autocomplete="off" class="search-box pa-4 pt-2" label="Search" single-line hide-details density="compact" clearable @update:model-value="search">
            <template #append>
              <v-fade-transition leave-absolute>
                <v-progress-circular v-if="loading" size="24" color="grey" indeterminate></v-progress-circular>
                <v-icon v-else>mdi-magnify</v-icon>
              </v-fade-transition>
            </template>
          </v-text-field>
          <span class="font-weight-bold">{{ mapProviders.length }} Providers</span>
        </v-col>

        <v-col cols="2">
          <v-badge :model-value="filters.filter(f => f.value && f.value.length > 0).length > 0" :content="filters.filter(f => f.value && f.value.length > 0).length">
            <div class="text-center">
              <v-menu v-model="menu" :close-on-content-click="false">
                <template #activator="{ props }">
                  <span v-bind="props">Filters</span>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h6">Filters</span>
                    <v-spacer></v-spacer>
                    <v-btn variant="text" @click="clearFilters">Clear</v-btn>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item v-for="filter in filters" :key="filter.id">
                      <v-list-item-title>{{ filter.name }}</v-list-item-title>
                      <v-list-item-subtitle style="width: 280px" class="pa-2">
                        <v-select v-model="filter.value" :items="filter.options" :menu-props="{ maxHeight: '400' }" label="Select" multiple variant="outlined" hide-details hint="Languages">
                          <template #selection="{ item }">
                            <v-chip>
                              <span>{{ item }}</span>
                            </v-chip>
                          </template>
                        </v-select>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </v-badge>
        </v-col>
      </v-row>
      <v-row no-gutters class="d-flex align-stretch" style="height: 100%">
        <v-col cols="4" style="border-right: 1px solid #dddddd">
          <v-container fluid class="pa-0">
            <v-list style="overflow-y: auto; border-top: 1px solid #ddd" class="full-height-below-nav">
              <span v-if="!loading && mapProviders && mapProviders.length == 0" class="text-caption pa-2">No results</span>
              <template v-for="(provider, index) in mapProviders" :key="'div-' + index">
                <v-list-item style="min-height: 82px" @click="activeProviderIndex = index">
                  <template #prepend>
                    <v-img v-if="provider.photo" alt="Avatar" :src="provider.photo" />
                    <v-avatar v-else :color="!provider.color ? '' : provider.color.name + ' text-' + provider.color.text">
                      <span v-if="provider.name" class="text-h6" v-text="provider.name.substr(0, 1)"></span>
                    </v-avatar>
                  </template>
                  <v-list-item-title class="text-subtitle-1" v-html="provider.name"></v-list-item-title>
                  <v-list-item-subtitle class="d-flex flex-row pt-1">
                    <v-rating v-model="provider.rating" length="5" density="compact" size="small" readonly></v-rating>
                    <span class="text-subtitle px-2 text-primary" v-text="provider.rating"></span>
                  </v-list-item-subtitle>
                  <v-list-item-action>
                    <v-tooltip v-if="provider.appointmentCount" location="right">
                      <template #activator="{ props }">
                        <v-avatar color="primary" class="text-white" size="28" v-bind="props">
                          <span class="text-h7" v-text="provider.appointmentCount"></span>
                        </v-avatar>
                      </template>
                      <span class="font-text-bold">{{ provider.appointmentCount }} Appointments</span>
                      <div>Most recent {{ formatDateTime(provider.mostRecentAppointment) }}</div>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-list>
          </v-container>
        </v-col>
        <v-col cols="8" style="overflow: scroll; height: 100%">
          <v-container fluid class="pa-0">
            <ProviderProfile v-if="activeProvider" v-model:practitioner="activeProvider.resource" :can-edit="false"></ProviderProfile>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="pa-3" style="background-color: #f2f5f9; height: 60px">
      <v-spacer></v-spacer>
      <v-btn v-if="activeProvider" variant="flat" color="primary" :loading="loading" @click="assignPractitioner">Assign</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import { UPDATE_APPOINTMENT } from "../../store/appointments/mutation-types";
import { colorFromString, formatDateTime } from "../../utils/helpers";
import * as fhirHelper from "../../utils/fhir-helpers.js";
import ProviderProfile from "./ProviderProfile.vue";
import * as api from "../../utils/api";

export default {
  name: "Search",
  components: { ProviderProfile },
  inheritAttrs: true,
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    currentAppointment: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    activeProvider: null,
    activeProviderIndex: null,
    psearch: null,
    loading: false,
    filters: [],
    menu: null,
    page: null,
    practitioners: [],
    pages: [],
  }),
  watch: {
    activeProviderIndex(val) {
      if (val !== null) {
        this.activeProvider = this.mapProviders[val];
      } else {
        this.activeProvider = null;
      }
    },
    $route() {
      this.closeDialog();
    },
    // practitioners() {
    //   let qualificationIssuers = [].concat.apply(
    //     [],
    //     this.mapProviders.filter(p => p.qualification).map(p => p.qualification.filter(q => q.issuer).map(q => q.issuer.display))
    //   );
    //   this.filters = [
    //     // {
    //     //   name: "Locations",
    //     //   value: null,
    //     //   options: ["test1"],
    //     //   logic: p => {
    //     //     return true;
    //     //   },
    //     // },
    //     {
    //       name: "Qualification Issuer/State",
    //       value: null,
    //       options: qualificationIssuers,
    //       logic: (filter, p) => {
    //         if (!p.qualification) return false;
    //         let issuers = p.qualification.filter(q => q.issuer).map(q => q.issuer.display);
    //         var common = issuers.filter(x => filter.value.indexOf(x) !== -1);
    //         return common.length > 0;
    //       },
    //     },
    //   ];
    // },
  },
  created() {
    this.search();
    if (!this.appointments) {
      // this.LOAD_APPOINTMENTS({});
    }
  },
  computed: {
    mapProviders() {
      if (!this.practitioners) return [];
      let filtered = this.practitioners;
      // if (this.psearch) {
      //   filtered = filtered.filter(p => {
      //     var name = p.name && p.name.length > 0 ? p.name[0] : "";
      //     let text = fhirHelper.renderName(name);
      //     if (p.meta.tag) {
      //       text = text += p.meta.tag.map(t => t.code).join();
      //     }
      //     return text.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) !== -1;
      //   });
      // }
      let activeFilters = this.filters.filter(f => f.value?.length > 0);
      if (activeFilters?.length > 0) {
        this.filters.map(f => {
          filtered = filtered.filter(p => f.logic(f, p));
        });
      }
      return filtered
        .filter(p => p.name)
        .map(provider => {
          var name = provider.name[0];
          var nameString = name.text ? name.text : name.given ? name.given.join(" ") + " " + name.family : name.family;
          var photo = provider.photo ? (provider.photo[0].data ? `data:image/png;base64,${provider.photo[0].data}` : provider.photo[0].url) : null;
          var rating = provider.extension?.find(e => e.url == "rating")?.valueCodeableConcept.coding[0].code ?? null;
          let appts = this.appointments?.filter(a => a.resource.participant.find(p => p.actor.reference.includes(provider.id)));
          let roles = this.roles?.filter(r => r.practitioner.reference.includes(provider.id));
          let locations = [].concat.apply(
            [],
            roles?.filter(r => r.location).map(r => r.location)
          );

          return {
            practitionerID: provider.id,
            name: nameString,
            photo: photo,
            resource: {
              ...provider,
              locations: locations,
            },
            color: colorFromString(nameString),
            rating: rating ? +parseFloat(rating).toFixed(2) : null,
            qualification: provider.qualification ?? [],
            appointmentCount: appts?.length,
            roles: roles,
            locations: roles?.filter(r => r.location).map(r => r.location),
            mostRecentAppointment: new Date(
              Math.max.apply(
                null,
                appts?.map(function (a) {
                  return new Date(a.resource.meta?.lastUpdated);
                })
              )
            ),
          };
        });
    },
  },
  methods: {
    ...mapActions("appointments", [UPDATE_APPOINTMENT]),
    formatDateTime,
    async closeDialog() {
      this.$emit("close-dialog");
    },
    color(value) {
      return value ? colorFromString(value) : null;
    },
    search: debounce(async function (params) {
      this.loading = true;

      const page = this.page;
      let pageToken = !page ? "" : (this.pages?.[page - 1]?.token ?? "");

      let url = "practitioner?_revinclude=PractitionerRole:practitioner&_count=25";
      if (this.psearch?.length > 0) {
        url += `&_content=${this.psearch}`;
      }
      // if (this.userFilters.states?.length > 0) {
      //   url += `&qualification-issuer-display=${this.userFilters.states.join(",")}`;
      // }
      if (pageToken) url += `&_page_token=${pageToken}`;
      const response = await api.search(url, {}, true);
      if (!response) {
        this.loading = false;
        params.fail();
        return;
      }
      const results = mapPractitionerBundle(response.data);
      let rowData = results.practitioners;

      if (rowData.length > 0) {
        this.practitioners = rowData;
      }
      this.loading = false;

      this.pages[page] = {
        token: response?.data?.link?.find(l => l.relation === "next")?.url?.split("_page_token=")[1] ?? "",
      };
    }, 500),
    assignPractitioner() {
      let type = [
        {
          coding: [
            {
              system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
              code: "PPRF",
              display: "Performer",
            },
          ],
        },
      ];
      let appointment = fhirHelper.updateAppointmentAssignee(this.activeProvider, this.currentAppointment, type);
      this.UPDATE_APPOINTMENT(appointment);
      this.closeDialog();
    },
    clearFilters() {
      this.filters.forEach(f => (f.value = null));
    },
  },
};
</script>
