export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const LOAD_ADMIN_USERS = "LOAD_ADMIN_USERS";
export const LOAD_ADMIN_USERS_SUCCESS = "LOAD_ADMIN_USERS_SUCCESS";
export const LOAD_ADMIN_USERS_FAILURE = "LOAD_ADMIN_USERS_FAILURE";

export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";

export const LOAD_USER = "LOAD_USER";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAILURE = "LOAD_USER_FAILURE";

export const CHECK_USERNAME_AVAILABILITY = "CHECK_USERNAME_AVAILABILITY";
export const CHECK_USERNAME_AVAILABILITY_COMPLETE = "CHECK_USERNAME_AVAILABILITY_COMPLETE";

export const LOAD_USER_FILTERS = "LOAD_USER_FILTERS";
export const LOAD_USER_FILTERS_SUCCESS = "LOAD_USER_FILTERS_SUCCESS";
export const UPDATE_USER_FILTERS = "UPDATE_USER_FILTERS";
export const UPDATE_USER_FILTERS_SUCCESS = "UPDATE_USER_FILTERS_SUCCESS";

export const RESET_USER = "RESET_USER";
