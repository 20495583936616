import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  patients: [],
  currentPatient: { appointments: null, docs: [], test: true },
  patientDocs: [],
  activeUploads: 0,
  isLoadingPatients: false,
  isLoadingPatient: false,
  isDeleting: false,
  processing: false,
  error: null,
  alertText: null,
  notFound: null,
  isLoadingAppointments: false,
  isLoadingDocs: false,
  responses: [],
  relatedPersons: [],
  loadingRelatedPersons: false,
};

const getters = {
  getPatientById: state => id => state.patients.find(p => p.id === id),
  getResponsesByPatient: state => patientID => state.responses.filter(r => r.subject?.reference === `Patient/${patientID}`),
  getPatientDocs: state => patientID => state.patientDocs.filter(r => r.subject?.reference === `Patient/${patientID}`),
  getCurrentPatientDocs: state => (state.currentPatient ? state.patientDocs?.filter(r => r.subject?.reference === `Patient/${state.currentPatient.id}`) : []),
  getCurrentPatientUserId: state => state.currentPatient?.identifier?.find(i => i.system == 'carelaunch' || i.system === "https://orbital-health.firebaseapp.com")?.value,
  getHasActiveUploads: state => state.activeUploads > 0,
};

const mutations = {
  [types.SET_PATIENT]: (state, patient) => {
    state.currentPatient = patient;
  },
  [types.LOAD_PATIENTS]: state => {
    state.isLoadingPatients = true;
    state.error = null;
  },
  [types.LOAD_PATIENTS_SUCCESS]: (state, patients) => {
    state.patients = patients;
    state.isLoadingPatients = false;
  },
  [types.LOAD_PATIENTS_FAILURE]: (state, error) => {
    state.isLoadingPatients = false;
    state.error = error;
  },
  [types.PATIENT_LOAD_NOTFOUND]: state => {
    state.notFound = true;
    state.isLoadingPatient = false;
  },
  [types.UPDATE_PATIENT]: state => {
    state.processing = true;
    state.error = null;
  },
  [types.UPDATE_PATIENT_SUCCESS]: (state, patient) => {
    let instanceID = state.currentPatient.instanceID;
    state.processing = false;
    state.currentPatient = { ...state.currentPatient, ...patient, instanceID: instanceID };
    state.error = null;
  },
  [types.UPDATE_PATIENT_FAILURE]: (state, error) => {
    state.processing = false;
    state.error = error;
  },
  [types.SYNC_PATIENTS]: state => {
    state.processing = true;
    state.error = null;
  },
  [types.SYNC_PATIENTS_SUCCESS]: state => {
    state.processing = false;
    state.error = null;
  },
  [types.SYNC_PATIENTS_FAILURE]: (state, error) => {
    state.processing = false;
    state.error = error;
  },
  [types.DELETE_PATIENTS]: state => {
    state.isDeleting = true;
    state.error = null;
  },
  [types.DELETE_PATIENTS_SUCCESS]: (state, patients) => {
    state.isDeleting = false;
  },
  [types.DELETE_PATIENTS_FAILURE]: (state, error) => {
    state.isDeleting = false;
    state.error = error;
  },
  [types.LOAD_PATIENT_BY_ID]: state => {
    state.currentPatient = null;
    state.isLoadingPatient = true;
    state.error = null;
    state.notFound = false;
  },
  [types.PATIENT_LOAD_SUCCESS]: (state, patientData) => {
    state.currentPatient = patientData?.patient;
    state.responses = patientData?.responses?.map(r => r.resource) ?? [];
    state.relatedPersons = patientData?.relatedPersons;
    state.isLoadingPatient = false;
  },
  [types.PATIENT_LOAD_FAILURE]: (state, error) => {
    state.isLoadingPatient = false;
    state.error = error;
  },
  [types.SET_PATIENT]: (state, patient) => {
    state.currentPatient = patient;
  },
  [types.PATIENTS_RESET]: state => {
    // state.patients = [];
    state.currentPatient = null;
    state.isLoadingPatients = false;
    state.isLoadingPatient = false;
    state.isDeleting = false;
    state.error = null;
    state.patientDocs = [];
    state.responses = [];
    state.relatedPersons = [];
  },
  [types.CREATE_DOCUMENT]: state => {
    state.activeUploads++;
  },
  [types.CREATE_DOCUMENT_FAILURE]: state => {
    state.activeUploads--;
  },
  [types.CREATE_DOCUMENT_COMPLETE]: (state, file) => {
    state.activeUploads--;
    if (!state.patientDocs) state.patientDocs = [];
    state.patientDocs.push(file.docRef);
  },
  [types.DELETE_FILE_SUCCESS]: (state, doc) => {
    state.patientDocs = state.patientDocs.filter(ele => {
      return ele != doc;
    });
    state.isLoadingDocs = false;
  },
  [types.DELETE_FILE_FAILURE]: state => {
    state.isLoadingDocs = false;
  },
  [types.LOAD_PATIENT_DOCUMENTS]: state => {
    state.isLoadingDocs = true;
    state.error = null;
  },
  [types.LOAD_PATIENT_DOCUMENTS_SUCCESS]: (state, docs) => {
    state.patientDocs = docs;
    state.isLoadingDocs = false;
  },
  [types.LOAD_PATIENT_DOCUMENTS_FAILURE]: (state, error) => {
    state.isLoadingDocs = false;
    state.error = error;
  },
  [types.POPULATE_QUESTIONNAIRE]: state => {
    state.processing = true;
  },
  [types.POPULATE_QUESTIONNAIRE_SUCCESS]: (state, response) => {
    state.processing = false;
    if (response.id) {
      state.responses = state.responses?.map(r => (r.id === response.id ? { meta: { ...r.meta }, ...response } : r));
    } else {
      state.responses?.unshift(response);
    }
  },
  [types.POPULATE_QUESTIONNAIRE_FAILURE]: (state, error) => {
    state.processing = false;
    state.error = error;
  },
  [types.CREATE_QUESTIONNAIRERESPONSE]: (state, response) => {
    state.processing = true;
  },
  [types.CREATE_QUESTIONNAIRERESPONSE_SUCCESS]: (state, response) => {
    if (response) {
      state.responses.unshift(response);
    }
    state.processing = false;
  },
  [types.UPDATE_QUESTIONNAIRERESPONSE]: (state, response) => {
    state.processing = true;
  },
  [types.UPDATE_QUESTIONNAIRERESPONSE_SUCCESS]: (state, response) => {
    state.processing = false;
    state.responses = state.responses.map(r => (r.id === response.id ? { meta: { ...r.meta }, ...response } : r));
  },
  [types.UPDATE_QUESTIONNAIRERESPONSE_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.UPDATE_RELATED_PERSON]: (state, person) => {
    let origPerson = state.relatedPersons?.find(u => u.userID == person.userID);
    if (origPerson) Object.assign(origPerson, { ...person });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
