<template>
  <span>
    <template v-if="Array.isArray(renderValue)">
      <template v-for="(license, i) in renderValue" :key="license.issuer.display + i">
        <v-chip :color="isLicenseExpired(license) ? 'error' : ''" class="mr-2" size="small">
          {{ license.issuer.display }}
        </v-chip>
      </template>
    </template>
    <template v-else>
      <v-chip :color="isLicenseExpired(license) ? 'error' : ''" class="mr-2" size="small">{{ license.issuer.display }}</v-chip>
    </template>
  </span>
</template>

<script>
export default {
  name: "LicenseCell",
  computed: {
    renderValue() {
      return this.params.value ? this.params.value.filter(e => e.issuer?.display) : [];
    },
  },
  methods: {
    isLicenseExpired(license) {
      if (license && license.period?.end) {
        const expiryDate = new Date(license.period.end);
        const currentDate = new Date();
        return expiryDate < currentDate;
      }
      return false;
    },
  },
};
</script>
