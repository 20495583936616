import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  integrationProviders: null,
  error: null,
  users: null,
  roles: null,
  isDeleting: false,
  loading: false,
};

const getters = {};

const mutations = {
  [types.CLEAR_INTEGRATION_PROVIDERS]: (state) => {
    state.integrationProviders = null;
    state.loading = false;
    state.error = null;
  },
  [types.LOAD_INTEGRATION_PROVIDERS]: state => {
    state.loading = true;
  },
  [types.LOAD_INTEGRATION_PROVIDERS_SUCCESS]: (state, providers) => {
    state.integrationProviders = providers;
    state.loading = false;
    state.error = null;
  },
  [types.LOAD_INTEGRATION_PROVIDERS_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
