import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  products: null,
  claims: null,
  payments: null,
  error: null,
  checkoutSession: null,
  status: null,
  loadingClaims: false,
  loadingPayments: false,
};

export const mutations = {
  [types.LOAD_PRODUCTS]: state => {
    state.error = null;
  },
  [types.LOAD_PRODUCTS_SUCCESS]: (state, products) => {
    state.products = products;
  },
  [types.LOAD_PRODUCTS_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.START_CHECKOUT]: state => {
    state.error = null;
    state.status = "Processing";
  },
  [types.START_CHECKOUT_SUCCESS]: (state, session) => {
    state.checkoutSession = session;
    localStorage.setItem("checkoutSession", JSON.stringify(session));
  },
  [types.RESUME_CHECKOUT]: state => {
    state.checkoutSession = JSON.parse(localStorage.getItem("checkoutSession"));
  },
  [types.START_CHECKOUT_FAILURE]: (state, error) => {
    state.error = error;
    state.status = "Error";
  },
  [types.UPDATE_CHECKOUT]: state => {
    state.error = null;
    state.status = "Processing";
  },
  [types.UPDATE_CHECKOUT_SUCCESS]: (state, session) => {
    state.checkoutSession = session;
    state.status = "Complete";
    localStorage.setItem("checkoutSession", JSON.stringify(session));
  },
  [types.UPDATE_CHECKOUT_FAILURE]: (state, error) => {
    state.error = error;
    state.status = "Error";
  },
  [types.LOAD_CLAIMS]: state => {
    state.error = null;
    state.loadingClaims = true;
  },
  [types.LOAD_CLAIMS_SUCCESS]: (state, claims) => {
    state.claims = claims;
    state.loadingClaims = false;
  },
  [types.LOAD_CLAIMS_FAILURE]: (state, error) => {
    state.error = error;
    state.loadingClaims = false;
  },
  [types.LOAD_PAYMENTS]: state => {
    state.status = null;
    state.error = null;
    state.loadingPayments = true;
  },
  [types.LOAD_PAYMENTS_SUCCESS]: (state, payments) => {
    state.payments = payments;
    state.loadingPayments = false;
  },
  [types.LOAD_PAYMENTS_FAILURE]: (state, error) => {
    state.error = error;
    state.loadingPayments = false;
  },
  [types.RESET_PAYMENTS]: state => {
    state.error = null;
    state.products = null;
    state.claims = null;
    state.payments = null;
    state.error = null;
    state.checkoutSession = null;
    state.status = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
