import * as types from "./mutation-types";
import * as api from "../../utils/api";

export const actions = {
  [types.LOAD_SYSTEM_NOTIFICATIONS]: async ({ commit }) => {
    commit(types.LOAD_SYSTEM_NOTIFICATIONS);
    try {
      var result = await api.get("/admin/system-notifications");
      commit(types.LOAD_SYSTEM_NOTIFICATIONS_SUCCESS, result.data);
    } catch (error) {
      commit(types.LOAD_SYSTEM_NOTIFICATIONS_FAILURE, error);
    }
  },
};
