export const CREATE_PRACTITIONER = "CREATE_PRACTITIONER";
export const CREATE_PRACTITIONER_SUCCESS = "CREATE_PRACTITIONER_SUCCESS";
export const CREATE_PRACTITIONER_FAILURE = "CREATE_PRACTITIONER_FAILURE";

export const LOAD_PRACTITIONER = "LOAD_PRACTITIONER";
export const LOAD_PRACTITIONER_SUCCESS = "LOAD_PRACTITIONER_SUCCESS";
export const LOAD_PRACTITIONER_FAILURE = "LOAD_PRACTITIONER_FAILURE";

export const LOAD_PRACTITIONERS = "LOAD_PRACTITIONERS";
export const LOAD_PRACTITIONERS_SUCCESS = "LOAD_PRACTITIONERS_SUCCESS";
export const LOAD_PRACTITIONERS_FAILURE = "LOAD_PRACTITIONERS_FAILURE";

export const UPDATE_PRACTITIONER = "UPDATE_PRACTITIONER";
export const UPDATE_PRACTITIONER_SUCCESS = "UPDATE_PRACTITIONER_SUCCESS";
export const UPDATE_PRACTITIONER_FAILURE = "UPDATE_PRACTITIONER_FAILURE";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";

export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";

export const DELETE_PRACTITIONERS = "DELETE_PRACTITIONERS";
export const DELETE_PRACTITIONERS_SUCCESS = "DELETE_PRACTITIONERS_SUCCESS";
export const DELETE_PRACTITIONERS_FAILURE = "DELETE_PRACTITIONERS_FAILURE";

export const RESET = "RESET";
export const SET_PRACTITIONER = "SET_PRACTITIONER";
