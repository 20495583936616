export const RESET_STATE = "RESET_STATE";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const PATIENT_CREATED = "PATIENT_CREATED";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const PATIENT_CREATED_FAILURE = "PATIENT_CREATED_FAILURE";
export const PATIENT_UPDATED = "PATIENT_UPDATED";
export const PATIENT_UPDATED_FAILURE = "PATIENT_UPDATED_FAILURE";
export const RESUME_REGISTRATION = "RESUME_REGISTRATION";
export const RESUME_REGISTRATION_SUCCESS = "RESUME_REGISTRATION_SUCCESS";

export const QUERY_SCHEDULE = "QUERY_SCHEDULE";
export const LOAD_COUNTRIES = "LOAD_COUNTRIES";
export const LOAD_COUNTRIES_SUCCESS = "LOAD_COUNTRIES_SUCCESS";