export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const LOAD_PRODUCTS_SUCCESS = "LOAD_PRODUCTS_SUCCESS";
export const LOAD_PRODUCTS_FAILURE = "LOAD_PRODUCTS_FAILURE";

export const START_CHECKOUT = "START_CHECKOUT";
export const START_CHECKOUT_SUCCESS = "START_CHECKOUT_SUCCESS";
export const START_CHECKOUT_FAILURE = "START_CHECKOUT_FAILURE";

export const RESUME_CHECKOUT = "RESUME_CHECKOUT";

export const UPDATE_CHECKOUT = "UPDATE_CHECKOUT";
export const UPDATE_CHECKOUT_SUCCESS = "UPDATE_CHECKOUT_SUCCESS";
export const UPDATE_CHECKOUT_FAILURE = "UPDATE_CHECKOUT_FAILURE";

export const LOAD_CLAIMS = "LOAD_CLAIMS";
export const LOAD_CLAIMS_SUCCESS = "LOAD_CLAIMS_SUCCESS";
export const LOAD_CLAIMS_FAILURE = "LOAD_CLAIMS_FAILURE";

export const LOAD_PAYMENTS = "LOAD_PAYMENTS";
export const LOAD_PAYMENTS_SUCCESS = "LOAD_PAYMENTS_SUCCESS";
export const LOAD_PAYMENTS_FAILURE = "LOAD_PAYMENTS_FAILURE";

export const RESET_PAYMENTS = "RESET_PAYMENTS";