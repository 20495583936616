import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  locations: {},
  loadingLocation: false,
  fhirLocations: [],
  loadingFhirLocations: false,
  error: null,
};

const getters = {};

const mutations = {
  [types.LOAD_INTEGRATION_LOCATION]: state => {
    state.loadingLocation = true;
  },
  [types.LOAD_INTEGRATION_LOCATION_SUCCESS]: (state, location) => {
    state.locations[location.id] = location;
    state.loadingLocation = false;
    state.error = null;
  },
  [types.LOAD_INTEGRATION_LOCATION_FAILURE]: (state, error) => {
    state.error = error;
    state.loadingLocation = false;
  },

  [types.CREATE_LOCATION]: state => {
    state.loadingFhirLocations = true;
    state.error = null;
  },
  [types.CREATE_LOCATION_SUCCESS]: (state, location) => {
    state.loadingFhirLocations = false;
    state.fhirLocations.push(location);
  },
  [types.CREATE_LOCATION_FAILURE]: (state, error) => {
    state.loadingFhirLocations = false;
    state.error = error;
  },

  [types.UPDATE_LOCATION]: state => {
    state.loadingFhirLocations = true;
    state.error = null;
  },
  [types.UPDATE_LOCATION_SUCCESS]: (state, location) => {
    state.loadingFhirLocations = false;
    state.fhirLocations = state.fhirLocations.map(loc => (loc.id !== location.id ? loc : location));
  },
  [types.UPDATE_LOCATION_FAILURE]: (state, error) => {
    state.loadingFhirLocations = false;
    state.error = error;
  },

  [types.LOAD_LOCATIONS]: state => {
    state.loadingFhirLocations = true;
    state.error = null;
  },
  [types.LOAD_LOCATIONS_SUCCESS]: (state, locations) => {
    state.loadingFhirLocations = false;
    state.fhirLocations = locations;
  },
  [types.LOAD_LOCATIONS_FAILURE]: (state, error) => {
    state.loadingFhirLocations = false;
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
