import * as types from "./mutation-types";
import * as appMutations from "../appMutations";
import * as api from "../../utils/api";
import * as fhirHelper from "../../utils/fhir-helpers";

export const actions = {
  [types.NEW_FORM]: ({ commit }) => {
    commit(types.NEW_FORM);
  },
  [types.FORMS_LOAD]: async ({ dispatch, rootState }) => {
    if (rootState.auth.user?.groups?.length > 0 && rootState.auth.user?.groups?.some(g => g === "Orbital-Platform-Admin")) {
      dispatch(types.ADMIN_FORMS_LOAD);
    }
  },
  [types.ADMIN_FORMS_LOAD]: async ({ commit, dispatch }) => {
    commit(types.FORMS_LOAD);
    try {
      const response = await api.get("admin/forms");
      commit(types.FORMS_LOAD_SUCCESS, response?.data);
    } catch (error) {
      commit(types.FORMS_LOAD_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Loading Forms", {
        root: true,
      });
    }
  },
  [types.FORM_LOAD_BY_ID]: async ({ commit, dispatch }, formID) => {
    commit(types.FORM_LOAD_BY_ID);
    try {
      const form = await api.get(`admin/form/${formID}`);
      commit(types.FORM_LOAD_BY_ID_SUCCESS, form.data);
    } catch (error) {
      commit(types.FORMS_LOAD_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Loading Form", {
        root: true,
      });
    }
  },
  [types.CREATE_FORM]: async ({ commit, dispatch }, form) => {
    commit(types.CREATE_FORM);
    try {
      const questionnaire = fhirHelper.formToQuestionnaire(form);
      const response = await api.post("Questionnaire", questionnaire);
      form.lastUpdated = Date.now();
      form.questionnaireID = response.id;

      const formResponse = await api.post("admin/forms", form);
      commit(types.CREATE_FORM_SUCCESS, formResponse);

      dispatch(appMutations.SNACKBAR_SUCCESS, "Form Created", {
        root: true,
      });
    } catch (error) {
      commit(types.CREATE_FORM_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Creating Form", {
        root: true,
      });
    }
  },

  [types.UPDATE_FORM]: async ({ commit, dispatch }, { formID, form }) => {
    commit(types.UPDATE_FORM);
    try {
      const questionnaire = fhirHelper.formToQuestionnaire(form);
      if (!form.questionnaireID) {
        const response = await api.post("Questionnaire", questionnaire);
        form.questionnaireID = response.id;
      } else {
        await api.put(`Questionnaire/${form.questionnaireID}`, questionnaire);
      }
      form.lastUpdated = Date.now();

      await api.put(`admin/forms/${formID}`, form);
      commit(types.UPDATE_FORM_SUCCESS, form);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Form Updated", {
        root: true,
      });
    } catch (error) {
      commit(types.UPDATE_FORM_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Updating Form", {
        root: true,
      });
    }
  },
  [types.DELETE_FORM]: async ({ commit, dispatch }, id) => {
    commit(types.DELETE_FORM);
    try {
      await api.deleteResource(`admin/forms/${id}`);
      commit(types.DELETE_FORM_SUCCESS, id);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Form Deleted", {
        root: true,
      });
    } catch (error) {
      commit(types.DELETE_FORM_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Deleting Form", {
        root: true,
      });
    }
  },
};
