import * as types from "./mutation-types";
import * as api from "../../utils/api";

export const actions = {
  [types.LOAD_PRODUCTS]: async ({ commit }) => {
    commit(types.LOAD_PRODUCTS);
    try {
      var resp = await api.get("paymentproducts");
      commit(types.LOAD_PRODUCTS_SUCCESS, resp?.data);
    } catch (error) {
      commit(types.LOAD_PRODUCTS_FAILURE, error);
      throw error;
    }
  },
  [types.START_CHECKOUT]: async ({ commit }, session) => {
    commit(types.START_CHECKOUT, session);
    try {
      var resp = await api.post("checkout", session);
      commit(types.START_CHECKOUT_SUCCESS, resp);
    } catch (error) {
      commit(types.START_CHECKOUT_FAILURE, error);
      throw error;
    }
  },
  [types.UPDATE_CHECKOUT]: async ({ commit }, session) => {
    commit(types.UPDATE_CHECKOUT, session);
    try {
      var resp = await api.put("checkout", session, {
        headers: { "Content-Type": "application/json" },
      });
      commit(types.UPDATE_CHECKOUT_SUCCESS, resp);
    } catch (error) {
      commit(types.UPDATE_CHECKOUT_FAILURE, error);
      throw error;
    }
  },
  [types.RESUME_CHECKOUT]: async ({ commit }) => {
    commit(types.RESUME_CHECKOUT);
  },
  [types.LOAD_CLAIMS]: async ({ commit }, patientID) => {
    commit(types.LOAD_CLAIMS);
    try {
      var url = patientID ? "claim/?patient=" + patientID : "claim";
      var response = await api.search(url, {});
      let claims = response?.data ? response?.data?.entry : [];
      commit(types.LOAD_CLAIMS_SUCCESS, claims);
    } catch (error) {
      commit(types.LOAD_CLAIMS_FAILURE, error);
      throw error;
    }
  },
  [types.LOAD_PAYMENTS]: async ({ commit }, patientID) => {
    if (!patientID) return;
    commit(types.LOAD_PAYMENTS);
    try {
      var url = patientID ? "PaymentReconciliation/?patient=" + patientID : "PaymentReconciliation";
      var response = await api.search(url, {});
      commit(types.LOAD_PAYMENTS_SUCCESS, response?.data?.entry);
    } catch (error) {
      commit(types.LOAD_PAYMENTS_FAILURE, error);
      throw error;
    }
  },
  [types.RESET_PAYMENTS]: ({ commit }) => {
    commit(types.RESET_PAYMENTS);
  },
};
