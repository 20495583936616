<template>
  <span v-if="value">
    <v-text-field v-bind="$attrs" id="address" v-model="addressText" type="Address" clearable :rules="[rules.required]" variant="outlined" autocomplete="false" validate-on="blur"></v-text-field>
  </span>
</template>
<script>
import * as api from "../../utils/api";
import placesInit from "../../utils/google-places";
export default {
  name: "SharedAddress",
  model: {
    prop: "value",
    event: "update",
  },
  props: ["value"],
  data: () => ({
    addressText: null,
    address: { address: null, street: null, city: null, state: null, postalCode: null },
    rules: {
      required: value => !!value || "Required",
    },
  }),
  watch: {
    value(val) {
      this.addressText = val.address;
    },
    addressText: {
      handler: function (val, old) {
        if (this.value.address != this.addressText) {
          this.address.address = this.addressText;
          this.$emit("update", this.address);
        }
      },
      deep: true,
    },
  },
  created() {
    if (!this.addressText && this.value?.address) this.addressText = this.value.address;
  },
  async mounted() {
    try {
      var response = await api.GetInit();
      let config = response?.data;
      let API_KEY = config.fbConfig.apiKey || "AIzaSyDZ4RXHDSE7TwV5074VgoJ5ZdQ_JBvoS0c";
      if (!API_KEY) return;
      const google = await placesInit(API_KEY);
      if (!google) return;
      var address = document.getElementById("address");
      if (!address) return;
      var autocomplete = new google.maps.places.Autocomplete(/** @type {!HTMLInputElement} */ (address), { types: ["geocode"] });
      autocomplete.setFields(["address_components", "formatted_address", "name"]);

      const parent = this;
      google.maps.event.addListener(autocomplete, "place_changed", function () {
        var place = autocomplete.getPlace();
        var street = parseAddress(place, "street_number");
        var route = parseAddress(place, "route");

        parent.address.address = place.formatted_address;
        parent.address.street = [street, route].join(" ").trim();
        parent.place = place;
        parent.address.country = parseAddress(place, "country") ?? "US";
        parent.address.city = parseAddress(place, "locality");
        parent.address.state = parseAddress(place, "administrative_area_level_1");
        parent.address.postalCode = parseAddress(place, "postal_code");

        parent.addressText = parent.address.address;
        //parent.$emit("update", parent.address);
      });
    } catch {
      console.log("Google Places API failed to load");
    }
  },
};
function parseAddress(place, property) {
  var val = place.address_components ? place.address_components.find(c => c.types.includes(property)) : null;
  return val ? val.short_name : null;
}
</script>
