<template>
  <router-link :to="`/providers/${params.data.id}`" class="px-2 text-decoration-none">
    <Avatar left size="24px" :user="params.data">
    </Avatar>
    <span class="mx-2" v-text="renderName(params.data.name)"></span>
    <span class="ml-2" style="white-space: nowrap">
      <span v-for="(tag, index) in params.data.fhirPractitioner?.meta.tag" :key="`${params.data.name}-${index}`">
        <v-chip
          v-if="index < 3" size="x-small" class="mr-2 mb-1"
          :color="!color(tag.code) ? '' : color(tag.code).name + ' text-' + color(tag.code).text"
        >
          <span style="max-width: 80px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis !important">{{
            tag.code }}</span>
        </v-chip>
        <v-chip v-if="index == 3" size="small" class="mr-2 mb-1" color="grey"><span>More</span>
        </v-chip>
      </span>
    </span>
  </router-link>
</template>
<script>
import { renderName } from "../../utils/fhir-helpers.js";

export default {
  name: "ProviderNameCell",
  methods: {
    renderName,
  },
};
</script>