<template>
  <div>
    <vue-tel-input
      ref="phoneNumber" v-model="phone" class="mb-2" input-id="phoneNumber" default-country="US"
      mode="international" label="Phone Number" :class="!isValid ? 'invalid' : ''" v-bind="{ ...phoneProps, ...$attrs }"
      tabindex="1" @update:model-value="phoneChanged"
    ></vue-tel-input>
    <v-input v-show="false" v-model="isValid" :rules="rules"></v-input>
  </div>
</template>
<script>
import { VueTelInput } from "vue-tel-input";

export default {
  components: {
    VueTelInput,
  },
  inheritAttrs: true,
  model: {
    prop: "value",
    event: "update",
  },
  props: ["value", "optional"],
  data: () => ({
    phone: null,
    phoneProps: {
      mode: "international",
      // validCharactersOnly: true,
      placeholder: "Phone number",
      dropdownOptions: {
        disabledDialCode: false,
      },
      inputOptions: {
        name: "phoneNumber",
        showDialCode: false,
      },
    },
    rules: [(val) => val],
    isValid: true,
  }),
  watch: {
    value(val) {
      this.phone = this.value;
    },
    phone(val) {
      if (this.phone !== this.value) {
        this.$emit("update", this.phone);
      }
    },
  },
  created() {
    if (this.value && !this.phone) {
      this.phone = this.value;
    }
  },
  methods: {
    phoneChanged(num, obj) {
      this.isValid = (this.optional && (!this.phone || this.phone === "")) || (obj?.isValid ?? false);
    },
  },
};
</script>
<style>
.vue-tel-input.disabled {
  border: none;
}

.vue-tel-input {
  border-radius: 3px;
  display: flex;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.12) !important;
  text-align: left;
  height: 56px;
}

.invalid {
  border: 2px solid red !important;
}
</style>
