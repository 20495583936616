export const RESET = "RESET";
export const LOAD_COUNTS = "LOAD_COUNTS";
export const LOAD_COUNTS_SUCCESS = "LOAD_COUNTS_SUCCESS";

export const LOAD_CONVERSATIONS = "LOAD_CONVERSATIONS";
export const LOAD_CONVERSATIONS_SUCCESS = "LOAD_CONVERSATIONS_SUCCESS";
export const LOAD_CONVERSATIONS_FAILURE = "LOAD_CONVERSATIONS_FAILURE";

export const CREATE_CONVERSATION = "CREATE_CONVERSATION";
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS";
export const CREATE_CONVERSATION_FAILURE = "CREATE_CONVERSATION_FAILURE";

export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION";
export const UPDATE_CONVERSATION_SUCCESS = "UPDATE_CONVERSATION_SUCCESS";
export const UPDATE_CONVERSATION_FAILURE = "UPDATE_CONVERSATION_FAILURE";

export const DELETE_CONVERSATION = "DELETE_CONVERSATION";
export const DELETE_CONVERSATION_SUCCESS = "DELETE_CONVERSATION_SUCCESS";
export const DELETE_CONVERSATION_FAILURE = "DELETE_CONVERSATION_FAILURE";

export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_FAILURE = "CREATE_MESSAGE_FAILURE";

export const DELETE_MESSAGE = "DELETE_MESSAGE";

export const LOAD_PATIENT_CONVERSATIONS = "LOAD_PATIENT_CONVERSATIONS";
export const LOAD_PATIENT_CONVERSATIONS_SUCCESS = "LOAD_PATIENT_CONVERSATIONS_SUCCESS";
export const LOAD_PATIENT_CONVERSATIONS_FAILURE = "LOAD_PATIENT_CONVERSATIONS_FAILURE";

export const LOAD_MESSAGE_TEMPLATES = "LOAD_MESSAGE_TEMPLATES";
export const LOAD_MESSAGE_TEMPLATES_SUCCESS = "LOAD_MESSAGE_TEMPLATES_SUCCESS";
export const LOAD_MESSAGE_TEMPLATES_FAILURE = "LOAD_MESSAGE_TEMPLATES_FAILURE";

export const LOAD_MESSAGE_TEMPLATE = "LOAD_MESSAGE_TEMPLATE";
export const LOAD_MESSAGE_TEMPLATE_SUCCESS = "LOAD_MESSAGE_TEMPLATE_SUCCESS";
export const LOAD_MESSAGE_TEMPLATE_FAILURE = "LOAD_MESSAGE_TEMPLATE_FAILURE";

export const UPDATE_MESSAGE_TEMPLATE = "UPDATE_MESSAGE_TEMPLATE";
export const UPDATE_MESSAGE_TEMPLATE_SUCCESS = "UPDATE_MESSAGE_TEMPLATE_SUCCESS";
export const UPDATE_MESSAGE_TEMPLATE_FAILURE = "UPDATE_MESSAGE_TEMPLATE_FAILURE";

export const DELETE_MESSAGE_TEMPLATE = "DELETE_MESSAGE_TEMPLATE";
export const DELETE_MESSAGE_TEMPLATE_SUCCESS = "DELETE_MESSAGE_TEMPLATE_SUCCESS";
export const DELETE_MESSAGE_TEMPLATE_FAILURE = "DELETE_MESSAGE_TEMPLATE_FAILURE";

export const CREATE_MESSAGE_TEMPLATE = "CREATE_MESSAGE_TEMPLATE";
export const CREATE_MESSAGE_TEMPLATE_SUCCESS = "CREATE_MESSAGE_TEMPLATE_SUCCESS";
export const CREATE_MESSAGE_TEMPLATE_FAILURE = "CREATE_MESSAGE_TEMPLATE_FAILURE";

export const RESET_TEMPLATE = "RESET_TEMPLATE";
