export const LOAD_STATUSES = "LOAD_STATUSES";
export const LOAD_STATUSES_SUCCESS = "LOAD_STATUSES_SUCCESS";
export const LOAD_STATUSES_FAILURE = "LOAD_STATUSES_FAILURE";

export const STATUS_UPDATE = "STATUS_UPDATE";
export const STATUS_UPDATE_SUCCESS = "STATUS_UPDATE_SUCCESS";
export const STATUS_UPDATE_FAILURE = "STATUS_UPDATE_FAILURE";

export const CREATE_STATUS = "CREATE_STATUS";
export const CREATE_STATUS_SUCCESS = "CREATE_STATUS_SUCCESS";
export const CREATE_STATUS_FAILURE = "CREATE_STATUS_FAILURE";

export const DELETE_STATUS = "DELETE_STATUS";
export const DELETE_STATUS_SUCCESS = "DELETE_STATUS_SUCCESS";
export const DELETE_STATUS_FAILURE = "DELETE_STATUS_FAILURE";

export const LOAD_CODE_SYSTEM = "LOAD_CODE_SYSTEM";
export const LOAD_CODE_SYSTEM_FAILURE = "LOAD_CODE_SYSTEM_FAILURE";
export const LOAD_CODE_SYSTEM_SUCCESS = "LOAD_CODE_SYSTEM_SUCCESS";

export const UPDATE_CODE_SYSTEM = "UPDATE_CODE_SYSTEM";
export const UPDATE_CODE_SYSTEM_SUCCESS = "UPDATE_CODE_SYSTEM_SUCCESS";
export const UPDATE_CODE_SYSTEM_FAILURE = "UPDATE_CODE_SYSTEM_FAILURE";
export const LOAD_STATUSES_AS_ADMIN = "LOAD_STATUSES_AS_ADMIN";
