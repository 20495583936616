import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
    events: [],
};

export const mutations = {
    [types.LOAD_EVENTS]: (state) => {
        state.error = null;
    },
    [types.LOAD_EVENTS_SUCCESS]: (state, events) => {
        state.events = events;
    },
    [types.LOAD_EVENTS_FAILURE]: (state, error) => {
        state.error = error;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};