<template>
  <v-img v-bind="$attrs" :src="computedSrc" @error="onError">
    <v-layout v-if="!disablePlaceholder && !loaded" fill-height align-center justify-center ma-0>
      <v-progress-circular v-if="!error" indeterminate color="grey-lighten-5"></v-progress-circular>
      <v-skeleton-loader v-else class="mx-auto" type="image"></v-skeleton-loader>
    </v-layout>
  </v-img>
</template>
<script>
export default {
  props: {
    disablePlaceholder: Boolean,
  },
  data: () => ({ error: false, originalSrc: null, loaded: false }),
  computed: {
    computedSrc() {
      if (!this.$attrs.src || !this.loaded) return "";
      return this.originalSrc;
    },
  },
  watch: {
    "$attrs.src": function (val) {
      this.originalSrc = this.$attrs.src;
    },
  },
  created() {
    this.originalSrc = this.$attrs.src;
    this.loaded = true;
  },
  methods: {
    onError() {
      this.error = true;
      this.originalSrc = `/api/proxy?url=${btoa(this.$attrs["src"])}`;
    },
  },
};
</script>
