<template>
  <div v-if="value">
    <span v-for="(name, i) in value" :key="i">
      <v-row>
        <v-col>
          <v-text-field
            v-if="name.given" id="first-name" v-bind="$attrs" v-model="name.given[0]" hide-details
            variant="outlined" label="First Name" @update:model-value="$emit('update', value)"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            id="last-name" v-bind="$attrs" v-model="name.family" hide-details variant="outlined"
            label="Last Name" @update:model-value="$emit('update', value)"
          ></v-text-field>
        </v-col>
      </v-row>
    </span>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  inheritAttrs: false,
  props: ["value"],
  emits: ["update"],
});
</script>
