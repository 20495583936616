import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  organization: null,
  loading: false,
  error: null,
};
const getters = {
  organization: state => state.organization,
};
export const mutations = {
  [types.LOAD_ORGANIZATION]: state => {
    state.error = null;
    state.loading = true;
  },
  [types.LOAD_ORGANIZATION_SUCCESS]: (state, org) => {
    state.organization = org;
    state.error = null;
    state.loading = false;
  },
  [types.LOAD_ORGANIZATION_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.UPDATE_ORGANIZATION]: state => {
    state.error = null;
    state.loading = true;
  },
  [types.UPDATE_ORGANIZATION_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.UPDATE_ORGANIZATION_SUCCESS]: (state, org) => {
    state.organization = org;
    state.loading = false;
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
