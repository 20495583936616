import { aliases, mdi } from 'vuetify/lib/iconsets/mdi'

const alertTypeIcon = {
  success: 'mdi-check-circle-outline',
  info: 'mdi-information-outline',
  warning: 'mdi-alert-outline',
  error: 'mdi-alert-circle-outline',
}

const modifiedAliases = Object.assign(aliases, alertTypeIcon)

export const icons = {
  defaultSet: 'mdi',
  iconfont: 'mdi',
  sets: {
    mdi,
  },
}
