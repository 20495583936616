import * as api from "../../utils/api";
import * as appMutations from "../appMutations";
import * as types from "./mutation-types";

export const actions = {
  [types.LOAD_AUTOMATIONS]: async ({ commit }) => {
    commit(types.LOAD_AUTOMATIONS);
    try {
      const { data } = await api.get("admin/automations");
      commit(types.LOAD_AUTOMATIONS_SUCCESS, data);
    } catch (error) {
      commit(types.LOAD_AUTOMATIONS_FAILURE, error);
      throw error;
    }
  },
  [types.LOAD_AUTOMATION_BY_ID]: async ({ commit, dispatch }, automationID) => {
    commit(types.LOAD_AUTOMATION_BY_ID, automationID);
    try {
      const { data } = await api.get(`admin/automations/${automationID}`);
      commit(types.LOAD_AUTOMATION_BY_ID_SUCCESS, data);
    } catch (error) {
      commit(types.LOAD_AUTOMATION_BY_ID_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Loading Automation", {
        root: true,
      });
      throw error;
    }
  },
  [types.CREATE_AUTOMATION]: async ({ commit, dispatch }, automation) => {
    commit(types.CREATE_AUTOMATION);
    try {
      const data = await api.post("admin/automations/", automation);
      commit(types.CREATE_AUTOMATION_SUCCESS, data);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Automation Created", {
        root: true,
      });
    } catch (error) {
      commit(types.CREATE_AUTOMATION_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Creating Automation", {
        root: true,
      });
      throw error;
    }
  },
  [types.UPDATE_AUTOMATION]: async ({ commit, dispatch }, automation) => {
    commit(types.UPDATE_AUTOMATION, automation);

    try {
      const { data } = await api.put(`admin/automations/${automation.id}`, automation);
      commit(types.UPDATE_AUTOMATION_SUCCESS, data);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Automation Updated", {
        root: true,
      });
    } catch (error) {
      commit(types.UPDATE_AUTOMATION_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Updating Automation", {
        root: true,
      });
      throw error;
    }
  },
  [types.DELETE_AUTOMATION]: async ({ commit, dispatch }, id) => {
    commit(types.DELETE_AUTOMATION);
    try {
      await api.deleteResource(`admin/automations/${id}`);
      commit(types.DELETE_AUTOMATION_SUCCESS, id);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Automation Deleted", {
        root: true,
      });
    } catch (error) {
      commit(types.DELETE_AUTOMATION_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Deleting Automation", {
        root: true,
      });
      throw error;
    }
  },
  [types.RESET_AUTOMATION]: ({ commit }) => {
    commit(types.RESET_AUTOMATION);
  },
};
