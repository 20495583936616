import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  validatingPhone: false,
  phoneValid: true,
  error: null,
  currentUser: null,
  users: [],
  roles: ["Orbital-Platform-Admin"],
  processing: false,
  createUserComplete: false,
  userNameAvailable: true,
  userFilters: {
    users: [],
    instances: [],
    statuses: [],
    dates: [],
    search: null,
    userRoles: null,
    importStatus: [],
    taskTypes: [],
    taskOwner: null,
    taskInstances: [],
  },
};

const getters = {
  getAdminUsers: state => state.users?.filter(u => u.groups?.includes("Orbital-Platform-Admin")).sort((a, b) => a.name?.localeCompare(b.name)) ?? [],
  getNonAdminUsers: state => state.users?.filter(u => !u.groups || u.groups?.length === 0) ?? [],
  getAdminsByInstance: state => instanceID => state.users?.filter(u => (u.groups?.includes("Orbital-Platform-Admin")) || (u.groups?.includes("Orbital-Instance-Admin") && u.instanceID === instanceID))
    .sort((a, b) => a.name?.localeCompare(b.name)) ?? [],
  getAdminRoles: state => state.roles?.filter(r => r.name.includes("Admin")),
  //GetPractitioners: state => state.users.filter(u => u.practitionerID),
};

const mutations = {
  [types.UPDATE_USER]: (state, user) => {
    state.error = null;
    state.processing = true;
  },
  [types.UPDATE_USER_SUCCESS]: (state, user) => {
    let oldUser = state.users?.find(u => u.userID === user.userID);
    if (oldUser) Object.assign(oldUser, { ...user });
    if (state.currentUser?.userID === user.userID) Object.assign(state.currentUser, { ...user });
    state.error = null;
    state.processing = false;
  },
  [types.UPDATE_USER_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.LOAD_ADMIN_USERS]: state => {
    state.error = null;
    state.processing = true;
  },
  [types.LOAD_ADMIN_USERS_SUCCESS]: (state, users) => {
    state.users = users;
    state.processing = false;
  },
  [types.LOAD_ADMIN_USERS_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.LOAD_ROLES_SUCCESS]: (state, roles) => {
    state.roles = roles;
  },
  [types.CREATE_USER]: state => {
    state.processing = true;
    state.createUserComplete = false;
  },
  [types.CREATE_USER_SUCCESS]: (state, user) => {
    if (user && state.users) state.users.unshift(user);
    state.processing = false;
    state.createUserComplete = true;
  },
  [types.CREATE_USER_FAILURE]: state => {
    state.processing = false;
  },

  [types.DELETE_USER]: state => {
    state.error = null;
    state.processing = true;
  },
  [types.DELETE_USER_SUCCESS]: (state, userID) => {
    state.users = state.users?.filter(u => u.userID !== userID);
    if (state.currentUser?.userID === userID) state.currentUser = null;
    state.error = null;
    state.processing = false;
  },
  [types.DELETE_USER_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.LOAD_USER]: state => {
    state.error = null;
    state.processing = true;
  },
  [types.LOAD_USER_SUCCESS]: (state, user) => {
    state.currentUser = user;
    state.processing = false;
  },
  [types.LOAD_USER_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
    state.currentUser = null;
  },
  [types.CHECK_USERNAME_AVAILABILITY]: (state, userID) => {
    state.userNameAvailable = true;
  },
  [types.CHECK_USERNAME_AVAILABILITY_COMPLETE]: (state, userNameAvailable) => {
    state.userNameAvailable = userNameAvailable;
  },
  [types.LOAD_USER_FILTERS]: state => {
    state.error = null;
  },
  [types.LOAD_USER_FILTERS_SUCCESS]: (state, filters) => {
    state.error = null;
    if (filters) state.userFilters = { ...state.userFilters, ...filters };
  },
  [types.UPDATE_USER_FILTERS_SUCCESS]: (state, filters) => {
    state.error = null;
    state.userFilters = filters;
  },
  [types.RESET_USER]: state => {
    state.error = null;
    state.currentUser = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
