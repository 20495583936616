<template>
  <div>
    <v-data-table-server
      v-bind="$attrs"
      v-model="selected"
      v-model:options="options"
      width="100%"
      :headers="headers"
      :items="localResources"
      :loading="loading"
      loading-text="Loading..."
      :items-per-page="25"
      :show-select="!embedded"
      :footer-props="{
        itemsPerPageOptions: embedded ? null : [25, 50, 75, -1],
      }"
      :hide-default-footer="embedded"
      :class="{ 'text-disabled': readonly, 'px-3': embedded }"
      :items-length="total"
      @update:options="search"
      @click:row="rowClick">
      <template v-for="header in headers.filter(header => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }">
        {{ header.formatter(value) }}
      </template>
      <template v-for="(_, slot) of $slots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <template #item.identifiers="{ item }">
        <div v-if="item.identifier && item.identifier.length > 0">
          <div v-for="identifier in item.identifier" :key="`${identifier.system}-${identifier.value}`">
            <!-- <span v-if="identifier.system">{{ identifier.system }}: </span> -->
            <span class="text-no-wrap">{{ identifier.value }}</span>
          </div>
        </div>
      </template>
      <template #item.subject="{ item }">
        <a v-if="item.subject && item.subject.reference.includes('Patient')" :href="`/${item.subject.reference.replace('Patient', 'patients')}`" target="_blank" class="text-no-wrap">
          {{ item.subject.display ? item.subject.display : item.subject.reference }}
        </a>
        <span v-else-if="item.subject">{{ item.subject.display ? item.subject.display : item.subject.reference }}</span>
      </template>
      <template #item.value="{ item }">
        <span class="text-no-wrap">
          <span v-if="item.valueQuantity">{{ item.valueQuantity.value }} {{ item.valueQuantity.unit }}</span>
          <span v-else-if="item.valueCodeableConcept">{{ item.valueCodeableConcept.text }}</span>
          <span v-else-if="item.valueString">{{ item.valueString }}</span>
          <span v-else-if="item.valueReference">{{ item.valueReference.display }}</span>
          <span v-else-if="item.valueInteger != null">{{ item.valueInteger }}</span>
          <span v-else-if="item.valueBoolean">{{ item.valueBoolean }}</span>
          <span v-else-if="item.valueDateTime">{{ formatDateTime(item.valueDateTime) }}</span>
          <span v-else-if="item.valuePeriod">{{ item.valuePeriod.start }} - {{ item.valuePeriod.end }}</span>
          <span v-else-if="item.valueRange">{{ item.valueRange.low }} - {{ item.valueRange.high }}</span>
          <span v-else-if="item.valueRatio">{{ item.valueRatio.numerator.value }} {{ item.valueRatio.numerator.unit }} / {{ item.valueRatio.denominator.value }} {{ item.valueRatio.denominator.unit }}</span>
          <span v-else>{{ item.value }}</span>
        </span>
      </template>
      <template #item.operation-type="{ item }">
        <span v-if="item.id">Existing</span>
        <span v-else>New</span>
      </template>
      <template #item.tags="{ item }">
        <span v-if="item.tags">
          <div v-for="tag in item.tags" :key="tag.text">
            <v-chip :color="tag.color" size="x-small" variant="outlined">
              {{ tag.text }}
            </v-chip>
          </div>
        </span>
      </template>
      <template #item.patient-name="{ item }">
        <a v-if="item.id" :href="`/patients/${item.id}`" target="_blank" class="text-no-wrap">
          {{ renderName(item.name) }}
        </a>
        <span v-else>
          {{ renderName(item.name) }}
        </span>
      </template>
      <template #item.participant="{ item }">
        <div v-if="item.participant && item.participant.length > 0" class="text-no-wrap">
          <div v-for="participant in item.participant" :key="participant.member.display">
            {{ participant.member.display }}
          </div>
        </div>
      </template>
      <template #item.content="{ item }">
        {{ item.content }}
        <div v-if="item.content && item.content.length > 0" class="text-no-wrap">
          <div v-for="content in item.content" :key="content.attachment.url">
            <a :href="content.attachment.url" target="_blank">{{ content.attachment.title }}</a>
          </div>
        </div>
      </template>
    </v-data-table-server>
    <v-dialog v-model="currentResourceDialog" max-width="75%">
      <v-card v-if="currentResourceDialog">
        <v-card-text class="pa-0">
          <span v-if="loading">
            <v-skeleton-loader v-for="i in 6" :key="`skel-${i}`" type="list-item-avatar" class="mb-8"></v-skeleton-loader>
          </span>
          <span v-else class="pa-0">
            <v-tabs v-model="tab" hide-slider>
              <!-- <v-tab>Resource</v-tab> -->
              <v-tab v-if="compareExisting && currentResource.id">Compare</v-tab>
              <v-tab>Proposed</v-tab>
              <v-tab v-if="compareExisting && currentResource.id">Existing</v-tab>
            </v-tabs>
            <v-window v-model="tab">
              <v-window-item v-if="compareExisting && currentResource.id" style="max-height: 800px; overflow-y: auto">
                <v-card theme="dark" class="rounded-0">
                  <v-card-title class="py-0">
                    <v-row>
                      <v-col cols="6">
                        <h4>Proposed</h4>
                      </v-col>
                      <v-col cols="6">
                        <h4>Existing</h4>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-row v-for="(key, value) in Object.keys(renderResource)" :key="key + value" no-gutters>
                      <v-col cols="6">
                        <v-card :disabled="doesKeyMatch(key)" :variant="doesKeyMatch(key) ? 'plain' : 'flat'" class="px-4 ma-0 rounded-0" :class="!doesKeyMatch(key) ? 'success font-weight-bold' : ''">
                          <pre class="language-json">{{ key }} : {{ renderResource[key] }}</pre>
                        </v-card>
                      </v-col>
                      <v-col cols="6" class="px-2">
                        <v-card disabled variant="plain" class="pa-0 ma-0 rounded-0">
                          <pre class="language-json">{{ key }} :{{ existingResource[key] }}</pre>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-window-item>
              <v-window-item>
                <v-card v-if="currentResource.issues && currentResource.issues.length > 0" variant="flat" class="pa-4 ma-0 mb-0 rounded-0">
                  <v-alert type="error" border="start" elevation="2">
                    <div v-for="issue in currentResource.issues" :key="issue.summary">
                      <div>{{ issue.summary }}</div>
                      <div>{{ issue.details }}</div>
                    </div>
                  </v-alert>
                </v-card>
                <v-card variant="flat" theme="dark" class="pa-4 ma-0 rounded-0 overflow-auto">
                  <pre class="language-json">{{ renderResource }}</pre>
                  <v-btn color="primary" variant="text" style="position: absolute; right: 6px; top: 6px" @click="copy(renderResource)">Copy to Clipboard</v-btn>
                </v-card>
              </v-window-item>
              <v-window-item v-if="compareExisting">
                <v-card variant="flat" theme="dark" class="pa-4 ma-0 rounded-0 overflow-auto">
                  <pre class="language-json">{{ existingResource }}</pre>
                  <v-btn color="primary" variant="text" style="position: absolute; right: 6px; top: 6px" @click="copy(existingResource)">Copy to Clipboard</v-btn>
                </v-card>
              </v-window-item>
            </v-window>
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as api from "../../utils/api";
import { colDefs } from "../../utils/colDefs";
import { debounce, formatDateTime } from "../../utils/helpers";
import { renderName } from "../../utils/fhir-helpers.js";

export default {
  name: "DynamicResourceTable",
  props: {
    resourceType: {
      type: String,
      required: true,
    },
    query: {
      type: String,
      required: false,
      default: "",
    },
    resources: {
      type: Array,
      required: false,
      default: () => [],
    },
    additionalColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    compareExisting: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    total: 0,
    localResources: [],
    selected: [],
    options: {},
    loading: false,
    pages: {},
    currentResourceDialog: false,
    currentResource: {},
    existingResource: {},
    tab: 0,
  }),
  computed: {
    headers() {
      let cols = colDefs;
      if (this.additionalColumns.length) {
        cols = cols.concat(this.additionalColumns);
      }
      let r = this.embedded ? cols.filter(c => c.refType === this.resourceType && !c.hideEmbedded) : cols.filter(c => c.refType === this.resourceType);
      r = r
        .map(c => {
          if (Object.prototype.hasOwnProperty.call(c, "formatter")) {
            return {
              ...c,
              title: c.headerName ?? c.title ?? c.text,
              value: c.field ?? c.value ?? "-",
              sortable: c.sortable,
              formatter: c.formatter,
            };
          } else {
            return {
              ...c,
              title: c.headerName ?? c.title ?? c.text,
              value: c.field ?? c.value ?? "-",
              sortable: c.sortable,
            };
          }
        })
        .map(c => {
          c.readonly = this.readonly;
          return c;
        });
      return r;
    },
    renderResource() {
      let tempRES = JSON.parse(JSON.stringify(this.currentResource));
      delete tempRES.tags;
      delete tempRES.issues;
      delete tempRES.meta;

      return tempRES;
    },
    compareResource() {
      let tempRES = JSON.parse(JSON.stringify(this.currentResource));
      delete tempRES.tags;
      delete tempRES.issues;
      // delete tempRES.extension;
      let tempExisting = JSON.parse(JSON.stringify(this.existingResource));
      delete tempExisting.tags;
      delete tempExisting.issues;
      // delete tempExisting.extension;

      let diff = {};
      for (let key in tempRES) {
        if (tempRES[key] !== tempExisting[key]) {
          //check type
          if (typeof tempRES[key] === "object") {
            if (JSON.stringify(tempRES[key]) !== JSON.stringify(tempExisting[key])) {
              diff[key] = tempRES[key];
            }
          } else {
            diff[key] = tempRES[key];
          }
        }
      }
      return diff;
    },
  },
  watch: {
    query() {
      this.search();
    },
    resourceType() {
      this.search();
    },
    resources() {
      this.search();
    },
    options: {
      handler: function () {
        this.search();
      },
      deep: true,
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    formatDateTime,
    renderName,
    rowClick(_e, r) {
      this.showResource(r.item);
    },
    search: debounce(async function (options) {
      const { sortBy, page, itemsPerPage } = options ?? this.options;
      const sortKey = sortBy[0]?.key;
      const sortOrder = sortBy[0]?.order;
      this.loading = true;
      if (this.resources.length) {
        this.localResources = this.resources?.filter(r => r.resourceType === this.resourceType);
        this.total = this.localResources.length;

        //handle sort
        if (sortKey) {
          this.localResources = this.localResources.sort((a, b) => {
            if (a[sortKey] < b[sortKey]) {
              return sortOrder === "asc" ? -1 : 1;
            }
            if (a[sortKey] > b[sortKey]) {
              return sortOrder === "asc" ? 1 : -1;
            }
            return 0;
          });
        }

        this.loading = false;
        return;
      }
      let pageToken = !page ? "" : (this.pages?.[page - 1]?.token ?? "");
      let url = this.query.startsWith(this.resourceType) ? this.query : `${this.resourceType}?`;
      if (this.query && !this.query.startsWith(this.resourceType)) {
        url += `${this.query}&`;
      }
      url += `_count=${itemsPerPage ?? 50}`;
      if (pageToken) url += `&_page_token=${pageToken}`;
      let response = await api.search(url, {}, true);
      if (response?.data) {
        this.localResources = response?.data?.entry?.map(e => e.resource) ?? [];
        this.total = response?.data?.total;
        this.loading = false;

        if (response?.data?.link) {
          this.pages[page] = {
            token: response?.data?.link?.find(l => l.relation === "next")?.url?.split("_page_token=")[1] ?? "",
          };
        }
      }
    }, 500),
    showResource(resource) {
      let tempRES = JSON.parse(JSON.stringify(resource));
      //delete tempRES.tags;
      //delete tempRES.issues;
      this.currentResource = tempRES;
      if (this.compareExisting) {
        this.getExistingResource();
      }
      this.currentResourceDialog = true;
    },
    getExistingResource: debounce(async function () {
      if (!this.currentResource.id) return;
      let url = `${this.resourceType}/${this.currentResource.id}`;
      let response = await api.search(url, {}, false);
      if (response?.data) {
        this.existingResource = response?.data;
        delete this.existingResource.meta;
      }
    }, 500),
    copy(content) {
      let txt = JSON.stringify(content, null, 2);

      navigator.clipboard.writeText(txt);
      this.$store.dispatch("SNACKBAR_SUCCESS", "Copied to clipboard");
    },
    doesKeyMatch(key) {
      if (!this.compareResource || !this.existingResource) {
        return false;
      }
      let difs = this.compareResource[key];
      return difs === undefined;
    },
  },
};
</script>
