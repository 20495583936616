export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT";
export const APPOINTMENT_CREATED = "APPOINTMENT_CREATED";
export const APPOINTMENT_CREATED_FAILURE = "APPOINTMENT_CREATED_FAILURE";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const UPDATE_APPOINTMENT_FAILURE = "UPDATE_APPOINTMENT_FAILURE";
export const UPDATE_APPOINTMENT_SUCCESS = "UPDATE_APPOINTMENT_SUCCESS";
export const LOAD_PATIENT_APPOINTMENTS = "LOAD_PATIENT_APPOINTMENTS";
export const LOAD_PATIENT_APPOINTMENTS_COMPLETE = "LOAD_PATIENT_APPOINTMENTS_COMPLETE";
export const LOAD_PATIENT_APPOINTMENTS_FAILURE = "LOAD_PATIENT_APPOINTMENTS_FAILURE";
export const FIND_APPOINTMENTS = "FIND_APPOINTMENTS";
export const FIND_APPOINTMENTS_SUCCESS = "FIND_APPOINTMENTS_SUCCESS";
export const FIND_APPOINTMENTS_FAILURE = "FIND_APPOINTMENTS_FAILURE";

export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS";
export const DELETE_APPOINTMENT_FAILURE = "DELETE_APPOINTMENT_FAILURE";

export const RESET_STATE = "RESET_STATE";

export const SET_CURRENT_APPOINTMENT = "SET_CURRENT_APPOINTMENT";
