<template>
  <div class="text-center">
    <v-dialog v-model="display" width="500">
      <v-card>
        <v-card-title class="text-h4">
          Still there?
        </v-card-title>
        <v-card-text class="text-subtitle-1">
          You have been idle for 30 minutes. You will be automatically logged out due to inactivity soon. (logout: {{ time }}s)
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="js">
import { mapActions } from "vuex";
import { AUTH_LOGOUT } from "../store/auth/mutation-types";
import { getUser, getIdleTimer } from "../utils/auth-helper.js";

export default {
  name: "IdleModal",
  data() {
    return {
      display: true,
      time: 60,
    };
  },
  methods: {
    ...mapActions("auth", [AUTH_LOGOUT]),
  },
  created() {
    this.timer = getIdleTimer().subscribe((t) => {
      this.time = t;
      let user = getUser(true);
      if (this.time < 1 || !user) {
        this.AUTH_LOGOUT();
      }
    });
  },
  beforeUnmount(){
    this.timer.unsubscribe();
  }
};
</script>
