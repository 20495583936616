import * as types from "./mutation-types";
import * as api from "../../utils/api";
import * as fhirHelper from "../../utils/fhir-helpers.js";
import * as appMutations from "../appMutations";

export const actions = {
  [types.LOAD_COUNTRIES]: async ({ commit, state }, context) => {
    commit(types.LOAD_COUNTRIES);

    let url = "countries";
    if (context) url = `${url}?context=${context}`;
    const countriesJson = await api.search(url, {});

    commit(types.LOAD_COUNTRIES_SUCCESS, {
      countries: countriesJson.data,
    });
  },
  [types.CREATE_PATIENT]: async ({ commit, dispatch }, patient) => {
    commit(types.CREATE_PATIENT, patient);
    try {
      var options = {
        headers: { "instance-id": patient.instanceID },
      };
      if (patient.disableNotifications) options.headers.disableNotifications = true;
      var fhirPatient = fhirHelper.patientToFhir(patient);
      const resp = await api.post("patient", fhirPatient, options);
      commit(types.PATIENT_CREATED, resp);
    } catch (error) {
      var errorMessage = error.message;
      if (error.message.includes("401")) errorMessage = "Your session has expired. Please login to continue registration";
      commit(types.PATIENT_CREATED_FAILURE, errorMessage);
      dispatch(appMutations.SNACKBAR_ERROR, errorMessage, {
        root: true,
      });
    }
  },
  [types.UPDATE_PATIENT]: async ({ commit, dispatch }, patient) => {
    commit(types.UPDATE_PATIENT, patient);
    try {
      var options = {
        headers: { "instance-id": patient.instanceID },
      };
      var fhirPatient = fhirHelper.patientToFhir(patient);
      fhirPatient.telecom.push({
        use: "home",
        system: "email",
        value: patient.email,
      });
      fhirPatient.telecom.push({
        use: "home",
        system: "phone",
        value: patient.phone,
      });
      const resp = await api.put("patient", fhirPatient, options);
      commit(types.PATIENT_UPDATED, resp.data);
    } catch (error) {
      var errorMessage = error.message;
      if (error.message.includes("401")) errorMessage = "Your session has expired. Please login to continue registration";
      commit(types.PATIENT_UPDATED_FAILURE, errorMessage);
      dispatch(appMutations.SNACKBAR_ERROR, errorMessage, {
        root: true,
      });
    }
  },
  [types.RESET_STATE]: async ({ commit }) => {
    commit(types.RESET_STATE);
  },
  [types.RESUME_REGISTRATION]: async ({ commit, dispatch, rootState }, patientID) => {
    if (!patientID) {
      return;
    }
    commit(types.RESUME_REGISTRATION);
    await dispatch("patients/LOAD_PATIENT_BY_ID", { id: patientID }, { root: true, noCache: true });
    if (patientID) {
      dispatch("appointments/LOAD_PATIENT_APPOINTMENTS", patientID, { root: true });
      dispatch("patients/LOAD_PATIENT_DOCUMENTS", patientID, { root: true });
      let patient = rootState.patients.currentPatient;
      patient.name = patient.fhirPatient.name;
      commit(types.RESUME_REGISTRATION_SUCCESS, { patient: patient });
    }
  },
};
export const SET_PATIENT = ({ commit }, patient) => {
  commit('SET_PATIENT', patient);
};
