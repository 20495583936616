<template>
  <span>
    <v-select v-bind="$attrs" v-model="gender" :model-value="value" variant="outlined" :items="genders" label="Gender" item-title="display" item-value="code" hide-details @update:model-value="$emit('update', value)"></v-select>
  </span>
</template>
<script>
export default {
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "update",
  },
  props: ["value"],
  emits: ["update"],
  data: () => ({
    gender: null,
    genders: [
      { code: "male", display: "Male" },
      { code: "female", display: "Female" },
      { code: "other", display: "Other" },
      { code: "unknown", display: "Unknown" },
    ],
  }),
  watch: {
    value(val) {
      if (val) this.gender = val;
    },
    gender(val) {
      this.$emit("update", this.gender);
    },
  },
  created() {
    this.gender = this.value;
  },
};
</script>
