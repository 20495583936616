export const LOAD_INTEGRATIONS = "LOAD_INTEGRATIONS";
export const LOAD_INTEGRATIONS_SUCCESS = "LOAD_INTEGRATIONS_SUCCESS";
export const LOAD_INTEGRATIONS_FAILURE = "LOAD_INTEGRATIONS_FAILURE";

export const INTEGRATION_LOAD_BY_ID = "INTEGRATION_LOAD_BY_ID";
export const INTEGRATION_LOAD_BY_ID_SUCCESS = "INTEGRATION_LOAD_BY_ID_SUCCESS";
export const INTEGRATION_LOAD_BY_ID_FAILURE = "INTEGRATION_LOAD_BY_ID_FAILURE";

export const UPDATE_INTEGRATION = "UPDATE_INTEGRATION";
export const UPDATE_INTEGRATION_SUCCESS = "UPDATE_INTEGRATION_SUCCESS";
export const UPDATE_INTEGRATION_FAILURE = "UPDATE_INTEGRATION_FAILURE";

export const INIT_ESIGNATURE = "INIT_ESIGNATURE";
export const INIT_ESIGNATURE_SUCCESS = "INIT_ESIGNATURE_SUCCESS";
export const INIT_ESIGNATURE_FAILURE = "INIT_ESIGNATURE_FAILURE";

export const RESUME_ESIGNATURE = "RESUME_ESIGNATURE";
export const RESUME_ESIGNATURE_SUCCESS = "RESUME_ESIGNATURE_SUCCESS";
export const RESUME_ESIGNATURE_FAILURE = "RESUME_ESIGNATURE_FAILURE";

export const DOWNLOAD_ESIGNATURE = "DOWNLOAD_ESIGNATURE";
export const DOWNLOAD_ESIGNATURE_SUCCESS = "DOWNLOAD_ESIGNATURE_SUCCESS";
export const DOWNLOAD_ESIGNATURE_FAILURE = "DOWNLOAD_ESIGNATURE_FAILURE";

