import * as api from "../../utils/api";
import * as types from "./mutation-types";
import * as appMutations from "../appMutations";

export const actions = {
  [types.LOAD_STATUSES]: async ({ dispatch, rootState }) => {
    if (rootState.auth.user?.groups?.length > 0 && !rootState.auth.user.groups?.some(g => g === "Orbital-Instance-Admin")) {
      dispatch(types.LOAD_STATUSES_AS_ADMIN);
    }
  },
  [types.LOAD_STATUSES_AS_ADMIN]: async ({ commit }) => {
    commit(types.LOAD_STATUSES);
    try {
      const result = await api.get("admin/statuses");
      commit(
        types.LOAD_STATUSES_SUCCESS,
        result.data?.sort((a, b) => (a.order < b.order ? -1 : 1))
      );
    } catch (error) {
      commit(types.LOAD_STATUSES_FAILURE, error);
    }
  },
  [types.STATUS_UPDATE]: async ({ commit, dispatch }, status) => {
    commit(types.STATUS_UPDATE, status);
    try {
      const result = await api.put(`admin/statuses/${status.id}`, status);
      commit(types.STATUS_UPDATE_SUCCESS, result);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Status Updated", {
        root: true,
      });
    } catch (error) {
      commit(types.STATUS_UPDATE_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Updating Status", {
        root: true,
      });
    }
  },
  [types.CREATE_STATUS]: async ({ commit, dispatch }, status) => {
    commit(types.CREATE_STATUS);
    try {
      const result = await api.post("admin/statuses", status);
      commit(types.CREATE_STATUS_SUCCESS, result.data);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Status Created", {
        root: true,
      });
    } catch (error) {
      commit(types.CREATE_STATUS_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Creating Status", {
        root: true,
      });
    }
  },
  [types.DELETE_STATUS]: async ({ commit, dispatch }, status) => {
    commit(types.DELETE_STATUS, status);
    try {
      await api.deleteResource(`admin/statuses/${status.id}`);
      commit(types.DELETE_STATUS_SUCCESS, status.id);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Status Deleted", {
        root: true,
      });
    } catch (error) {
      commit(types.DELETE_STATUS_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Deleting Status", {
        root: true,
      });
    }
  },
  [types.LOAD_CODE_SYSTEM]: async ({ commit }, title) => {
    commit(types.LOAD_CODE_SYSTEM, title);
    try {
      const resp = await api.get(`CodeSystem?title=${title}`);
      let codeSystem = resp?.data?.entry?.[0].resource;
      commit(types.LOAD_CODE_SYSTEM_SUCCESS, codeSystem);
    } catch (error) {
      commit(types.LOAD_CODE_SYSTEM_FAILURE, error);
    }
  },
  [types.UPDATE_CODE_SYSTEM]: async ({ commit }, codeSystem) => {
    commit(types.UPDATE_CODE_SYSTEM, codeSystem);
    try {
      const resp = await api.put(`CodeSystem/${codeSystem.id}`, codeSystem);
      commit(types.UPDATE_CODE_SYSTEM_SUCCESS, resp.data);
    } catch (error) {
      commit(types.UPDATE_CODE_SYSTEM_FAILURE, error);
    }
  }
};
