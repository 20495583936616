<template>
  <div>
    <div v-for="file in files" :key="file.id" class="px-2">
      <v-hover v-slot="{ isHovering }">
        <v-card class="mb-3 py-2" :class="isHovering && !file.complete ? 'card-hover' : ''" :download="file.name" variant="outlined" target="blank" :href="file.url" @click="file.type == 'esignature' ? startSignature(file) : () => {}">
          <v-list-item :download="file.name">
            <template #prepend :color="file.complete ? 'grey lighten-1' : 'primary lighten-1'">
              <v-icon>
                {{ file.complete ? "mdi-check" : file.type == "esignature" ? "mdi-signature" : "mdi-download" }}
              </v-icon>
            </template>
            <v-list-item-title class="text-subtitle-2 text-left" :class="file.complete ? 'text-grey' : ''">
              {{ file.description }}
            </v-list-item-title>
            <v-slide-y-transition>
              <v-list-item-subtitle v-if="!file.complete || isHovering" class="text-caption text-left mb-0 text-grey">
                <span>{{ file.instructions }}</span>
              </v-list-item-subtitle>
            </v-slide-y-transition>
            <v-list-item-action>
              <v-btn v-if="file.complete" variant="text" color="grey" size="small">Complete</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-hover>
    </div>
    <v-overlay :model-value="redirecting"></v-overlay>
  </div>
</template>
<script>
import * as api from "../../utils/api.js";
import { mapState } from "vuex";
export default {
  name: "InstanceRequiredFiles",
  components: {},
  props: ["patientInstance"],
  data: () => ({
    redirecting: false,
    tasks: [],
  }),
  computed: {
    ...mapState("register", ["instanceID"]),
    ...mapState("instances", ["instance", "loading"]),
    files() {
      if (this.currentInstance?.files) {
        return this.currentInstance.files.map(f => {
          return { ...f, complete: this.tasks?.find(t => t.focus?.identifier?.value === f.templateID)?.status === "completed" };
        });
      }
      return [];
    },
    currentInstanceID() {
      return this.instance?.id ?? this.instanceID;
    },
    currentInstance() {
      return this.patientInstance ?? this.instance;
    },
  },
  methods: {
    async fetchDocument(doc) {
      var options = {
        headers: { "instance-id": this.currentInstanceID },
      };
      await api.downloadFile(doc, options);
    },
    startSignature(form) {
      if (!form.complete) {
        this.redirecting = true;
        this.$emit("init-signature", { isNew: true, templateId: form.templateID, formName: form.description });
      }
    },
  },
};
</script>
<style scoped>
.card-hover {
  border-color: #1976d2 !important;
}
</style>
