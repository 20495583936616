<template>
  <v-select v-bind="$attrs" v-model="instancesSelected" class="my-0" :items="orderedInstances" item-title="name" hide-details variant="outlined" @update:model-value="onSelectChange">
    <template #selection="{ item, index }">
      <Avatar v-if="index < 2" tile size="22px" :user="item"></Avatar>
      <span v-if="index == 2" class="text-grey text-caption">(+{{ instancesSelected ? instancesSelected.length - 2 : "" }})</span>
      <span v-if="$attrs['multiple'] === undefined || $attrs['multiple'] === false" class="pl-4" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis !important">{{ item.title }}</span>
    </template>
    <template #item="{ props, item }">
      <v-list-item v-bind="props" lines="two">
        <template #prepend>
          <Avatar tile size="22px" :user="item.raw"></Avatar>
        </template>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import { LOAD_INSTANCES } from "../../store/instances/mutation-types";
import { mapState, mapActions } from "vuex";

export default {
  name: "InstanceSelector",
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    value: {
      type: [String, Array, null],
      default: null,
    },
  },
  data: () => ({
    instancesSelected: [],
  }),
  watch: {
    value(val) {
      if (val) this.instancesSelected = val;
    },
  },
  computed: {
    ...mapState("instances", ["instances"]),

    orderedInstances() {
      if (!this.instances) return [];
      return [...this.instances].sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
  methods: {
    ...mapActions("instances", [LOAD_INSTANCES]),

    onSelectChange() {
      this.$emit("change", this.instancesSelected);
      this.$emit("update", this.instancesSelected);
    },
  },
  async created() {
    this.LOAD_INSTANCES();
    this.instancesSelected = this.value;
  },
};
</script>
