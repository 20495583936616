export const LOAD_AUTOMATIONS = "LOAD_AUTOMATIONS";
export const LOAD_AUTOMATIONS_SUCCESS = "LOAD_AUTOMATIONS_SUCCESS";
export const LOAD_AUTOMATIONS_FAILURE = "LOAD_AUTOMATIONS_FAILURE";

export const LOAD_AUTOMATION_BY_ID = "LOAD_AUTOMATION_BY_ID";
export const LOAD_AUTOMATION_BY_ID_SUCCESS = "LOAD_AUTOMATION_BY_ID_SUCCESS";
export const LOAD_AUTOMATION_BY_ID_FAILURE = "LOAD_AUTOMATION_BY_ID_FAILURE";

export const CREATE_AUTOMATION = "CREATE_AUTOMATION";
export const CREATE_AUTOMATION_SUCCESS = "CREATE_AUTOMATION_SUCCESS";
export const CREATE_AUTOMATION_FAILURE = "CREATE_AUTOMATION_FAILURE";

export const UPDATE_AUTOMATION = "UPDATE_AUTOMATION";
export const UPDATE_AUTOMATION_SUCCESS = "UPDATE_AUTOMATION_SUCCESS";
export const UPDATE_AUTOMATION_FAILURE = "UPDATE_AUTOMATION_FAILURE";

export const DELETE_AUTOMATION = "DELETE_AUTOMATION";
export const DELETE_AUTOMATION_SUCCESS = "DELETE_AUTOMATION_SUCCESS";
export const DELETE_AUTOMATION_FAILURE = "DELETE_AUTOMATION_FAILURE";

export const RESET_AUTOMATION = "RESET_AUTOMATION";

export const LOAD_TEMPLATES = "LOAD_TEMPLATES";
export const LOAD_TEMPLATES_SUCCESS = "LOAD_TEMPLATES_SUCCESS";
export const LOAD_TEMPLATES_FAILURE = "LOAD_TEMPLATES_FAILURE";

export const LOAD_TEMPLATE_BY_ID = "LOAD_TEMPLATE_BY_ID";
export const LOAD_TEMPLATE_BY_ID_SUCCESS = "LOAD_TEMPLATE_BY_ID_SUCCESS";
export const LOAD_TEMPLATE_BY_ID_FAILURE = "LOAD_TEMPLATE_BY_ID_FAILURE";

export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";
export const CREATE_TEMPLATE_FAILURE = "CREATE_TEMPLATE_FAILURE";

export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAILURE = "UPDATE_TEMPLATE_FAILURE";

export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";

export const RESET_TEMPLATE = "RESET_TEMPLATE";
