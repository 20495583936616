import * as types from "./mutation-types";
import * as appMutations from "../appMutations";
import * as api from "../../utils/api";

export const actions = {
  [types.LOAD_ORGANIZATION]: async ({ commit }, id) => {
    if (!id) {
      commit(types.LOAD_ORGANIZATION_FAILURE, "No ID provided");
      return;
    }
    commit(types.LOAD_ORGANIZATION);
    try {
      const result = await api.get(`admin/organization/${id}`);
      commit(types.LOAD_ORGANIZATION_SUCCESS, result.data);
    } catch (error) {
      commit(types.LOAD_ORGANIZATION_FAILURE, error);
    }
  },

  [types.UPDATE_ORGANIZATION]: async ({ commit, dispatch }, { organizationID, organization }) => {
    if (!organizationID) {
      commit(types.UPDATE_ORGANIZATION_FAILURE, "No ID provided");
      return;
    }
    commit(types.UPDATE_ORGANIZATION);
    try {
      const result = await api.put(`admin/organization/${organizationID}`, organization);
      commit(types.UPDATE_ORGANIZATION_SUCCESS, result);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Organization Updated", {
        root: true,
      });
    } catch (error) {
      commit(types.UPDATE_ORGANIZATION_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Updating Organization", {
        root: true,
      });
    }
  },
};
