import { defineStore } from 'pinia';
import * as api from "../../utils/api.js";
import { computeHash, formatDate } from "../../utils/helpers";

export const useNotificationsStore = defineStore({
  id: 'notifications',
  state: () => ({
    loadingNotifications: false,
    count: {
      unread: 0,
      total: 0,
    },
    notifications: [],
    notificationTypes: [],
    error: null,
  }),
  getters: {
    getNotificationCount: state => state.count?.total,
    getUnreadCount: state => state?.count?.unread,
    getNotificationTypes: state => type => state?.notificationTypes?.filter(n => n.type === type) ?? [],
  },
  actions: {
    async loadNotificationCount() {
      try {
        let response = await api.get("admin/notification/count");
        this.count = response?.data;
      } catch (error) {
        console.error("Failed to load notification count:", error);
        this.error = error;
      }
    },
    async loadNotifications(state) {
      try {
        this.loadingNotifications = true;
        let originalHash = computeHash(state?.notifications?.map(notification => `${notification.id}-${notification.read}-${notification.patientName}`));
        var response = await api.get("admin/notification?_sort=-created");
        let newHash = computeHash(response?.data?.map(notification => `${notification.id}-${notification.read}-${notification.patientName}`));

        if (originalHash !== newHash) {
          this.notifications = response?.data;
        }
      } catch (error) {
        console.error("Failed to load notifications:", error);
        this.error = error;
      } finally {
        this.loadingNotifications = false;
      }
    },
    async loadNotificationTypes() {
      try {
        let response = await api.get("admin/notificationType");
        this.notificationTypes = response?.data;
      } catch (error) {
        console.error("Failed to load notification types:", error);
        this.error = error;
      }
    },
    async deleteNotifications(ids) {
      await api.post("admin/notification/delete", ids);
      this.notifications = this.notifications.filter(n => !ids.includes(n.id));
    },
    updateNotification(n) {
      return api.put("admin/notification/update", n);
    },
    resetNotifications() {
      this.notifications = [];
    },
    loadNotificationTypesSuccess(notificationTypes) {
      this.notificationTypes = notificationTypes;
    },
    async markAllAsRead() {
      try {
        await api.put("admin/notification/markAllRead");
        this.notifications.forEach(n => n.read = true);
      } catch (error) {
        console.error("Failed to mark all notifications as read:", error);
        this.error = error;
      }
    }
  },
});
