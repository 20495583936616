import * as types from "./mutation-types";
import * as api from "../../utils/api";
import * as appMutations from "../appMutations";

export const actions = {
  [types.MAPPERS_LOAD]: async ({ commit }) => {
    commit(types.MAPPERS_LOAD);
    try {
      const result = await api.get("admin/mappers");
      commit(types.MAPPERS_LOAD_SUCCESS, result.data);
    } catch (error) {
      commit(types.MAPPERS_LOAD_FAILURE, error);
    }
  },
  [types.MAPPER_LOAD_BY_ID]: async ({ commit, dispatch }, mapperID) => {
    commit(types.MAPPER_LOAD_BY_ID);
    try {
      const result = await api.get(`admin/mappers/${mapperID}`);
      commit(types.MAPPER_LOAD_BY_ID_SUCCESS, result.data);
    } catch (error) {
      commit(types.MAPPER_LOAD_BY_ID_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Loading Mapper", {
        root: true,
      });
    }
  },
  [types.UPDATE_MAPPER]: async ({ commit, dispatch }, { mapperID, mapper }) => {
    commit(types.UPDATE_MAPPER);
    try {
      const result = await api.put(`admin/mappers/${mapperID}`, mapper);
      commit(types.UPDATE_MAPPER_SUCCESS, result);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Mapper Updated", {
        root: true,
      });
    } catch (error) {
      commit(types.UPDATE_MAPPER_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Updating Mapper", {
        root: true,
      });
    }
  },
};
