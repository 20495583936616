import * as types from "./mutation-types";
import * as api from "../../utils/api";
export const actions = {
  [types.LOAD_INTEGRATION_LOCATION]: async ({ commit, state }, { integrationID, locationID }) => {
    commit(types.LOAD_INTEGRATION_LOCATION);
    try {
      locationID = locationID.replace("Location/", "");
      var response = await api.search("admin/integrations/" + integrationID + "/location/" + locationID, {});
      commit(types.LOAD_INTEGRATION_LOCATION_SUCCESS, response?.data);
    } catch (error) {
      commit(types.LOAD_INTEGRATION_LOCATION_FAILURE, error);
    }
  },
  [types.CREATE_LOCATION]: async ({ commit }, location) => {
    commit(types.CREATE_LOCATION);
    try {
      var response = await api.post("location/", location);
      commit(types.CREATE_LOCATION_SUCCESS, response?.data);
    } catch (error) {
      commit(types.CREATE_LOCATION_FAILURE, error);
    }
  },
  [types.UPDATE_LOCATION]: async ({ commit }, location) => {
    commit(types.UPDATE_LOCATION);
    try {
      var response = await api.put("location/" + location.id, location);
      commit(types.UPDATE_LOCATION_SUCCESS, response);
    } catch (error) {
      commit(types.UPDATE_LOCATION_FAILURE, error);
    }
  },
  [types.LOAD_LOCATIONS]: async ({ commit }) => {
    commit(types.LOAD_LOCATIONS);
    try {
      var response = await api.get("location/");
      commit(
        types.LOAD_LOCATIONS_SUCCESS,
        response?.data?.entry.map(e => e.resource)
      );
    } catch (error) {
      commit(types.LOAD_LOCATIONS_FAILURE, error);
    }
  },
};
