<template>
  <v-autocomplete :id="elementId" v-bind="$attrs" :model-value="selectedCountry" autocomplete="new-password" :items="countries" return-object item-value="code" item-title="name" :rules="countryValidators" hide-details placeholder="Select Country" clearble @update:model-value="locationChange">
    <template #selection="{ item }">
      <v-list-item :title="item.title" density="compact">
        <template #prepend>
          <span :class="'fi fi-' + item.value.toLowerCase()" class="flag-icon mr-2"></span>
        </template>
      </v-list-item>
    </template>
    <template #item="{ props, item }">
      <v-list-item v-bind="{ ...props, title: null }" style="min-width: 350px">
        <span v-if="item.value" :class="'fi fi-' + item.value.toLowerCase()" class="flag-icon mr-2"></span>
        <span class="pl-2 text-no-wrap" v-text="item.title"></span>
      </v-list-item>
    </template>
  </v-autocomplete>
  <v-dialog v-model="countryDialog" persistent max-width="600px">
    <v-card>
      <v-card-text class="text-subtitle-1">
        <v-row>
          <v-col>
            <span v-if="country && country.text" v-html="country.text"></span>
            <span v-else>Sorry, we do not currently support your country. If you selected this country in error or are in a different region, please change your selection.</span>
          </v-col>
        </v-row>
        <v-container v-if="country && country.allowCitizens" class="pa-0">
          <v-divider></v-divider>
          <v-row class="mt-4">
            <v-col>
              If you are a US citizen and would like to proceed, please confirm below:
              <v-checkbox v-model="citizen" label="I confirm I am a US Citizen"></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="countryDialog = false" v-text="'Cancel'"></v-btn>
        <v-btn v-show="country.allowCitizens && citizen" color="primary" variant="flat" @click="locationChange(country)" v-text="'Agree'"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { mapState } from "vuex";
export default {
  name: "CountrySelector",
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    value: {
      required: false,
      type: String,
      default: "",
    },
    bypassRestrictions: {
      required: false,
      type: Boolean,
      default: false,
    },
    citizenship: {
      required: false,
      type: Boolean,
      default: false,
    },
    ruleOverrides: {
      required: false,
      type: Object,
      default: null,
    },
  },
  emits: ["update", "locationChange", "citizenshipChange"],
  data: () => ({
    countryDialog: false,
    country: null,
    citizen: null,
    rules: {
      validCountry: c => {
        if (c?.local) {
          return `${c.name} is restricted`;
        }
        return true;
      },
      required: c => {
        if (c === undefined || !c) return "Country is required";
        return true;
      },
    },
  }),
  created() {
    this.citizen = this.citizenship;
  },
  computed: {
    ...mapState("register", ["patient", "countries", "newUser"]),
    elementId() {
      return this.$attrs.id ?? "countrySelector";
    },
    selectedCountry() {
      return this.countries?.find(c => c.code === this.value);
    },
    countryValidators() {
      let rules = { ...this.rules, ...this.ruleOverrides };
      return this.bypassRestrictions || (this.country?.allowCitizens && this.citizen) ? [rules.required] : [rules.validCountry, rules.required];
    },
  },
  methods: {
    locationChange(country) {
      this.country = country;
      //if bypassing restrictions, just emit the country
      if (this.bypassRestrictions) {
        this.$emit("update", country?.code);
        return;
      }
      let valid = this.countryValidators.every(v => v(country) === true);
      if (valid) {
        this.countryDialog = false;
        this.$emit("update", country?.code);
      } else {
        this.countryDialog = true;
      }
    },
  },
};
</script>
