<template>
  <div>
    <v-chip-group v-if="!canEdit" v-model="tagValues" readonly v-bind="$attrs">
      <v-chip v-for="(tag, index) in tagViewModel" :key="index" class="ma-1">
        <span style="max-width: 160px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis !important">{{ renderTag(tag) }}</span>
      </v-chip>
      <v-chip v-if="tagViewModel.length > limit" class="ma-1" :color="color(null)">
        <span class="text-grey text-caption">(+{{ tagViewModel.length - limit }} others)</span>
      </v-chip>
    </v-chip-group>
    <v-combobox v-else v-model="selectedTags" :items="allTags" item-title="code" variant="plain" item-value="code" chips density="compact" append-inner-icon="mdi-plus" menu-icon=" null" multiple hide-details @update:model-value="saveTags"></v-combobox>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-combobox v-model="selectedTags" :items="allTags" item-title="code" variant="outlined" item-value="code" chips multiple hide-details></v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="dialog = false">Cancel</v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="saveTags">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  inheritAttrs: true,
  props: {
    value: Array,
    limit: Number,
    canEdit: Boolean,
  },
  emits: ["update:model", "change"],
  data: () => ({
    tags: [],
    dialog: false,
    selectedTags: [],
    allTags: [],
  }),
  computed: {
    tagViewModel() {
      return (
        this.tags?.map(t => {
          return typeof t === "object" ? t : { code: t };
        }) ?? []
      );
    },
    tagValues: {
      get: function () {
        return this.tags ? this.tags.map(t => this.renderTag(t)) : [];
      },
      set: function (val) {
        this.tags = val?.value?.map(t => ({ code: t }));
      },
    },
    dialogTitle() {
      return this.canEdit ? "Manage Tags" : "Tags";
    },
  },
  watch: {
    value(val) {
      this.tags = val ?? [];
    },
    allTags(val) {
      this.selectedTags = this.tags?.filter(tag => val.some(t => t.code === tag.code)) ?? [];
    },
  },
  created() {
    this.tags = this.value;
    this.allTags = this.tagViewModel;
  },
  methods: {
    removeTag(tag) {},
    renderTag(tag) {
      return tag.display ?? tag.code ?? tag.value;
    },
    saveTags() {
      this.dialog = false;
      this.$emit(
        "change",
        this.selectedTags.map(t => {
          return typeof t === "object" ? t : { code: t };
        })
      );
    },
  },
};
</script>
