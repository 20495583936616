
export function rateLimiter(axios, options) {
  const queue = [];
  let activeCount = 0;

  const next = () => {
    if (queue.length && activeCount < options.maxConcurrent) {
      activeCount++;
      const requestResolver = queue.shift();
      if (requestResolver) {
        requestResolver();
      }
    }
  };

  axios.interceptors.request.use(config => {
    return new Promise(resolve => {
      const requestResolver = () => {
        resolve(config);
        next();
      };
      queue.push(requestResolver);
      if (activeCount < options.maxConcurrent) {
        next();
      }
    });
  });

  axios.interceptors.response.use(
    response => {
      activeCount--;
      next();
      return response;
    },
    error => {
      activeCount--;
      next();
      return Promise.reject(error);
    }
  );
}