<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-container class="ma-0 pa-0 align-start" fluid>
    <v-tabs v-model="tab">
      <v-tab value="profile">Profile</v-tab>
      <v-tab value="biography">Biography</v-tab>
      <v-tab value="tags">Search Tags</v-tab>
      <v-tab value="accounts">Linked Profiles</v-tab>
      <v-tab value="notes">Notes</v-tab>
      <!-- <v-tab v-if="checkFeature('scheduling')" href="#scheduling">Scheduling</v-tab> -->
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item value="profile" class="pa-4">
        <v-row>
          <v-col cols="12" sm="6" lg="4">
            <v-card>
              <v-card-text class="pa-0">
                <v-container fill-height fluid class="pa-0">
                  <v-row class="my-2">
                    <v-col>
                      <input v-if="canEdit" type="file" accept="image/*" style="z-index: 3" :class="['input-file', dragging ? 'dragenterClass' : '']" drag-over="handleDragOver" @change="previewImage($event)" @dragenter="dragging = true" @dragend="dragging = false" @dragleave="dragging = false" />
                      <v-row justify="space-around">
                        <v-avatar v-if="!uploading" size="200px" rounded="0">
                          <failover-image v-if="practitioner.photo" :src="practitioner.photo[0].url" :alt="practitioner.name[0].text" position="center,top" />
                          <v-icon v-else size="112">mdi-account-circle-outline</v-icon>
                        </v-avatar>
                        <v-progress-circular v-if="uploading" :size="80" :width="7" color="primary" indeterminate class="fill-height mx-auto mt-8"></v-progress-circular>
                      </v-row>
                      <v-row justify="space-around" class="mt-5 py-2">
                        <div class="text-caption mb-0" style="font-size: 10px !important; width: 100%; text-align: center">Image should be square (256x256, 512x512)</div>
                        <div class="text-caption mb-0" style="font-size: 10px !important; width: 100%; text-align: center">Image should be less than 2MB</div>
                        <v-alert v-if="uploadError" density="compact" variant="outlined" type="error" v-text="uploadError"></v-alert>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            <v-card v-if="practitioner" width="100%" class="mt-4">
              <v-card-title v-if="canEdit" class="pa-0">
                <span class="text-subtitle-2 ml-4">Details</span>
                <span v-if="canEdit" class="float-right mr-4">
                  <v-btn v-if="!edit" class="ma-1" color="primary" variant="text" size="small" @click.stop="startEdit()">Edit</v-btn>
                  <v-btn v-if="edit" class="ml-2" variant="text" size="small" @click.stop="resetForm()">Cancel</v-btn>
                  <v-btn v-if="edit" class="ma-1" variant="flat" color="primary" :disabled="!validVM && !updating" :loading="updating" size="small" @click.stop="save">Save</v-btn>
                </span>
              </v-card-title>
              <v-card-text class="py-4">
                <v-form ref="form" v-model="validVM" style="min-height: 245px">
                  <v-row>
                    <v-col>
                      <span v-for="(name, i) in practitioner.name" :key="i">
                        <v-row :key="`name-row-${i}`" dense>
                          <v-col cols="12" md="6">
                            <v-text-field v-if="name.given" id="first-name" v-model="name.given[0]" hide-details="auto" :disabled="!edit" variant="outlined" label="First Name" class="mb-3"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field id="last-name" v-model="name.family" hide-details="auto" variant="outlined" :disabled="!edit" label="Last Name" class="mb-3"></v-text-field>
                          </v-col>
                        </v-row>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <Gender :value="practitioner.gender" :disabled="!edit" class="mb-3" @update="genderUpdate" />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="providerNpi" :rules="[rules.npi]" variant="outlined" label="NPI Number" validate-on="blur" :disabled="!edit" class="mb-3" persistent-hint hint="10-digit National Provider Identifier"></v-text-field>
                    </v-col>
                    <v-col v-for="(phone, i) in practitioner.telecom" :key="`pract-phone-${i}`" cols="12" md="6">
                      <v-text-field v-model="phone.value" :rules="[rules.required]" variant="outlined" label="Phone Number" validate-on="blur" :disabled="!edit" class="mb-3" persistent-hint hint="Include area code"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="practitioner.communication" :items="mapLanguages" label="Languages Spoken" multiple variant="outlined" hide-details="auto" return-object item-title="text" :disabled="!edit" chips class="mb-3"></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col col="12" sm="6" lg="4">
            <v-hover v-slot="{ isHovering, props }">
              <v-card width="100%" class="mb-4 py-2" v-bind="props">
                <v-card-title class="py-0">
                  <span class="text-subtitle-2">Departments</span>
                  <v-btn v-if="canEdit && isHovering" icon color="primary" variant="text" size="small" class="float-right" @click="addDepartment">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-title>

                <v-chip v-for="(department, index) in departmentExtensions" :key="department.title" label class="ma-1 px-4" color="grey-lighten-2" size="small" :closable="isHovering" @click:close="removeDepartment(department)">
                  <span>{{ department.title }}</span>
                </v-chip>

                <v-text-field v-if="showDepartmentInput" v-model="newDepartment" label="New Department" density="compact" hide-details class="mt-2" @keyup.enter="saveDepartment" @blur="cancelAddDepartment"></v-text-field>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ isHovering, props }">
              <v-card v-if="practitioner && !isNewProvider" width="100%" class="mb-4 py-2" v-bind="props">
                <v-card-title class="py-0">
                  <span class="text-subtitle-2">Locations</span>
                  <v-btn v-if="canEdit && isHovering" icon :loading="loadingFhirLocations" class="float-right" size="small" variant="text" :disabled="!isHovering" @click.stop="startAddLocation()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="pa-0">
                  <template v-if="(providerLoading && providerLocations.length == 0) || loadingFhirLocations">
                    <v-list-item>
                      <v-skeleton-loader type="heading" width="100%"></v-skeleton-loader>
                    </v-list-item>
                  </template>
                  <v-list-item v-else-if="providerLocations.length == 0" color="grey">No associated locations</v-list-item>
                  <template v-for="item in providerLocations" :key="item.id">
                    <v-chip class="ma-1" size="small" label :closable="isHovering" @click:close="deleteLocation(item)" @click.stop="locationEdit(item)">
                      <span>{{ item.name ? item.name : item.display }}</span>
                    </v-chip>
                  </template>
                </v-card-text>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ isHovering, props }">
              <v-card v-if="practitioner" width="100%" class="mb-4 py-2" v-bind="props">
                <v-card-title class="py-0">
                  <span class="text-subtitle-2">Qualifications</span>
                  <v-menu>
                    <template #activator="{ props }">
                      <v-chip v-bind="props" class="mx-4" size="small">
                        {{ qualificationActiveFilter }}
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item v-for="(item, index) in ['Active Only', 'All']" :key="`pract-qual-${index}`" @click="qualificationActiveFilter = item">
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn v-if="canEdit && isHovering" icon class="float-right" variant="text" size="small" @click.stop="startAddQualification()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="pa-0">
                  <v-list-item v-if="!practitioner.qualification && providerLoading">
                    <v-skeleton-loader type="heading" width="100%"></v-skeleton-loader>
                  </v-list-item>
                  <v-list-item v-else-if="!practitioner.qualification || practitioner.qualification.length == 0" color="grey">No licenses found</v-list-item>
                  <v-table v-else>
                    <template #default>
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Issuer</th>
                          <th>Dates</th>
                          <th v-if="canEdit"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in filteredQualifications" :key="`pract-filtered-qual-${index}`">
                          <td>
                            <span v-if="item.code">{{ item.code.text ?? item.code.coding?.[0].display }}</span>
                          </td>
                          <td>
                            <span v-if="item.issuer">{{ item.issuer.display }}</span>
                          </td>
                          <td>
                            <span v-if="item.period">{{ formatDate(item.period.start) }}</span>
                            <span v-if="item.period && item.period.start">-</span>
                            <span v-if="item.period">{{ formatDate(item.period.end) }}</span>
                          </td>
                          <td v-if="canEdit">
                            <v-icon size="16" color="grey-lighten-1" @click.stop="deleteQualification(item)">mdi-trash-can-outline</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-table>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <VCol v-if="checkFeature('tasks')" cols="12" sm="6" lg="4">
            <v-card min-width="200" max-width="290" min-height="150">
              <v-card-title class="">Task Breakdown</v-card-title>
              <v-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in taskStats" :key="`task-stat-${index}`">
                      <td>{{ toProperCase(item.status) }}</td>
                      <td>{{ item.cnt }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-table>
            </v-card>
            <v-card min-width="200" max-width="290" flat variant="plain">
              <TaskList v-if="practitioner && checkFeature('tasks')" :body-height="800" :hide-complete="false" :can-edit="false" :owner="`Practitioner/${$route.params.id}`"></TaskList>
            </v-card>
          </VCol>
        </v-row>
      </v-window-item>
      <v-window-item value="biography" class="pa-8">
        <v-card max-width="800">
          <v-card-title>
            <span v-if="canEdit" class="float-right">
              <v-btn v-if="!edit" class="ma-1" color="primary" variant="flat" size="small" @click.stop="startEdit()">Edit</v-btn>
              <v-btn v-if="edit" class="ml-2" variant="text" size="small" @click.stop="resetForm()">Cancel</v-btn>
              <v-btn v-if="edit" class="ma-1" variant="flat" color="primary" :disabled="!validVM && !updating" :loading="updating" size="small" @click.stop="save">Save</v-btn>
            </span>
          </v-card-title>
          <v-card-text>
            <span v-if="!edit && practitioner.text" class="pa-4" v-html="practitioner.text.div"></span>
            <div v-else-if="tab === 'biography' && practitioner.text" style="min-height: 120px">
              <WYSIWYG :value="practitioner.text.div" identifier="practitionerBio" @update="updateBio"></WYSIWYG>
            </div>
          </v-card-text>
        </v-card>
      </v-window-item>
      <v-window-item value="notes">
        <Notes v-if="practitioner.id" :subject="`Practitioner/${practitioner.id}`" :subject-name="practitioner.name ? practitioner.name[0].text : null" :can-edit="canEdit"></Notes>
      </v-window-item>
      <v-window-item value="tags" class="pa-8">
        <v-row>
          <v-col v-if="canEdit" cols="12" align="end">
            <v-btn v-if="!edit" class="ma-1" color="primary" variant="flat" size="small" @click.stop="startEdit()">Edit</v-btn>
            <v-btn v-if="edit" class="ml-2" variant="text" size="small" @click.stop="resetForm()">Cancel</v-btn>
            <v-btn v-if="edit" class="ma-1" variant="flat" color="primary" :disabled="!validVM && !updating" :loading="updating" size="small" @click.stop="save">Save</v-btn>
          </v-col>
        </v-row>
        <Tags v-if="practitioner.meta" :value="practitioner.meta.tag" :disabled="!edit" :can-edit="edit" append-icon="false" @change="saveTags"></Tags>
      </v-window-item>
      <v-window-item value="accounts" class="pa-4">
        <v-card v-if="practitioner.id" width="100%" variant="flat" class="mb-6" height="100%">
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-expansion-panels variant="accordion">
                  <v-expansion-panel v-for="(integration, i) in mapIdentifiers" :key="`${integration.system}-${i}`" class="elevation-0">
                    <v-expansion-panel-title disable-icon-rotate>
                      <failover-image :src="integration.logo" max-width="160" max-height="24" class="ml-4" position="left"></failover-image>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      {{ integration.value }}
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-window-item>
      <v-window-item value="scheduling" class="pa-4">
        <v-card class="mx-auto" max-width="800">
          <v-card-title>
            Availability
            <v-spacer></v-spacer>
            <v-btn density="compact" size="small" color="primary" variant="flat" :loading="savingSchedule" @click="saveSchedule">Save</v-btn>
          </v-card-title>
          <v-card-subtitle class="pb-0">Default Schedule</v-card-subtitle>
          <v-card-text>
            <v-list>
              <v-list-item v-for="day in Object.keys(schedule)" :key="day" style="min-height: 64px">
                <template v-if="practitionerRole.availableTime" #prepend>
                  <v-checkbox v-model="schedule[day].enabled"></v-checkbox>
                </template>
                <v-list-item-title class="text-button">
                  {{ day }}
                </v-list-item-title>
                <v-list-item-title>
                  <template v-if="schedule[day].enabled">
                    <v-row>
                      <v-col>
                        <v-select v-model="schedule[day].start" :items="times" variant="outlined" hide-details density="compact"></v-select>
                      </v-col>
                      <v-col>
                        <v-select v-model="schedule[day].end" :items="times" variant="outlined" hide-details density="compact"></v-select>
                      </v-col>
                    </v-row>
                  </template>
                  <span v-else class="text-grey">Unavailable</span>
                </v-list-item-title>
                <v-list-item-action></v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-window-item>
    </v-window>
    <v-dialog v-model="locationEditDialog" width="auto" max-width="1000px">
      <LocationForm :value="editingLocation" @save="saveLocation" @cancel="cancelLocationEdit"></LocationForm>
    </v-dialog>
    <v-dialog v-model="qualificationEditDialog" width="800px">
      <v-card v-if="qualificationEditDialog">
        <v-card-title class="card-title" admin-appbar>
          <span class="text-h6">{{ newQualification ? "New" : "Edit" }} Qualification</span>
          <v-btn variant="text" class="mr-2 float-right" icon @click="cancelQualificationEdit">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container style="min-height: 300px; overflow: auto" fluid>
            <v-form ref="form" v-model="qualificationValid" autocomplete="off" :disabled="processing">
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-autocomplete v-model="editingQualification.code.coding[0]" variant="outlined" :items="qualificationCodes" label="Type" item-title="display" return-object hide-details></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="editingQualification.issuer.display" class="mb-5" variant="outlined" hide-details label="Issuer/State" auto-grow></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-1">
                  <v-text-field v-model="editingQualification.period.start" label="Issued" variant="outlined" autocomplete="off" type="date" clearable hide-details></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1">
                  <v-text-field v-model="editingQualification.period.end" label="Expires" variant="outlined" type="date" :rules="[rules.date]" autocomplete="off" clearable hide-details></v-text-field>
                </v-col>
                <v-row class="my-2 mx-1">
                  <v-col>
                    Identifiers
                    <Identifiers :value="editingQualification.identifier"></Identifiers>
                    <v-row>
                      <v-col cols="5">
                        <v-text-field v-model="newIdentifier" label="New Identifier" single-line></v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field v-model="newIdentifierSystem" label="System" single-line></v-text-field>
                      </v-col>
                      <v-col>
                        <v-btn icon @click="addIdentifier">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="flat" :disabled="!qualificationValid" @click.stop="saveQualification()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="deleteDialog && editingQualification.code" v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">Delete qualification?</v-card-title>
        <v-card-text>{{ editingQualification.code.coding[0].display }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="primary" variant="flat" @click="deleteCallback">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script lang="js">
import { mapActions, mapState } from "vuex";
import { LOAD_PRACTITIONER, UPDATE_ROLE, CREATE_ROLE } from "../../store/practitioners/mutation-types";
import { UPDATE_LOCATION, LOAD_LOCATIONS } from "../../store/locations/mutation-types";
import { LOAD_INTEGRATIONS } from "../../store/integrations/mutation-types";
import { LOAD_STATUSES } from "../../store/settings/mutation-types";
import json from "../../assets/languages.json";
import Codes from "../../assets/code-systems/qualifications.json";
import Location from "../shared/location.vue";
import * as api from "../../utils/api.js";
import { colorFromString, formatDate } from "../../utils/helpers";
import { SNACKBAR_ERROR } from "../../store/appMutations";
import Tags from "../shared/tags.vue";
import TaskList from "../tasks/TaskList.vue";
import Gender from "../shared/gender.vue";
import Phone from "../shared/phone.vue";
import Notes from "../shared/notes.vue";
import LocationForm from "../shared/locationForm.vue";
import WYSIWYG from "../wysiwyg/wysiwyg.vue";
import SimpleIdentifiers from "../shared/SimpleIdentifiers.vue";
import { useTasks } from "../../store/tasks";
import { useFhir } from "../../store/fhir/index.js";
const qualificationCodes = Codes.concept;

let times = [];
for (let hour = 0; hour < 24; hour++) {
  const h = hour < 10 ? `0${hour}` : hour;

  times.push(`${h}:00:00`);
  times.push(`${h}:30:00`);
}
export default {
  components: { Tags, Gender, Phone, Notes, Location, LocationForm, TaskList, WYSIWYG, SimpleIdentifiers },
  inject: ["checkFeature"],
  props: {
    passedPractitioner: {
      type: Object,
      default: null,
    },
    locations: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    hideTabs: {
      type: Boolean,
      default: false,
    },
    formEdit: {
      type: Boolean,
      default: false,
    },
    saveExt: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    providerLoading: {
      type: Boolean,
      default: false,
    },
    hideNotes: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["save", "update:valid"],
  data: () => ({
    qualificationCodes,
    newIdentifier: null,
    newIdentifierSystem: null,
    locationEditDialog: false,
    qualificationEditDialog: false,
    showTaskDialog: false,
    showDepartmentInput: false,
    newDepartment: "",
    editingQualification: {
      mode: "instance",
      code: {},
      issuer: {},
      period: {
        start: null,
        end: null,
      },
      identifier: [],
    },
    editingLocation: {
      mode: "instance",
      status: "active",
      address: { line: [""] },
      telecom: [],
    },
    uploading: false,
    uploadError: null,
    imageData: null,
    picture: null,
    processing: false,
    updating: false,
    languages: json.languages,
    qualificationValid: true,
    rules: {
      required: value => {
        if (value && typeof value === "string") return !!value.trim() || "Required.";
        else return !!value || "Required.";
      },
      requiredCode: value => {
        if (value && typeof value === "object") return value.coding?.length > 0;
        else return false;
      },
      date: value => {
        if (!value) return true;
        var validDate = true;
        try {
          var date = new Date(value);
          validDate = date instanceof Date && !Number.isNaN(date);
        } catch {
          return false;
        }
        return validDate;
      },
      npi: value => {
        if (value) {
          let test = /^\d{10}$/.test(value);
          if (!test) return "NPI must be 10-digits";
        }
        return true;
      },
    },
    appointmentHeaders: [
      { title: "Patient", value: "patient" },
      { title: "Description", value: "description" },
      { title: "Status", value: "status" },
      { title: "Date", value: "date" },
    ],
    dragging: false,
    newQualification: false,
    deleteDialog: false,
    deleteCallback: () => {},
    days: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
    practitionerRoles: [],
    practitionerRole: {
      mode: "instance",
      practitioner: {},
      organization: {},
      role: {},
      availableTime: [],
    },
    tab: null,
    schedule: {
      sun: { start: "08:00:00", end: "17:00:00", enabled: false },
      mon: { start: "08:00:00", end: "17:00:00", enabled: false },
      tue: { start: "08:00:00", end: "17:00:00", enabled: false },
      wed: { start: "08:00:00", end: "17:00:00", enabled: false },
      thu: { start: "08:00:00", end: "17:00:00", enabled: false },
      fri: { start: "08:00:00", end: "17:00:00", enabled: false },
      sat: { start: "08:00:00", end: "17:00:00", enabled: false },
    },
    savingSchedule: false,
    qualificationActiveFilter: "Active Only",
    fhirStore: null,
    taskStore: null,
  }),
  watch: {
    saveExt: function (val) {
      if (val) this.save();
    },
  },
  computed: {
    ...mapState("appointments", ["patients", "appointments", "loading"]),
    ...mapState("locations", ["fhirLocations", "loadingFhirLocations"]),
    ...mapState("practitioners", { statePractitioner: "practitioner", practitioners: "practitioners" }),
    ...mapState("settings", ["statuses", "visitTypes"]),
    ...mapState("integrations", ["integrations"]),
    ...mapState("organization", ["organization"]),
    edit() {
      return this.formEdit;
    },
    practitioner() {
      return this.passedPractitioner ?? this.statePractitioner;
    },
    taskStats() {
      return this.taskStore.ownerStats?.filter(stat => stat.practitionerId === this.practitioner.id) ?? [];
    },
    times() {
      return times;
    },
    filteredQualifications() {
      return this.practitioner.qualification.filter(q => {
        return this.qualificationActiveFilter === "Active Only" ? q.period?.end > new Date().toISOString() : true;
      });
    },
    validVM: {
      get: function () {
        return this.valid;
      },
      set: function (val) {
        this.$emit("update:valid", val);
      },
    },
    departmentExtensions() {
      if (!this.practitioner || !this.practitioner.extension) return [];
      return this.practitioner.extension
        .filter(ext => ext.url === "department")
        .map(ext => {
          return { title: ext.valueString ?? ext.valueCodeableConcept?.text };
        });
    },
    mapLanguages() {
      return this.languages.map(concept => {
        return {
          text: concept.display,
          coding: [
            {
              system: "http://terminology.hl7.org/CodeSystem/designation-usage",
              code: concept.code,
              display: concept.display,
            },
          ],
        };
      });
    },
    isNewProvider() {
      return this.$route.params.id === "new";
    },
    mapIdentifiers() {
      if (!this.integrations || !this.practitioner.identifier) return [];
      return this.practitioner.identifier
        .map(id => {
          let integration = this.integrations.find(i => id.system.includes(i.systemUri));
          if (!integration) return false;
          return {
            logo: integration.logo,
            description: integration.description,
            system: id.system,
            value: id.value,
          };
        })
        .filter(id => id);
    },
    providerNpi: {
      get() {
        let npi = this.practitioner?.identifier?.find(i => i.system == "NPI" || i.system === "http://hl7.org/fhir/sid/us-npi");
        return npi?.value;
      },
      set(val) {
        if (!this.practitioner.identifier) {
          this.practitioner.identifier = [];
        }
        let npi = this.practitioner.identifier.find(i => i.system == "NPI" || i.system === "http://hl7.org/fhir/sid/us-npi");
        if (npi) {
          npi.value = val;
        } else {
          let newNpi = { system: "http://hl7.org/fhir/sid/us-npi", value: val };
          this.practitioner.identifier.push(newNpi);
        }
      },
    },
    providerLocations() {
      var locations = [];
      if (this.practitioner?.locations && this.fhirLocations) {
        locations = this.practitioner.locations.map(loc => {
          return { display: loc.display || "Office", ...this.fhirLocations.find(floc => floc.id === loc.reference?.substring(9)) };
        });
      } else if (this.locations) {
        return this.locations;
      }
      return locations;
    },
  },
  async created() {
    const id = this.$route.params.id;
    this.taskStore = useTasks();
    this.fhirStore = useFhir();
    if (!this.practitioner) this.LOAD_PRACTITIONER(id);
    if (!this.integrations) this.LOAD_INTEGRATIONS();
    if (!this.statuses) this.LOAD_STATUSES();
    this.LOAD_LOCATIONS();

    this.practitionerRole.availableTime = this.days.map(d => {
      return { daysOfWeek: [d] };
    });
    if (this.practitioner.id) {
      this.getSchedule(this.practitioner.id);
    }
    if (this.checkFeature("tasks") && this.taskStats.length == 0) this.taskStore.loadOwnerStats();
  },
  methods: {
    ...mapActions("practitioners", [UPDATE_ROLE, CREATE_ROLE, LOAD_PRACTITIONER]),
    ...mapActions("locations", [UPDATE_LOCATION, LOAD_LOCATIONS]),
    ...mapActions("integrations", [LOAD_INTEGRATIONS]),
    ...mapActions("settings", [LOAD_STATUSES]),
    ...mapActions([SNACKBAR_ERROR]),
    async addIdentifier() {
      if (!this.editingQualification.identifier) this.editingQualification.identifier = [];
      this.editingQualification.identifier.push({
        system: this.newIdentifierSystem,
        value: this.newIdentifier,
      });
      this.newIdentifier = "";
      this.newIdentifierSystem = "";
    },
    startAddLocation() {
      this.locationEditDialog = true;
      this.editingLocation = {};
      this.editingLocation = {
        mode: "instance",
        status: "active",
        address: { line: [""] },
        telecom: [],
      };
    },
    cancelLocationEdit() {
      this.locationEditDialog = false;
      this.editingLocation = {
        mode: "instance",
        status: "active",
        address: { line: [""] },
        telecom: [],
      };
    },
    deleteLocation(location) {
      const role = { ...this.practitioner.role, location: this.practitioner.role.location.filter(loc => loc.reference.substring(9) !== location.id) };
      this.UPDATE_ROLE({ practitionerRole: role, location: location });
    },
    locationEdit(location) {
      let telecom = location.telecom ? [...location.telecom] : [];
      let address = location.address ? { ...location.address } : { line: [null] };
      this.editingLocation = {
        ...location,
        address: address,
        telecom: telecom,
      };
      this.locationEditDialog = true;
    },
    saveLocation(location) {
      if (this.practitioner.role) this.UPDATE_ROLE({ practitionerRole: this.practitioner.role, location: location });
      else {
        var role = {
          practitioner: { reference: "Practitioner/" + this.practitioner.id, display: this.practitioner?.name[0].text },
          active: true,
          location: [],
        };
        this.CREATE_ROLE({ practitionerRole: role, location: location });
      }
      this.locationEditDialog = false;
    },
    editQualification(q) {
      q.period = q.period ?? {
        start: null,
        end: null,
      };
      this.qualificationEditDialog = true;
      this.editingQualification = q;
      this.newQualification = false;
    },
    startAddQualification() {
      this.qualificationEditDialog = true;
      this.newQualification = true;
      this.editingQualification = {
        code: {
          coding: [{ system: null, code: null, display: null }],
        },
        period: {
          start: null,
          end: null,
        },
        issuer: {},
      };
    },
    cancelQualificationEdit() {
      this.qualificationEditDialog = false;
    },
    saveQualification() {
      this.practitioner.qualification = this.practitioner.qualification ?? [];
      if (this.newQualification) this.practitioner.qualification.push(this.editingQualification);
      this.qualificationEditDialog = false;
      this.save();
    },
    resetForm() {
      this.$emit("reset");
    },
    startEdit() {
      this.$emit("startEdit");
    },
    mapStatus(fhirStatus) {
      var status = this.statuses?.find(s => fhirStatus == s.fhirStatus);
      if (!status) return null;
      return status.name;
    },
    async previewImage(event) {
      this.uploadError = null;
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
      const fileSize = this.imageData.size / 1024 / 1024;
      if (fileSize > 2) {
        this.dragging = false;
        this.uploadError = "Image must be less than 2MB";
        return;
      }
      await this.upload();
    },
    async upload() {
      this.uploading = true;
      this.dragging = false;
      this.picture = null;

      let parent = this;
      let formData = new FormData();
      formData.append("file", this.imageData);

      await api
        .post(`asset/public`, formData)
        .then(response => {
          // handle success
          parent.picture = response.url;
          if (!parent.practitioner.photo) parent.practitioner.photo = [];
          parent.practitioner.photo[0] = { url: response.url };
          parent.uploading = false;
          parent.$forceUpdate();
          if (!parent.isNewProvider) parent.save();
        })
        .catch(error => {
          parent.SNACKBAR_ERROR(error.message);
        });
    },
    save() {
      this.$emit("save", this.practitioner);
    },
    color(value) {
      return value ? colorFromString(value) : null;
    },
    saveTags(tags) {
      this.practitioner.meta.tag = tags;
      this.save();
    },
    removeTag(tag) {
      this.practitioner.meta.tag = this.arrayRemove(this.practitioner.meta.tag, tag);
    },
    deleteQualification(q) {
      this.deleteDialog = true;
      this.editingQualification = q;
      let parent = this;
      this.deleteCallback = async function () {
        parent.deleteDialog = false;
        parent.practitioner.qualification = parent.arrayRemove(parent.practitioner.qualification, q);
        parent.save();
      };
    },
    arrayRemove(arr, value) {
      return arr.filter(ele => ele !== value);
    },
    async saveSchedule() {
      this.savingSchedule = true;
      let val = this.schedule;
      this.practitionerRole.availableTime = Object.keys(val)?.map(day => {
        return {
          daysOfWeek: [day],
          availableStartTime: val[day].enabled ? val[day]?.start || "08:00:00" : null,
          availableEndTime: val[day].enabled ? val[day]?.end || "17:00:00" : null,
        };
      });
      this.practitionerRole.practitioner = {
        reference: `Practitioner/${this.practitioner.id}`,
        display: this.practitioner.name?.[0]?.text,
      };
      if (this.practitionerRole.id) await api.put(`PractitionerRole/${this.practitionerRole.id}`, this.practitionerRole);
      else await api.post("PractitionerRole", this.practitionerRole);

      this.savingSchedule = false;
    },
    async updateBio(val) {
      this.practitioner.text.div = val;
    },
    async getSchedule() {
      await api.get(`PractitionerRole?practitioner=${this.practitioner.id}`).then(async res => {
        this.practitionerRoles = res.data?.entry?.map(entry => entry.resource);

        if (this.practitionerRoles?.length > 0) {
          this.practitionerRole = { ...this.practitionerRole, ...this.practitionerRoles[0] };
        } else {
          //create role
          await api.post("PractitionerRole", {
            resourceType: "PractitionerRole",
            practitioner: { reference: `Practitioner/${this.practitioner.id}` },
          });
        }
      });

      let d = {};
      this.practitionerRole?.availableTime?.map(day => {
        d[day.daysOfWeek[0]] = {
          start: day.availableStartTime,
          end: day.availableEndTime,
          enabled: !!day.availableStartTime,
        };
        return d;
      }) || [];
      this.schedule = { ...this.schedule, ...d };
    },
    genderUpdate(val) {
      this.practitioner.gender = val;
    },
    addLocation(locationId) {
      if (!locationId) return;
      const location = this.fhirLocations.find(l => l.id === locationId);
      if (location) {
        if (!this.practitioner.role) {
          this.practitioner.role = {
            location: [],
          };
        }
        this.practitioner.role.location.push({
          reference: `Location/${location.id}`,
          display: location.name,
        });
        this.save();
      }
      this.selectedLocation = null;
    },
    removeLocation(location) {
      if (this.practitioner?.role?.location) {
        this.practitioner.role.location = this.practitioner.role.location.filter(l => l.reference !== `Location/${location.id}`);
        this.save();
      }
    },
    addDepartment() {
      this.showDepartmentInput = true;
    },
    saveDepartment() {
      if (this.newDepartment.trim()) {
        if (!this.practitioner.extension) {
          this.practitioner.extension = [];
        }
        this.practitioner.extension.push({
          url: "department",
          valueString: this.newDepartment.trim(),
        });
        this.newDepartment = "";
        this.showDepartmentInput = false;
        this.save();
      }
    },
    cancelAddDepartment() {
      this.newDepartment = "";
      this.showDepartmentInput = false;
    },
    removeDepartment(department) {
      this.practitioner.extension = this.practitioner.extension.filter(ext => ext.valueString !== department.title);
      this.save();
    },
  },
};
</script>
<style scoped>
.drop-box {
  border: 3px dashed rgba(25, 118, 210, 0.11) !important;
  border-radius: 4px;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 240px;
  position: absolute;
  cursor: pointer;
}
</style>
