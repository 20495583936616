import * as types from "./mutation-types";
import { actions } from "./actions";

const state = {
  integrations: null,
  integration: null,
  error: null,
  downloadingEsignatures: [],
};

const getters = {
  getIntegrations: state => state.integrations,
  getIsDownloadingEsignatures: state => state.downloadingEsignatures.length > 0,
};

export const mutations = {
  [types.LOAD_INTEGRATIONS]: state => {
    state.error = null;
  },
  [types.LOAD_INTEGRATIONS_SUCCESS]: (state, integrations) => {
    state.integrations = integrations;
  },
  [types.LOAD_INTEGRATIONS_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.INTEGRATION_LOAD_BY_ID]: (state, integrationID) => {
    state.loading = true;
  },
  [types.INTEGRATION_LOAD_BY_ID_SUCCESS]: (state, integration) => {
    state.integration = { processing: false, status: null, ...integration };
  },
  [types.INTEGRATION_LOAD_BY_ID_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.UPDATE_INTEGRATION]: (state, integration) => {
    const i = state.integrations.find(i => i.id === integration.id);
    if (i) {
      Object.assign(i, integration);
    }
    state.loading = true;
  },
  [types.UPDATE_INTEGRATION_SUCCESS]: state => {
    state.loading = false;
  },
  [types.UPDATE_INTEGRATION_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.DOWNLOAD_ESIGNATURE]: (state, taskID) => {
    state.downloadingEsignatures.push(taskID);
  },
  [types.DOWNLOAD_ESIGNATURE_SUCCESS]: (state, taskID) => {
    state.downloadingEsignatures = state.downloadingEsignatures.filter(d => d !== taskID);
  },
  [types.DOWNLOAD_ESIGNATURE_FAILURE]: (state, taskID) => {
    state.downloadingEsignatures = state.downloadingEsignatures.filter(d => d !== taskID);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
