import router from "../../router";
import * as types from "./mutation-types";
import * as api from "../../utils/api";
import * as fhirHelper from "../../utils/fhir-helpers";
import * as appMutations from "../appMutations";
import * as locMutations from "../locations/mutation-types";

export const actions = {
  [types.LOAD_PRACTITIONER]: async ({ commit }, practitionerID) => {
    commit(types.LOAD_PRACTITIONER);
    try {
      var resp = await api.get("practitioner/?_id=" + practitionerID + "&_revinclude=PractitionerRole:practitioner&_revinclude=Composition:subject");
      var practitioner = fhirHelper.fhirBundleToPractitioner(resp.data);
      commit(types.LOAD_PRACTITIONER_SUCCESS, practitioner);
    } catch (error) {
      commit(types.LOAD_PRACTITIONER_FAILURE, error);
    }
  },
  [types.LOAD_PRACTITIONERS]: async ({ commit }, noCache) => {
    commit(types.LOAD_PRACTITIONERS);
    try {
      var response = await api.search("practitioner/?_revinclude=PractitionerRole:practitioner", {}, noCache);
      commit(types.LOAD_PRACTITIONERS_SUCCESS, response?.data.entry);
    } catch (error) {
      commit(types.LOAD_PRACTITIONERS_FAILURE, error);
    }
  },
  [types.CREATE_PRACTITIONER]: async ({ commit, dispatch }, { practitioner, roles, locations, nav }) => {
    const key = new Date().getMilliseconds().toString();
    let fhirPractitioner = fhirHelper.practitionerToFhir(practitioner);
    commit(types.CREATE_PRACTITIONER, { key: key, practitioner: fhirPractitioner });
    try {
      var locationIDs = [];
      if (locations && locations.length > 0) {
        await Promise.all(
          locations.map(async loc => {
            let r = await api.post("Location", { ...loc, name: loc.display });
            locationIDs.push({ reference: "Location/" + r.id, display: loc.display });
          })
        );
      }
      var resp = await api.post("Practitioner", fhirPractitioner);

      if (roles) {
        await Promise.all(
          roles.map(async role => {
            role.practitioner.reference = "Practitioner/" + resp.id;
            role.id = null;
            role.location = locationIDs;
            await api.post("PractitionerRole", role);
          })
        );
      }
      dispatch(appMutations.SNACKBAR_SUCCESS, "Provider Added", { root: true });

      commit(types.CREATE_PRACTITIONER_SUCCESS, { key: key, practitioner: resp });
      if (nav) {
        router.replace({ path: `/providers/${resp.id}` });
      }
    } catch (error) {
      dispatch(appMutations.SNACKBAR_ERROR, "Error Creating Provider", {
        root: true,
      });
      commit(types.CREATE_PRACTITIONER_FAILURE, { key: key, error: error });
    }
  },
  [types.UPDATE_PRACTITIONER]: async ({ commit, dispatch }, { practitioner }) => {
    commit(types.UPDATE_PRACTITIONER);
    try {
      var resp = await api.put("Practitioner/" + practitioner.id, fhirHelper.practitionerToFhir(practitioner));
      commit(types.UPDATE_PRACTITIONER_SUCCESS, practitioner);
      dispatch(types.LOAD_PRACTITIONERS, true);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Provider Updated", { root: true });
    } catch (error) {
      commit(types.UPDATE_PRACTITIONER_FAILURE, error);
      dispatch(types.LOAD_PRACTITIONER, practitioner.id);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Saving Provider", { root: true });
    }
  },
  [types.UPDATE_ROLE]: async ({ commit, dispatch }, { practitionerRole, location }) => {
    // updating location
    if (location.id) {
      var locRef = practitionerRole.location.find(r => r.reference.substring(9) === location.id);
      // null if deleting
      if (locRef) {
        locRef.display = location.display;
        delete location.display;
        dispatch("locations/" + locMutations.UPDATE_LOCATION, location, { root: true });
      }
    }
    // new location, existing role
    else {
      try {
        const display = location.display;
        delete location.display;
        var response = await api.post("location/", location);
        if (!practitionerRole.location) practitionerRole.location = [];
        practitionerRole.location.push({ reference: "Location/" + response.id, display: display });
        commit("locations/" + locMutations.CREATE_LOCATION_SUCCESS, response, { root: true });
      } catch (error) {
        commit("locations/" + locMutations.CREATE_LOCATION_FAILURE, error, { root: true });
      }
    }

    commit(types.UPDATE_ROLE);
    try {
      var resp = await api.put("PractitionerRole/" + practitionerRole.id, practitionerRole);
      dispatch(types.UPDATE_ROLE_SUCCESS, { ...resp });
    } catch (error) {
      commit(types.UPDATE_ROLE_FAILURE, error);
    }
  },
  [types.UPDATE_ROLE_SUCCESS]: async ({ commit, dispatch }, role) => {
    commit(types.UPDATE_ROLE_SUCCESS, { ...role });
    dispatch(appMutations.SNACKBAR_SUCCESS, "Provider Updated", { root: true });

    dispatch(types.LOAD_PRACTITIONERS, true);
  },
  [types.CREATE_ROLE]: async ({ commit, dispatch }, { practitionerRole, location }) => {
    commit(types.CREATE_ROLE);
    try {
      var locDisplay = location.display;
      var locId = null;
      delete location.display;

      // updating location
      if (location.id) {
        locId = location.id;
        practitionerRole.location.push({ reference: "Location/" + location.id, display: locDisplay });
        dispatch("locations/" + locMutations.UPDATE_LOCATION, location, { root: true });
      }
      // location needs created first
      else {
        let response = await api.post("Location", location);
        locId = response.id;
        commit("locations/" + locMutations.CREATE_LOCATION_SUCCESS, response, { root: true });
      }

      practitionerRole.location.push({ reference: "Location/" + locId, display: locDisplay });
      var resp = await api.post("PractitionerRole", practitionerRole);
      dispatch(types.CREATE_ROLE_SUCCESS, { ...resp });
    } catch (error) {
      commit(types.CREATE_ROLE_FAILURE, error);
      commit("locations/" + locMutations.CREATE_LOCATION_FAILURE, error, { root: true });
    }
  },
  [types.CREATE_ROLE_SUCCESS]: async ({ commit, dispatch }, role) => {
    commit(types.CREATE_ROLE_SUCCESS, { ...role });
    dispatch(appMutations.SNACKBAR_SUCCESS, "Provider Updated", { root: true });

    dispatch(types.LOAD_PRACTITIONERS, true);
  },
  [types.DELETE_PRACTITIONERS]: async ({ commit, dispatch }, selectedProviders) => {
    commit(types.DELETE_PRACTITIONERS, selectedProviders);
    try {
      let roleIDs = selectedProviders
        .filter(s => s.role?.resource)
        .map(s => {
          return { id: s.role.resource.id };
        });

      if (roleIDs.length > 0) await api.executeBundle(fhirHelper.createDeleteBundle("PractitionerRole", [].concat.apply([], roleIDs)), true);

      let noteIDs = selectedProviders
        .filter(s => s.notes)
        .map(s => {
          return s.notes.map(n => {
            return {
              id: n.id,
            };
          });
        });

      if (noteIDs.length > 0) await api.executeBundle(fhirHelper.createDeleteBundle("Composition", [].concat.apply([], noteIDs)), true);

      await api.executeBundle(
        fhirHelper.createDeleteBundle(
          "Practitioner",
          selectedProviders.map(s => s.id)
        ),
        true
      );
      commit(types.DELETE_PRACTITIONERS_SUCCESS, selectedProviders);
      let msg = selectedProviders.length == 1 ? "Provider Deleted" : "Providers Deleted";
      dispatch(appMutations.SNACKBAR_SUCCESS, msg, { root: true });
    } catch (error) {
      console.error(error);
      commit(types.DELETE_PRACTITIONERS_FAILURE, { error: error, selectedProviders: selectedProviders });
      dispatch(appMutations.SNACKBAR_ERROR, "Error Deleting Providers", {
        root: true,
      });
    }
  },
  [types.RESET]: ({ commit }) => {
    commit(types.RESET);
  },
  [types.SET_PRACTITIONER]: ({ commit }, p) => {
    commit(types.SET_PRACTITIONER, p);
  },
};
